@include component(post) {
    margin: $content-padding 0;
    @include policy(margin);
    
    @include part(section) {
        position: relative;
        margin: 24px 0;
        @include policy(margin);
        @include clearfix;
        
        @include option(excerpt) {
            margin: 32px 0;
            @include policy(margin);
        }
    }
    
    @include part(meta) {
        @extend .no-list;
        margin: 0 -12px;
        font-weight: $fw-semi-bold;
        
        > li {
            float: left;
            margin: 0 12px;
        }
        
        @include part(categories) {
            @extend .no-list;
            float: left;
            margin: 0 -6px;
            
            > li {
                float: left;
                margin: 0 6px;
            }
            
            @include part(item) {
                display: inline-block;
                position: relative;
                padding: .3em .6em;
                font-size: 75%;
                font-weight: $fw-normal;
                line-height: 1;
                text-align: center;
                white-space: nowrap;
                border-radius: .25em;
                background: $accent-color;
                color: color-contrast($accent-color);
                transition: background $animation-duration;
                
                &:hover {
                    background: lighten($accent-color, 5%);
                }
            }
        }
    }
    
    @include part(img) {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

@include location(post) {
    @include component(post) {
        #{headings()} {
            a {
                color: inherit;
                
                &:hover {
                    color: inherit;
                }
            }
        }
        
        p {
            margin: 1.4em 0;
            @include policy(margin);
        }
        
        @include part(section) {
            @include option(author) {
                margin-top: 48px;
                @include font-size(20px);
                font-weight: $fw-black;
                
                &:first-child {
                    margin-top: 0;
                }
            }
            
            @include option(content) {
                margin-bottom: 48px;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
            
            @include option(share) {
                .addtoany_list {
                    display: block;
                    @include clearfix;
                    margin: 0 -4px;
                    
                    a {
                        display: block;
                        float: left;
                        @include size(32px, 32px);
                        margin: 0 4px;
                        padding: 0;
                        
                        img, span {
                            transition: background $animation-duration, opacity $animation-duration;
                        }
                        
                        &:hover {
                            img, span {
                                opacity: .8;
                            }
                        }
                        
                        @each $key, $hex in $social-colors {
                            $networks: if(map-has-key($addtoany-icons, $key), map-get($addtoany-icons, $key), $key);
                            $classes: '';
                            
                            @each $network in $networks {
                                $classes: if(str-length($classes) > 0, '#{$classes}, .a2a_s_#{$network}', '.a2a_s_#{$network}');
                            }
                            
                            #{$classes} {
                                @extend '%social-bg-important-#{$key}';
                            }
                            
                            &:hover {
                                #{$classes} {
                                    @extend '%social-bg-important-hover-#{$key}';
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include location(posts) {
    @include component(post) {
        
    }
}
