@font-face {
    font-family: 'Freight Text';
    src: url('#{asset('fonts/freight_text_book.woff2')}') format('woff2'),
         url('#{asset('fonts/freight_text_book.woff')}') format('woff');
    font-weight: $fw-normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('#{asset('fonts/aktiv_grotesk_medium.woff2')}') format('woff2'),
         url('#{asset('fonts/aktiv_grotesk_medium.woff')}') format('woff');
    font-weight: $fw-medium;
    font-style: normal;
}
