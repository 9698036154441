%valign {
    @include component(content) {
        @include padding(($content-padding / 2) null ($content-padding / 2));
        
        @include part(section) {
            @include margin(($content-padding / 4) null ($content-padding / 4));
            @include policy(margin);
        }
    }
}

%valign-center, %valign-top, %valign-bottom, %valign-around, %valign-between {
    @extend %flex-col;
}

%valign-center {
    justify-content: center;
}

%valign-top {
    justify-content: flex-start;
}

%valign-bottom {
    justify-content: flex-end;
}

%valign-around {
    justify-content: space-around;
}

%valign-between {
    justify-content: space-between;
}

%align-center {
    align-items: center;
}

@include tweak(valign) {
    @include part(center, top, bottom, around, between) {
        @extend %valign;
        
        @include option(align-center) {
            @extend %align-center;
        }
    }
    
    @include part(center) {
        @extend %valign-center;
    }
    
    @include part(top) {
        @extend %valign-top;
    }
    
    @include part(bottom) {
        @extend %valign-bottom;
    }
    
    @include part(around) {
        @extend %valign-around;
    }
    
    @include part(between) {
        @extend %valign-between;
    }
}
