@include component(box) {
    position: relative;
    background: $main-color;
    @include responsive-padding(($content-padding / 4) ($content-padding / 4));
    overflow: hidden;
    
    @include part(section) {
        @include clearfix;
        margin: ($content-padding / 6) 0;
        @include policy(margin);
    }
    
    @include option(small) {
        @include responsive-padding(($content-padding / 6) ($content-padding / 4));
        
        @include part(section) {
            margin: ($content-padding / 8) 0;
            @include policy(margin);
        }
    }
}

@include component(form-box) {
    background: $main-color;
    color: $text-color;
    @include clearfix;
    @extend %z-depth-1;
    
    @include part(primary, secondary) {
        height: 100%;
        padding: 32px;
    }
    
    @include part(secondary) {
        background: $inverse-main-color;
        color: $inverse-text-color;
    }
}

