@include component(quote) {
    background: $grey;
    
    @include part(logo, content) {
        padding: 32px;
    }
    
    @include part(logo) {
        background: $main-color;
        height: 128px + 32px * 2;
        
        img {
            max-height: 100%;
            margin: 0 auto;
        }
    }
    
    @include part(content) {
        border-left: none;
        
        p {
            @include font-size(18px);
            font-weight: $fw-bold;
        }
        
        footer {
            &:before {
                content: none;
            }
        }
    }
    
    @include part(author) {
        color: $text-color;
    }
    
    @include part(job-title) {
        color: $secondary-color;
    }
    
    @include option(client) {
        @include part(author, job-title) {
            display: block;
        }
    }
}