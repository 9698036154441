// Base components

label[for], label[data-set] {
    cursor: pointer;
}

%flex-row, %flex-col {
    display: -webkit-flex;
    display: flex;
}

%flex-row {
    flex-direction: row;
}

%flex-col {
    flex-direction: column;
}

%flex-around {
    justify-content: space-around;
}

%flex-between {
    justify-content: space-between;
}
