$fa-font-path: "https://netdna.bootstrapcdn.com/font-awesome/#{$fa-version}/fonts";
$fa-css-prefix: icn;

@import "font-awesome";

%fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
