%hl-img-description-xs {
    font-size: 3.25vw;
}

@media (min-width: $screen-sm-min) {
    %hl-img-description-sm {
        font-size: 2.5vw;
    }
}

@media (min-width: $screen-md-min) {
    %hl-img-description-md {
        font-size: 1.75vw;
    }
}

@media (min-width: $screen-lg-min) {
    %hl-img-description-lg {
        font-size: 1.25vw;
    }
}

@include component(img) {
    display: inline-block;
    max-width: 100%;
    padding-left: 48px;
    
    @include part(description) {
        @include absolute((top: 2 / 3 * 100%, left: 0));
        max-width: 80%;
        transform: translateY(-50%);
        
        @media (min-width: $screen-lg-min) {
            max-width: 70%;
        }
        
        #{tweak(highlight)} {
            &#{option(inverse)} {
                span {
                    @extend %hl-img-description-xs;
                    
                    @media (min-width: $screen-sm-min) {
                        @extend %hl-img-description-sm;
                    }
                    
                    @media (min-width: $screen-md-min) {
                        @extend %hl-img-description-md;
                    }
                    
                    @media (min-width: $screen-lg-min) {
                        @extend %hl-img-description-lg;
                    }
                }
            }
            
            @each $color, $hex in $color-scheme {
                &#{option($color)} {
                    span {
                        @extend %hl-img-description-xs;
                        
                        @media (min-width: $screen-sm-min) {
                            @extend %hl-img-description-sm;
                        }
                        
                        @media (min-width: $screen-md-min) {
                            @extend %hl-img-description-md;
                        }
                        
                        @media (min-width: $screen-lg-min) {
                            @extend %hl-img-description-lg;
                        }
                    }
                }
            }
        }
    }
}

@include component(img-block) {
    height: 100%;
    padding: 32px;
    background: #fff;
    color: $text-color;
    text-align: center;
}
