@include component(cta-block) {
    @include component(img) {
        @include padding(0 24px 0 24px);
        
        @include part(description) {
            max-width: 50%;
        }
    }
    
    @include part(section) {
        margin: 32px 0;
        @include policy(margin);
        @include clearfix;
        
        &:not(:first-child) {
            padding: 0 24px 0 32px;
        }
        
        @include option(lg-padding) {
            margin: 48px 0;
            @include policy(margin);
        }
    }
}
