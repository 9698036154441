@include policy(add, margin) {
    &:first-child {
        margin-top: 0;
    }
    
    &:last-child {
        margin-bottom: 0;
    }
}

@include policy(add, no-list) {
    margin: 0;
    padding: 0;
    list-style: none;
}

@include policy(add, transitionable) {
    transition: all $animation-duration;
}
