@include component(slider) {
    @include part(slide) {
        &:not(:first-child) {
            display: none;
        }
    }
    
    @include option(ready) {
        @include part(slide) {
            &:not(:first-child) {
                display: block;
            }
        }
    }
    
    @include option(case-study, quote, client-quote) {
        .slick-list {
            margin: 0 (-320px - 160px);
            padding: 8px 160px;
        }
        
        @include part(slide) {
            @extend %flex-row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
            margin: -16px (320px - 16px);
            
            @include part(case-study, quote, client-quote) {
                flex-basis: (100% / 3);
                flex-grow: 1;
                padding: 16px;
                
                @include component(case-study) {
                    height: 100%;
                }
            }
        }
        
        @include option(ready) {
            @include part(slide) {
                &:not(:first-child) {
                    display: flex;
                }
            }
        }
    }
    
    @include option(logo) {
        img {
            display: inline-block;
            max-height: 192px;
            height: auto;
        }
        
        @include part(control) {
            display: block;
            @include absolute((top: 50%));
            @include size(1em, 1em);
            @include font-size(18px);
            line-height: 1;
            text-align: center;
            color: $faded-text-color;
            transform: translateY(-50%);
            transition: opacity $animation-duration;
            
            &:hover {
                opacity: .8;
            }
            
            @include option(prev) {
                left: -1.5em;
            }
            
            @include option(next) {
                right: -1.5em;
                text-align: right;
            }
        }
        
        @include part(slides) {
            .slick-list {
                padding: 8px 0;
            }
            
            .slick-track {
                @extend %flex-row;
                align-items: stretch;
            }
        }
        
        @include part(slide) {
            height: auto;
            margin: 0 32px;
        }
    }
}
