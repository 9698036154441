@include tweak(text) {
    @include part(main, default) {
        color: $text-color;
    }
    
    @include part(accent) {
        color: $accent-text-color;
    }
    
    @include part(inverse) {
        color: $inverse-text-color;
        
        a:not([class]) {
            color: $accent-color;
            
            &:hover {
                color: lighten($accent-color, 10%);
            }
        }
    }
    
    @include part(primary) {
        color: $primary-color;
    }
    
    @include part(secondary) {
        color: $secondary-color;
    }
}
