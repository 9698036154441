$asset-path: 'assets' !default;
$css-path: 'css' !default;

@function get-path-structure($path) {
    $structure: ();
    
    @while str-index($path, '/') {
        $structure: append($structure, str-slice($path, 1, str-index($path, '/')));
        $path: str-slice($path, str-index($path, '/') + 1, str-length($path));
    }
    
    @if $path != '' {
        @if str-index($path, '.') {
            $structure: append($structure, $path);
        } @else {
            $structure: append($structure, $path + '/');
        }
    }
    
    @return $structure;
}

@function asset($asset) {
    $asset-path: if(str-index($asset-path, '/') == str-length($asset-path), str-slice($asset-path, 0, str-length($asset-path) - 1), $asset-path) !global;
    $css-path: if(str-index($css-path, '/') == 1, str-slice($css-path, 2, str-length($css-path)), $css-path) !global;
    $css-path: if(str-index($css-path, '/') == str-length($css-path), str-slice($css-path, 0, str-length($css-path) - 1), $css-path) !global;
    
    $asset: if(str-index($asset, '/') == 1, str-slice($asset, 2, str-length($asset)), $asset);
    $asset: $asset-path + '/' + $asset;
    $asset: if(map-has-key($manifest, $asset), map-get($manifest, $asset), $asset);
    
    $asset-structure: get-path-structure($asset);
    $css-structure: get-path-structure($asset-path + '/' + $css-path);
    $common-path: '';
    $final: '';
    
    @for $i from 1 through length($asset-structure) {
        @if length($css-structure) >= $i {
            @if nth($css-structure, $i) == nth($asset-structure, $i) {
                $common-path: $common-path + nth($asset-structure, $i);
            }
        }
    }
    
    $css-structure: get-path-structure(str-slice($asset-path + '/' + $css-path, str-length($common-path) + 1));
    
    @for $i from 1 through length($css-structure) {
        $final: $final + '../';
    }
    
    @return unquote($final + str-slice($asset, str-length($common-path) + 1));
}

@function add($some-number, $another-number){
    @return $some-number + $another-number
}

@function headings($start: 1, $end: 0){
    @if $end == 0{
        @if $start == 1{
        $end: 6;
        } @else{
        $end: $start;
        $start: 1;
        }
    }
    
    $string: '';
    @for $i from $start through $end{
        $string: $string + 'h' + $i + ', .h' + $i;
        
        @if $i < $end{
            $string: $string + ', ';
        }
    }
    
    @return unquote($string);
}

@function _brightness($color){
    @return ((red($color) * .299) + (green($color) * .587) + (blue($color) * .114)) / 255 * 100%;
}

$color-contrast-offset: 0% !default;
@function which-color-contrast($color){
    @if($color == null){
        @return null;
    }@else{
        $color-brightness: _brightness($color);
        $light-contrast-brightness: _brightness(#FFF);
        $dark-contrast-brightness: _brightness(#000);
        
        @return abs($color-brightness - $light-contrast-brightness) + ($color-contrast-offset / 2) > abs($color-brightness - $dark-contrast-brightness) - ($color-contrast-offset / 2);
    }
}

$dark-contrast-default: #000 !default;
$light-contrast-default: #FFF !default;

@function color-contrast($color, $dark: $dark-contrast-default, $light: $light-contrast-default) {
    @if($color == null){
        @return null;
    }@else{
        @return if(which-color-contrast($color), $light, $dark);
    }
}

$em-base-size: 16px !default;
@function calculate-em($size, $base: $em-base-size) {
    $em-size: $size / $base;
    @return $em-size * 1em;
}
@function calculate-rem($size, $base: $em-base-size) {
    $rem-size: $size / $base;
    @return $rem-size * 1rem;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
