@mixin grid-class-size($class, $i: 1, $columns: $grid-columns) {
    $list: ".col-#{$class}-#{$i}";

    @for $i from ($i + 1) through $columns {
        $list: "#{$list}, .col-#{$class}-#{$i}";
    }

    #{$list} {
        @content;
    }
}

@mixin grid-size($i: 1, $columns: $grid-columns) {
    $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";

    @for $i from ($i + 1) through $columns {
        $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
    }

    #{$list} {
        @content;
    }
}

$screen-xl-min: 1400px !default;
$screen-lg-max: $screen-xl-min - 1;

$sm-gutter-size: 4px;
$gutter-size: 16px;
$large-gutter-size: 32px;
$xl-gutter-size: 64px;

.col, [class*="col-"] {
    width: 100%;
}

@import "bootstrap/grid";

.col {
    @extend .col-xs-12;
}

@media (min-width: $screen-xl-min) {
    @include make-grid(xl);
}

@include component(row) {
    @include option(ten-cols) {
        @for $i from 1 through 10 {
            @include component(col-xs-#{$i}) {
                width: $i * 10%;
            }
        }
        
        @media (min-width: $screen-sm-min) {
            @for $i from 1 through 10 {
                @include component(col-sm-#{$i}) {
                    width: $i * 10%;
                }
            }
        }
        
        @media (min-width: $screen-md-min) {
            @for $i from 1 through 10 {
                @include component(col-md-#{$i}) {
                    width: $i * 10%;
                }
            }
        }
        
        @media (min-width: $screen-lg-min) {
            @for $i from 1 through 10 {
                @include component(col-lg-#{$i}) {
                    width: $i * 10%;
                }
            }
        }
    }
}

@include component(row) {
    @include margin(null ($gutter-size * -1) null ($gutter-size * -1));

    > {
        @include grid-size {
            @include padding($gutter-size $gutter-size $gutter-size $gutter-size);
        }
    }

    &:first-child {
        margin-top: $gutter-size * -1;
    }

    &:last-child {
        margin-bottom: $gutter-size * -1;
    }

    @include option(sm-gutter) {
        @include margin(null ($sm-gutter-size * -1) null ($sm-gutter-size * -1));

        &:first-child {
            margin-top: $sm-gutter-size * -1;
        }

        &:last-child {
            margin-bottom: $sm-gutter-size * -1;
        }

        @include option(no-v-gutter) {
            @include margin(0 null 0 null);
        }

        > {
            @include grid-size {
                @include padding($sm-gutter-size $sm-gutter-size $sm-gutter-size $sm-gutter-size);
            }
        }
    }

    @include option(lg-gutter) {
        @include margin(null ($large-gutter-size * -1) null ($large-gutter-size * -1));

        &:first-child {
            margin-top: $large-gutter-size * -1;
        }

        &:last-child {
            margin-bottom: $large-gutter-size * -1;
        }

        @include option(no-v-gutter) {
            @include margin(0 null 0 null);
        }

        > {
            @include grid-size {
                @include padding($large-gutter-size $large-gutter-size $large-gutter-size $large-gutter-size);
            }
        }
    }

    @include option(xl-gutter) {
        @include margin(null ($xl-gutter-size * -1) null ($xl-gutter-size * -1));

        &:first-child {
            margin-top: $xl-gutter-size * -.75;
        }

        &:last-child {
            margin-bottom: $xl-gutter-size * -.75;
        }

        @include option(no-v-gutter) {
            @include margin(0 null 0 null);
        }

        > {
            @include grid-size {
                @include padding($xl-gutter-size * .75 $xl-gutter-size $xl-gutter-size * .75 $xl-gutter-size);
            }
        }
    }

    @include option(no-gutter) {
        @include margin(0 0 0 0);

        > {
            @include grid-size {
                @include padding(0 0 0 0);
            }
        }
    }

    @include option(no-v-gutter) {
        @include margin(0 null 0 null);

        > {
            @include grid-size {
                @include padding(0 null 0 null);
            }
        }
    }

    @include option(no-h-gutter) {
        @include margin(null 0 null 0);

        > {
            @include grid-size {
                @include padding(null 0 null 0);
            }
        }
    }
}

@include component(row) {
    @include option(xs-eq-height) {
        display: flex;
        justify-content: flex-start;
        -webkit-align-items: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        
        &:before, &:after {
            content: none;
        }

        > [class*=" col-"], > [class^="col-"] {
            flex-grow: 0;
            flex-shrink: 0;
            height: auto;

            @include rejects(flexbox) {
                margin-right: -1px;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        @include option(sm-eq-height) {
            display: flex;
            justify-content: flex-start;
            -webkit-align-items: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            
            &:before, &:after {
                content: none;
            }

            > [class*=" col-"], > [class^="col-"] {
                flex-grow: 0;
                flex-shrink: 0;
                height: auto;

                @include rejects(flexbox) {
                    margin-right: -1px;
                }
            }
        }
    }

    @media (min-width: $screen-md-min) {
        @include option(md-eq-height) {
            display: flex;
            justify-content: flex-start;
            -webkit-align-items: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            
            &:before, &:after {
                content: none;
            }

            > [class*=" col-"], > [class^="col-"] {
                flex-grow: 0;
                flex-shrink: 0;
                height: auto;

                @include rejects(flexbox) {
                    margin-right: -1px;
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        @include option(lg-eq-height) {
            display: flex;
            justify-content: flex-start;
            -webkit-align-items: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            
            &:before, &:after {
                content: none;
            }

            > [class*=" col-"], > [class^="col-"] {
                flex-grow: 0;
                flex-shrink: 0;
                height: auto;

                @include rejects(flexbox) {
                    margin-right: -1px;
                }
            }
        }
    }

    @media (min-width: $screen-xl-min) {
        @include option(xl-eq-height) {
            display: flex;
            justify-content: flex-start;
            -webkit-align-items: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            
            &:before, &:after {
                content: none;
            }

            > [class*=" col-"], > [class^="col-"] {
                flex-grow: 0;
                flex-shrink: 0;
                height: auto;

                @include rejects(flexbox) {
                    margin-right: -1px;
                }
            }
        }
    }
}

@include respond-options(hidden) {
    display: none;
}
