@mixin md-just-me-style($color, $hex) {
    @include option($color) {
        @include part(close) {
            color: color-contrast($hex);
        }
        
        @include part(content) {
            color: color-contrast($hex);
        }
        
        @include option(show) {
            ~ {
                @include part(overlay) {
                    background: $hex;
                }
            }
        }
    }
}

@include component(modal) {
    @include part(close) {
        @include option(show-just-me) {
            display: none;
        }
    }
    
    @include option(just-me) {
        top: 0;
        left: 0;
        max-width: none;
        width: 100%;
        opacity: 0;
        transform: scale(.8);
        transition: all $animation-duration;
        
        @include part(content) {
            max-height: 100%;
            max-height: 100vh;
            color: $text-color;
            background: transparent;
            box-shadow: none !important;
            
            @include part(header, body, footer) {
                margin: 0;
                padding: 16px $content-padding;
                
                &:first-child {
                    padding-top: $content-padding;
                }
                
                &:last-child {
                    padding-bottom: $content-padding;
                }
            }
        }
        
        @include part(close) {
            @include fixed((top: 0, right: 0));
            
            @include option(hide-just-me) {
                display: none;
            }
            
            @include option(show-just-me) {
                display: block;
            }
        }
        
        @include option(show) {
                opacity: 1;
                transform: scale(1);
            
            ~ {
                @include part(overlay) {
                    background: $main-color;
                }
            }
        }
        
        @include option(blur) {
            @include option(show) {
                ~ #container {
                    filter: none;
                }
            }
        }
        
        @include md-just-me-style(inverse, $inverse-main-color);
        @include md-just-me-style(accent, $accent-color);
        @include md-just-me-style(primary, $primary-color);
        @include md-just-me-style(secondary, $secondary-color);
    }
}
