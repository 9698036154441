@mixin responsive-margin($margin, $important: false) {
    @include _responsive-box(margin, $margin, $important);
}

@mixin responsive-padding($padding, $important: false) {
    @include _responsive-box(padding, $padding, $important);
}

@mixin responsive-option($option) {
    $xs-option: xs-#{$option};
    $sm-option: sm-#{$option};
    $md-option: md-#{$option};
    $lg-option: lg-#{$option};
    $xl-option: xl-#{$option};
    
    @media (max-width: $screen-xs-max) {
        @include option($xs-option) {
            @content;
        }
    }
    
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @include option($sm-option) {
            @content;
        }
    }
    
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @include option($md-option) {
            @content;
        }
    }
    
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        @include option($lg-option) {
            @content;
        }
    }
    
    @media (min-width: $screen-xl-min) {
        @include option($xl-option) {
            @content;
        }
    }
}

@mixin respond-options($option) {
    @include respond-option($option) {
        @content
    }
    
    @include respond-up-option($option) {
        @content
    }
    
    @include respond-down-option($option) {
        @content
    }
}

@mixin respond-option($option) {
    $xs-option: xs-#{$option};
    $sm-option: sm-#{$option};
    $md-option: md-#{$option};
    $lg-option: lg-#{$option};
    $xl-option: xl-#{$option};
    
    @media (max-width: $screen-xs-max) {
        #{option($xs-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        #{option($sm-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        #{option($md-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        #{option($lg-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-xl-min) {
        #{option($xl-option)} {
            @content;
        }
    }
}

@mixin respond-up-option($option) {
    $sm-option: sm-up-#{$option};
    $md-option: md-up-#{$option};
    $lg-option: lg-up-#{$option};
    $xl-option: xl-up-#{$option};
    
    @media (min-width: $screen-sm-min) {
        #{option($sm-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-md-min) {
        #{option($md-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-lg-min) {
        #{option($lg-option)} {
            @content;
        }
    }
    
    @media (min-width: $screen-xl-min) {
        #{option($xl-option)} {
            @content;
        }
    }
}

@mixin respond-down-option($option) {
    $xs-option: xs-down-#{$option};
    $sm-option: sm-down-#{$option};
    $md-option: md-down-#{$option};
    $lg-option: lg-down-#{$option};
    
    @media (max-width: $screen-xs-max) {
        #{option($xs-option)} {
            @content;
        }
    }
    
    @media (max-width: $screen-sm-max) {
        #{option($sm-option)} {
            @content;
        }
    }
    
    @media (max-width: $screen-md-max) {
        #{option($md-option)} {
            @content;
        }
    }
    
    @media (max-width: $screen-lg-max) {
        #{option($lg-option)} {
            @content;
        }
    }
}

@mixin _responsive-box($type, $number, $important: false) {
    @if $important == true {
        $important: !important;
    } @else {
        $important: null;
    }
    
    @if (type-of($number) == number) or (nth($number, 1) == null) or ((length($number) > 1) and (nth($number, 2) == null)) or ((length($number) > 2) and (nth($number, 3) == null)) {
        @if (type-of($number) == number) {
            $number: (null $number null $number);
        } @elseif (length($number) == 1) or (length($number) == 2) {
            $length: length($number);
            $vertical: null;
            
            @if ($length == 2) {
                $vertical: nth($number, 1);
            }
            
            $number: ($vertical nth($number, $length) $vertical nth($number, $length));
        } @elseif length($number) == 3 {
            $number: (nth($number, 1) nth($number, 2) nth($number, 3) nth($number, 2));
        } @else {
            $number: (nth($number, 1) nth($number, 2) nth($number, 3) nth($number, 4));
        }
        
        $one: null;
        $two: null;
        $three: null;
        $four: null;
        
        @if (nth($number, 1) == null) {
            $one: null;
        } @else {
            $one: nth($number, 1) $important;
        }
        
        @if (nth($number, 3) == null) {
            $three: null;
        } @else {
            $three: nth($number, 3) $important;
        }
        
        @if ($type == margin) {
            @if (nth($number, 2) == null) {
                $two: null;
            } @else {
                $two: (nth($number, 2) * .375) $important;
            }
            
            @if (nth($number, 4) == null) {
                $four: null;
            } @else {
                $four: (nth($number, 4) * .375) $important;
            }
            
            @include margin($one $two $three $four);
            
            @media (min-width: $screen-sm-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (2/3)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (2/3)) $important;
                }
                
                @include margin($one $two $three $four);
            }
            
            @media (min-width: $screen-md-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (5/6)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (5/6)) $important;
                }
                
                @include margin($one $two $three $four);
            }
            
            @media (min-width: $screen-lg-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: nth($number, 2) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: nth($number, 4) $important;
                }
                
                @include margin($one $two $three $four);
            }
        } @elseif ($type == padding) {
            @if (nth($number, 2) == null) {
                $two: null;
            } @else {
                $two: (nth($number, 2) * .375) $important;
            }
            
            @if (nth($number, 4) == null) {
                $four: null;
            } @else {
                $four: (nth($number, 4) * .375) $important;
            }
            
            @include padding($one $two $three $four);
            
            @media (min-width: $screen-sm-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (2/3)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (2/3)) $important;
                }
                
                @include padding($one $two $three $four);
            }
            
            @media (min-width: $screen-md-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (5/6)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (5/6)) $important;
                }
                
                @include padding($one $two $three $four);
            }
            
            @media (min-width: $screen-lg-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: nth($number, 2) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: nth($number, 4) $important;
                }
                
                @include padding($one $two $three $four);
            }
        } @elseif ($type == border) {
            @if (nth($number, 2) == null) {
                $two: null;
            } @else {
                $two: (nth($number, 2) * .375) $important;
            }
            
            @if (nth($number, 4) == null) {
                $four: null;
            } @else {
                $four: (nth($number, 4) * .375) $important;
            }
            
            @include border-width($one $two $three $four);
            
            @media (min-width: $screen-sm-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (2/3)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (2/3)) $important;
                }
                
                @include border-width($one $two $three $four);
            }
            
            @media (min-width: $screen-md-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: (nth($number, 2) * (5/6)) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: (nth($number, 4) * (5/6)) $important;
                }
                
                @include border-width($one $two $three $four);
            }
            
            @media (min-width: $screen-lg-min) {
                @if (nth($number, 2) == null) {
                    $two: null;
                } @else {
                    $two: nth($number, 2) $important;
                }
                
                @if (nth($number, 4) == null) {
                    $four: null;
                } @else {
                    $four: nth($number, 4) $important;
                }
                
                @include border-width($one $two $three $four);
            }
        }
    } @else {
        @if (length($number) == 2) {
            #{$type}: nth($number, 1) (nth($number, 2) * .375) $important;
            
            @media (min-width: $screen-sm-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (2/3)) $important;
            }
            
            @media (min-width: $screen-md-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (5/6)) $important;
            }
            
            @media (min-width: $screen-lg-min) {
                #{$type}: nth($number, 1) (nth($number, 2)) $important;
            }
        } @elseif (length($number) == 3) {
            #{$type}: nth($number, 1) (nth($number, 2) * .375) nth($number, 3) $important;
            
            @media (min-width: $screen-sm-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (2/3)) nth($number, 3) $important;
            }
            
            @media (min-width: $screen-md-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (5/6)) nth($number, 3) $important;
            }
            
            @media (min-width: $screen-lg-min) {
                #{$type}: nth($number, 1) nth($number, 2) nth($number, 3) $important;
            }
        } @else {
            #{$type}: nth($number, 1) (nth($number, 2) * .375) nth($number, 3) (nth($number, 4) * .375) $important;
            
            @media (min-width: $screen-sm-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (2/3)) nth($number, 3) (nth($number, 4) * (2/3)) $important;
            }
            
            @media (min-width: $screen-md-min) {
                #{$type}: nth($number, 1) (nth($number, 2) * (5/6)) nth($number, 3) (nth($number, 4) * (5/6)) $important;
            }
            
            @media (min-width: $screen-lg-min) {
                #{$type}: nth($number, 1) nth($number, 2) nth($number, 3) nth($number, 4) $important;
            }
        }
    }
}
