@import "streamline_vars";

@font-face {
  font-family: "streamline-24px";
  src: url('#{asset('fonts/streamline-24px.eot')}');
  src: url('#{asset('fonts/streamline-24px.eot')}?#iefix') format("embedded-opentype"),
    url('#{asset('fonts/streamline-24px.woff')}') format("woff"),
    url('#{asset('fonts/streamline-24px.ttf')}') format("truetype"),
    url('#{asset('fonts/streamline-24px.svg')}#streamline-24px') format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon],
[class^="#{$streamline-icon-prefix}-"],
[class*=" #{$streamline-icon-prefix}-"] {
    display: inline-block;
    vertical-align: middle;
    
    &#{option(text-left)} {
        padding-left: 4px;
    }
    
    &#{option(text-right)} {
        padding-right: 4px;
    }
}

[data-icon]:before,
[class^="#{$streamline-icon-prefix}-"]:before,
[class*=" #{$streamline-icon-prefix}-"]:before {
  display: block;
  font-family: "streamline-24px" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $icon, $char in $streamline-icons {
  .#{$streamline-icon-prefix}-#{$icon}:before {
    content: $char;
  }
}
