$main-nav-item-padding: 16px !default;
$nav-item-vertical-offset: ($nav-height - $font-base-size) / 2 - $main-nav-item-padding;
$nav-item-border-y-height: $main-nav-item-padding * 3;

$nav-breakpoint-min: $nav-breakpoint;
$nav-breakpoint-max: $nav-breakpoint-min - 1;

@include location(nav-open) {
    overflow: hidden;
}

@include component(nav) {
    transition: box-shadow 0s $animation-duration;
    
    @include location(nav-open) {
        @include important {
            box-shadow: none;
            transition: box-shadow 0s;
        }
        
        @include component(toggle-icn) {
            @extend "#{component(toggle-icn)}#{option(active)}";
        }
        
        @include part(section) {
            @include option(desktop) {
                @extend %z-depth-1;
                transition: height $animation-duration, box-shadow 0s;
            }
        }
        
        @media (max-width: $nav-breakpoint-max) {
            @include part(section) {
                @include option(desktop) {
                    overflow: scroll;
                }
            }
            
            @include option(default) {
                @include part(section) {
                    @include option(desktop) {
                        @include component(nav) {
                            @include part(item) {
                                overflow: hidden;
                                
                                &:after {
                                    opacity: 1;
                                    transition: height $animation-duration, opacity 0s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include part(section) {
        flex-grow: 0;
        
        @include option(desktop) {
            @media (max-width: $nav-breakpoint-max) {
                @include absolute((top: $nav-height, left: 0));
                width: 100%;
                max-height: 100vh;
                max-height: calc(100vh - #{$nav-height});
                overflow: hidden;
                transition: height $animation-duration, box-shadow 0s $animation-duration;
            }
            
            @media (min-width: $nav-breakpoint-min) {
                height: $nav-height;
                transition: none;
            }
        }
        
        @include option(mobile) {
            @media (min-width: $nav-breakpoint-min) {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    
    @include option(default) {
        @include fixed((top: 0, left: 0));
        @extend %flex-row;
        @extend %flex-between;
        @include size(100%, $nav-height);
        padding: 0 24px;
        font-family: $heading-font;
        background: rgba(#000, .5);
        color: $inverse-text-color;
        z-index: 99999;
        
        &:before, &:after {
            content: none;
        }
        
        @include location(has-admin-bar) {
            top: 32px;
            
            @include part(bg) {
                @include part(before) {
                    height: calc(100vh - 32px);
                }
            }
        }
        
        @include part(bg) {
            @include absolute((top: 0, left: 0));
            @include size(100%, $nav-height);
            background: rgba(#000, .5);
            overflow: hidden;
            transition: height $animation-duration;
            
            @include part(before) {
                @include absolute((top: 0, left: 0));
                @include size(100%, 100vh);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                z-index: -1;
            }
        }
        
        @include part(item) {
            float: left;
            height: $nav-height;
            padding: 0 24px;
            margin: 0 8px;
            font-weight: 400;
            line-height: $nav-height;
            
            &:first-child {
                margin-left: 0;
            }
            
            &:last-child {
                margin-right: 0;
            }
            
            &:before, &:after {
                content: '';
                position: absolute;
                @include size(2px, 0);
                background: $accent-color;
                transition: height $animation-duration;
            }

            &:before {
                bottom: $nav-item-vertical-offset;
                left: 0;
            }

            &:after {
                top: $nav-item-vertical-offset;
                right: 0;
            }

            &:hover {
                &:before, &:after {
                    height: $nav-item-border-y-height;
                }

                @include part(content) {
                    &:before, &:after {
                        width: 100%;
                    }
                }
            }

            @include part(content) {
                &:before, &:after {
                    content: '';
                    position: absolute;
                    @include size(0, 2px);
                    background: $accent-color;
                    transition: width $animation-duration;
                }

                &:before {
                    top: $nav-item-vertical-offset;
                    left: 0;
                }

                &:after {
                    right: 0;
                    bottom: $nav-item-vertical-offset;
                }
            }

            @include option(logo, icn) {
                &:before, &:after {
                    content: none;
                }
            }
            
            @include option(logo) {
                width: auto;
            }

            @include option(active, child-active) {
                @include part(content) {
                    &:after {
                        width: 100%;
                    }
                }
            }
            
            @include part(content) {
                display: block;
            }
            
            @include option(logo) {
                @include padding(0 null 0);
                @extend %valign-center;
                
                &:hover {
                    transform: none;
                    
                    img {
                        transform: rotateY(-360deg);
                    }
                }
                
                img {
                    max-height: 48px;
                    transition: all $animation-duration * (4 / 3);
                }
            }
            
            @include option(icn) {
                line-height: $nav-height;
                @include font-size(24px);
            }
            
            @include option(hide-desktop) {
                @media (min-width: $nav-breakpoint-min) {
                    display: none;
                }
            }
            
            @include option(hide-mobile) {
                @media (max-width: $nav-breakpoint-max) {
                    display: none;
                }
            }
        }
        
        @include option(fix) {
            position: fixed;
            animation: nav-fix $animation-duration;
            
            @include option(out) {
                animation: nav-fix-out $animation-duration ease 0s 1 normal forwards;
            }
        }
        
        @include option(stick) {
            animation: nav-stick $animation-duration;
        }
        
        @media (max-width: $screen-xs-max) {
            @include padding(null 0 null 0);
        }
        
        @media (max-width: $nav-breakpoint-max) {
            @include part(item) {
                float: none;
                width: 100%;
            }
            
            @include part(section) {
                @include option(desktop) {
                    height: 0;
                    
                    @include component(nav) {
                        @include part(item) {
                            margin: 0;
                            border-top: 1px solid rgba(#fff, .2);
                            transition: background $animation-duration;
                            
                            &:before, &:after {
                                content: none;
                            }
                            
                            &:hover {
                                background: rgba($inverse-main-color, .2);
                            }
                            
                            @include part(content) {
                                &:before, &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: $nav-breakpoint-min) {
            @include part(item) {
                
            }
        }
    }
    
    @include option(simple) {
        margin: 0 6px;
        
        @include part(item) {
            position: relative;
            margin: 2px -6px;
            @include policy(margin);
            
            @include part(content) {
                padding: 2px 6px;
                color: inherit;
                transition: background $animation-duration;
            }
            
            &:hover {
                @include part(content) {
                    color: inherit;
                    background: rgba(#fff, .125);
                }
            }
        }
    }
}

html.ie.crie {
    @include component(nav) {
        &#{option(default)} {
            top: 56px;
        }
    }
    
    @include location(has-admin-bar) {
        @include component(nav) {
            &#{option(default)} {
                top: 56px + 32px;
            }
        }
    }
}

html.ie-9 {
    @include component(nav) {
        &#{option(default)} {
            top: 36px;
        }
    }
    
    @include location(has-admin-bar) {
        @include component(nav) {
            &#{option(default)} {
                top: 36px + 32px;
            }
        }
    }
}

@keyframes nav-fix {
    0% {
        top: -1 * $nav-height;
    }
}

@keyframes nav-fix-out {
    100% {
        top: -1 * $nav-height;
    }
}

@keyframes nav-stick {
    0% {
        opacity: 0;
    }
}
