// core
@import "normalize.scss";
@import "csstyle/csstyle";
@import "math";
@import "functions";
@import "base_mixins";
@import "base_components";

* {
    outline: none !important;
    overflow: visible;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: $main-font;
    background: $main-color;
    color: $text-color;
    text-rendering: optimizeLegibility;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: $link-color;
}

#{headings()} {
    font-family: $heading-font;
}

::selection {
    background-color: rgba($accent-color, .8);
    color: color-contrast($accent-color, $text-color, $inverse-text-color);
}
