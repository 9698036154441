@include component(we-dont-like-ie) {
    display: none;
    @include fixed((top: 0, left: 0));
    width: 100%;
    padding: 16px 32px;
    border: 1px solid transparent;
    z-index: 99999999999999;
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    
    .alert-link:hover {
        text-decoration: underline;
    }
}

.ie-6, .ie-7, .ie-8 {
    margin: 56px 0 0 0 !important;
    
    .hidden-ie, .hidden-ie-9 {
        display: none;
    }
    
    @include component(we-dont-like-ie) {
        [data-dismiss], .close {
            display: none;
        }
    }
}

.ie-6, .ie-7, .ie-8, .ie-9 {
    @include component(we-dont-like-ie) {
        display: block !important;
    }
}

.ie-9 {
    margin: 36px 0 0 0 !important;
    
    @include component(we-dont-like-ie) {
        display: block !important;
        padding: 8px 16px;
        font-size: 12px;
        @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
        
        .close {
            font-size: 1.3125em;
        }
    }
    
    .hidden-ie-9 {
        display: none;
    }
}

html:not(.ie) {
    margin-top: 0 !important;
}

@for $i from 6 through 9 {
    .ie-#{$i} {
        .we-dont-like-ie {
            .ie-version {
                &:after {
                    content: ' #{$i}';
                }
            }
        }
        
        @if ($i < 9) {
            $hidden: '';
            
            @for $j from $i through 8 {
                @if ($hidden != '') {
                    $hidden: $hidden + ', ';
                }
                
                $hidden: $hidden + '.hidden-ie-' + $j;
            }
            
            #{unquote($hidden)} {
                display: none;
            }
        }
    }
}
