@include component(social-links) {
    margin: (24px - 4px) -4px;
    @include clearfix;
    
    &:first-child {
        margin-top: -4px;
    }
    
    &:last-child {
        margin-bottom: -4px;
    }
    
    @include part(item) {
        display: block;
        float: left;
        @include size(32px, 32px);
        margin: 4px;
        line-height: 32px;
        text-align: center;
        background: $accent-color;
        color: $inverse-main-color;
        border-radius: 6px;
        transition: background $animation-duration;
        
        &:hover {
            background: lighten($accent-color, 10%);
            color: $inverse-main-color;
        }
        
        @include component(icn) {
            display: block;
            @include size(32px, 32px);
            font-size: 18px;
            line-height: inherit;
        }
    }
}
