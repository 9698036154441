@include component(figure) {
    @include clearfix;
    
    @include part(icn, content) {
        height: 100%;
    }
    
    @include part(icn) {
        margin-right: 24px;
        font-size: 48px;
        
        @include component(icn) {
            @include size(96px, 96px);
            border-radius: 50%;
            text-align: center;
            @extend '#{tweak(valign)}#{part(center)}';
        }
    }
    
    @include part(content) {
        float: left;
    }
    
    @include part(figure, affix) {
        float: left;
        line-height: 1;
        font-family: $helvetica;
        font-weight: $fw-bold;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    @include part(figure) {
        @include font-size(64px);
    }
    
    @include part(affix) {
        @include font-size(32px);
        margin-top: calculate-rem(32px);
    }
    
    @include option(accent) {
        @include part(icn) {
            @include component(icn) {
                background: $accent-color;
                color: $primary-color;
            }
        }
    }
    
    @include option(primary) {
        @include part(icn) {
            @include component(icn) {
                background: $primary-color;
                color: $accent-color;
            }
        }
    }
    
    @include option(secondary) {
        @include part(icn) {
            @include component(icn) {
                background: $secondary-color;
                color: $accent-color;
            }
        }
    }
}

@include location(figures) {
    @include component(figure) {
        padding: 32px 0;
    }
}

.odometer, .odometer * {
    white-space: nowrap;
}

