@mixin hover {
    @at-root {
        #{option(hoverable)}:hover &, &:hover {
            @content;
        }
    }
}

@mixin focus {
    @at-root {
        #{option(focusable)}:focus &, &:focus {
            @content;
        }
    }
}

@mixin active {
    @include option(active) {
        @content;
    }

    @at-root {
        input:checked + & {
            @content;
        }
    }
}
