@import "rev-manifest";
@import "variables";
@import "bourbon";
@import "core/functions";

// disable for production / enable for development
$debug: false;

// settings
$app: 'ThreeSixty';
$asset-path: 'assets';
$css-path: 'css';

// CSStyle
$csstyle-part-symbol: '.';
$csstyle-root-id: $app;

// Fonts
$main-font: 'Freight Text', $helvetica;
$heading-font: 'Aktiv Grotesk', $helvetica;

$font-base-size: 16px;
$font-base-height: 1.4;
$em-base-size: $font-base-size;

$heading-font-sizes: (
    h1: 54px,
    h2: 36px,
    h3: 28px,
    h4: 20px,
    h5: 18px,
    h6: 16px,
);

// Animation
$animation-duration: .3s;

// Sizes
$nav-height: 96px;
$content-padding: 96px;

$container-lg: 1178px;

// Breakpoints
$nav-breakpoint: 1208px;

// Colours
$main-color: #fff;
$inverse-main-color: #111;
$grey: #f3f3f3;

$text-color: #222;
$inverse-text-color: $main-color;
$accent-text-color: #3ac1b2;
$faded-text-color: #d6d6d6;

$accent-color: #3ac1b2;
$primary-color: $inverse-main-color;
$secondary-color: #00aa86;

$color-scheme: (
    accent: $accent-color,
    primary: $primary-color,
    secondary: $secondary-color,
);

$border-color: #d9dbe8;
$secondary-border-color: darken(#e8e8e8, 5%);
$nav-color: $inverse-main-color;
$link-color: $accent-color;

$credits-color: #222;

$input-border: $border-color;

$color-contrast-offset: 60%;

// Overlay
$overlay-step-size: 5;

// Derived Vars
@import "vars";

// core
@import "odometer-theme-minimal";
@import "bootstrap/bootstrap";
@import "fonts";
@import "core/core";
@import "icons/*";

// Modernizr
@import "modernizr";

// app
@import "we-dont-like-ie";
@import "mixins/*";
@import "policies";
@import "base";
@import "layouts/*";
@import "tweaks/*";
@import "components/*";
