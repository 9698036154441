@include component(hero) {
    min-height: 100vh;
    padding: ($nav-height + 32px) 0 32px;
    
    @include location(has-admin-bar) {
        min-height: calc(100vh - 32px);
    }
    
    @include part(logo, slogan, cta) {
        margin: 16px 0;
        @include policy(margin);
    }
    
    @include part(logo) {
        img {
            width: 480px;
        }
    }
}
