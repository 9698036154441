@include component(job) {
    @include location(jobs) {
        margin: 48px 0;
        @include policy(margin);
    }
    
    @include part(section) {
        margin: 24px 0;
        @include policy(margin);
    }
}
