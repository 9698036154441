@include component(content) {
    height: 100%;
    @include responsive-padding($content-padding $content-padding);
    
    @media (max-width: $screen-xs-max) {
        @include padding(null 16px null 16px);
    }
    
    @include part(section) {
        margin: ($content-padding / 2) 0;
        @include policy(margin);
        @include clearfix;
        
        @include option(sm-padding) {
            margin: ($content-padding / 3) 0;
            @include policy(margin);
        }
    }
    
    @include option(sm-padding) {
        @include responsive-padding(($content-padding / 2) $content-padding);
        
        @include part(section) {
            margin: ($content-padding / 4) 0;
            @include policy(margin);
        }
    }
    
    @each $dir in top, right, bottom, left {
        @include option("sm-padding-#{$dir}") {
            padding-#{$dir}: $content-padding / 2;
        }
        
        @include option("no-padding-#{$dir}") {
            @include important {
                padding-#{$dir}: 0;
            }
        }
    }
    
    @include option(sm-h-padding) {
        @include responsive-padding($content-padding);
    }
    
    @include option(sm-v-padding) {
        @include responsive-padding(($content-padding / 2) null);
    }
    
    @include option(xs-v-padding) {
        @include responsive-padding(($content-padding / 4) null);
    }
    
    @include option(no-h-padding) {
        @include padding(null 0 null 0);
    }
    
    @include option(no-v-padding) {
        @include padding(0 null 0);
    }
}

@media (max-width: $screen-lg-max) {
    [class*="col-xl-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding($content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-top: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-bottom: $content-padding;
                }
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    [class*="col-lg-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding($content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-top: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-bottom: $content-padding;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    [class*="col-md-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding($content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-top: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-bottom: $content-padding;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    [class*="col-sm-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding($content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-top: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-bottom: $content-padding;
                }
            }
        }
    }
}

[class*="col-xs-"] {
    @include component(content) {
        @include option(collapse) {
            @include padding(null $content-padding / 2 null $content-padding / 2);
        }
    }
    
    &:first-child {
        @include component(content) {
            @include option(collapse) {
                padding-left: $content-padding;
            }
        }
    }
    
    &:last-child {
        @include component(content) {
            @include option(collapse) {
                padding-right: $content-padding;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    [class*="col-sm-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding(null $content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-left: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-right: $content-padding;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    [class*="col-md-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding(null $content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-left: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-right: $content-padding;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    [class*="col-lg-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding(null $content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-left: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-right: $content-padding;
                }
            }
        }
    }
}

@media (min-width: $screen-xl-min) {
    [class*="col-xl-"] {
        @include component(content) {
            @include option(collapse) {
                @include padding(null $content-padding / 2 null $content-padding / 2);
            }
        }
        
        &:first-child {
            @include component(content) {
                @include option(collapse) {
                    padding-left: $content-padding;
                }
            }
        }
        
        &:last-child {
            @include component(content) {
                @include option(collapse) {
                    padding-right: $content-padding;
                }
            }
        }
    }
}
