@include component(profile) {
    @include part(header) {
        margin-bottom: 24px;
    }
    
    @include part(img) {
        max-width: 100%;
        padding-bottom: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        
        @include part(wrapper) {
            max-width: 192px;
        }
    }
    
    @include option(large) {
        @include component(social-links) {
            @include part(item) {
                background: $primary-color;
                color: color-contrast($primary-color);
                border-radius: 50%;
                
                &:hover {
                    background: lighten($primary-color, 5%);
                }
            }
        }
        
        @include part(img) {
            @include part(wrapper) {
                max-width: 256px;
            }
        }
        
        @include part(img, meta, social-links) {
            @include margin(24px null 24px);
            @include policy(margin);
        }
        
        @include part(content) {
            p:first-child {
                @extend "#{component(text)}#{part(lead)}";
            }
        }
    }
}
