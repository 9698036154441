@include tweak(clickable) {
    @include absolute((top: 0, right: 0, bottom: 0, left: 0));
    z-index: 9;

    // IE fix
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') 0 0 repeat;
    }

    @if ($debug) {
        background: rgba(#F00, .5);
    }

    &:before, &:after {
        content: none;
    }
}

##{$csstyle-root-id} {
    &.ie-6, &.ie-7, &.ie-8, &.ie-9 {
        #{tweak(clickable)} {
            background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') 0 0 repeat;
        }
    }
}

@include tweak(click-through) {
    pointer-events: none;
}

@include tweak(click-area) {
    position: relative;

    @include option(before) {
        &:before {
            content: '';
            @include absolute((top: -32px, right: -32px, bottom: -32px, left: -32px));

            @if ($debug) {
                background: rgba(#F00, .5);
            }
        }

        @include option(small) {
            &:before {
                top: -16px;
                right: -16px;
                bottom: -16px;
                left: -16px;
            }
        }
    }

    @include option(after) {
        &:after {
            content: '';
            @include absolute((top: -32px, right: -32px, bottom: -32px, left: -32px));

            @if ($debug) {
                background: rgba(#F00, .5);
            }
        }

        @include option(small) {
            &:after {
                top: -16px;
                right: -16px;
                bottom: -16px;
                left: -16px;
            }
        }
    }
}
