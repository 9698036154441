@include component(pagination) {
    display: block;
    text-align: center;
    line-height: 1;
    @include clearfix;
    
    @include part(controls) {
        @include clearfix;
        font-size: 0;
        
        @include component(pagination) {
            @include part(item) {
                &:before {
                    @extend %fa;
                    @include font-size(16px);
                    padding: .3em .6em;
                }
                
                @include option(disabled) {
                    opacity: 0;
                }
            }
        }
        
        @include option(prev) {
            float: left;
            
            @include component(pagination) {
                @include part(item) {
                    &:before {
                        content: $fa-var-angle-left;
                    }
                }
            }
        }
        
        @include option(next) {
            float: right;
            
            @include component(pagination) {
                @include part(item) {
                    &:before {
                        content: $fa-var-angle-right;
                    }
                }
            }
        }
    }
    
    @include part(pages) {
        display: inline-block;
        margin: 0 -4px;
        @include clearfix;
        
        @include component(pagination) {
            @include part(item) {
                margin: 0 4px;
                padding: .3em .6em;
            }
        }
    }
    
    @include part(item) {
        display: block;
        float: left;
        border: 1px solid $accent-color;
        border-radius: 4px;
        color: $accent-color;
        cursor: pointer;
        @include policy(transitionable);
        
        &:hover {
            background: rgba($accent-color, .02);
            @extend %z-depth-1;
        }
        
        @include option(active, disabled) {
            cursor: default;
            
            &:hover {
                box-shadow: none;
            }
        }
        
        @include option(active) {
            background: $accent-color;
            color: color-contrast($accent-color);
            
            &:hover {
                background: $accent-color;
            }
        }
        
        @include option(disabled) {
            border-color: rgba(#000, 0);
            
            &:hover {
                background: none;
            }
        }
    }
}
