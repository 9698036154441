@include component(map) {
    @include absolute((top: 0, left: 0));
    @include size(100% * 4 / 3, 100%);
    
    @include part(container) {
        @include absolute((top: 0, left: 0));
        @include size(100%, 100%);
        background: #2c5a71;
    }
    
    @include part(address) {
        @include absolute((top: 50%, left: (50% / 3)));
        max-width: (100% / 3);
        font-size: 0;
        pointer-events: none;
        transform: translateY(-50%);
        
        @include part(line, link) {
            float: left;
            clear: both;
            pointer-events: auto;
            filter: drop-shadow(0 2px 5px rgba(#000, .16)) drop-shadow(0 2px 10px rgba(#000, .12));
            
            ::selection {
                background-color: rgba($primary-color, .8);
                color: color-contrast($primary-color, $text-color, $inverse-text-color);
            }
            
            span {
                display: inline-block;
                margin: em(($font-base-size * $font-base-height - $font-base-size) / 4) 0;
                padding: 0 em(2px);
                background: $accent-color;
                color: color-contrast($accent-color);
            }
        }
        
        @include part(line) {
            span {
                @include font-size(32px);
            }
            
            + {
                @include component(map) {
                    @include part(address) {
                        @include part(link) {
                            margin-top: rem(24px);
                        }
                    }
                }
            }
        }
        
        @include part(link) {
            @include policy(transitionable);
            
            &:hover {
                filter: drop-shadow(0 8px 17px rgba(#000, .2)) drop-shadow(0 6px 20px rgba(#000, .19));
            }
            
            span {
                @include font-size(24px);
                transition: background $animation-duration;
            }
            
            a {
                &:hover {
                    span {
                        background: lighten($accent-color, 10%);
                    }
                }
            }
        }
    }
}