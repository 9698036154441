$modal-border-radius: 3px !default;

@include policy(add, modal-perspective) {
    perspective: 1300px;
}

%modal-perspective {
    height: 100%;
    perspective: 600px;
}

@include location(modal-open) {
    body {
        height: 100%;
        overflow: hidden;
    }
}

// Main Component
@include component(modal) {
    @extend '#{tweak(valign)}#{part(center)}';
    @include fixed((top: 50%, left: 50%));
    max-width: 640px;
    min-width: 320px;
    width: 50%;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-50%) translateY(-50%);
    z-index: 555555;
    
    @include part(perspective) {
        @extend %modal-perspective;
        
        body, #container {
            @extend %modal-perspective;
        }
        
        #container {
            overflow: auto;
        }
    }
    
    @include part(overlay) {
        position: fixed;
        width: 100%;
        height: 100%;
        visibility: hidden;
        top: 0;
        left: 0;
        z-index: 111111;
        opacity: 0;
        background: rgba($text-color, .8);
        cursor: pointer;
        transition: all $animation-duration;
        
        @include option(no-close) {
            cursor: default;
        }
    }
    
    @include part(content) {
        position: relative;
        max-height: 90vh;
        max-height: calc(100vh - 128px);
        margin: 0 auto;
        border-radius: $modal-border-radius;
        overflow: auto;
        @extend #{tweak(z-depth-2)};
        
        @include part(header, body, footer) {
            margin: 32px;
        }
        
        @include part(header) {
            height: auto;
            
            #{headings()} {
                margin: 0;
            }
        }
    }
    
    @include part(close) {
        @include absolute((top: -32px, right: -32px));
        line-height: 1;
        padding: 16px;
        text-align: center;
        
        @include component(icn) {
            width: 1em;
            display: block;
        }
    }
    
    @include option(image) {
        max-width: 90%;
        width: auto;
        
        @include part(close) {
            top: 0;
            right: 0;
            padding: 6px;
        }
        
        img {
            max-width: 90vw;
            max-height: 90vh;
            max-height: calc(100vh - 128px);
        }
    }
    
    @include option(show) {
        visibility: visible;
        
        ~ {
            @include part(overlay) {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

// Modal Effects
// @import "modal/blur";
// @import "modal/fade-in-scale-up";
// @import "modal/fall";
// @import "modal/flip-3d";
@import "modal/just-me";
// @import "modal/let-me-in";
// @import "modal/make-way";
// @import "modal/newspaper";
// @import "modal/rotate-3d";
// @import "modal/sign-3d";
// @import "modal/slide-in";
// @import "modal/slit-3d";
// @import "modal/sticky";
// @import "modal/super-scaled";
