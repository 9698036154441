@include component(case-study) {
    background: $grey;
    
    @include part(logo, content) {
        padding: 32px;
    }
    
    @include part(logo) {
        background: $main-color;
        height: 128px + 32px * 2;
        
        img {
            max-height: 100%;
            margin: 0 auto;
        }
    }
}