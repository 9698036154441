$streamline-icon-prefix: icon !default;

$streamline-icons: (
  bin-1:                           "\e000",
  bin-2:                           "\e001",
  binocular:                       "\e002",
  bomb:                            "\e003",
  clip-1:                          "\e004",
  clip-2:                          "\e005",
  crosshair-1:                     "\e006",
  crosshair-2:                     "\e007",
  crosshair-3:                     "\e008",
  cutter:                          "\e009",
  delete-1:                        "\e00a",
  delete-2:                        "\e00b",
  edit-1:                          "\e00c",
  edit-2:                          "\e00d",
  edit-3:                          "\e00e",
  hide:                            "\e00f",
  ink:                             "\e010",
  key-1:                           "\e011",
  key-2:                           "\e012",
  link-1:                          "\e013",
  link-2:                          "\e014",
  link-3:                          "\e015",
  link-broken-1:                   "\e016",
  link-broken-2:                   "\e017",
  lock-1:                          "\e018",
  lock-2:                          "\e019",
  lock-3:                          "\e01a",
  lock-4:                          "\e01b",
  lock-5:                          "\e01c",
  lock-unlock-1:                   "\e01d",
  lock-unlock-2:                   "\e01e",
  magnifier:                       "\e01f",
  pen-1:                           "\e020",
  pen-2:                           "\e021",
  pen-3:                           "\e022",
  pen-4:                           "\e023",
  pencil-1:                        "\e024",
  pencil-2:                        "\e025",
  pencil-3:                        "\e026",
  pin-1:                           "\e027",
  pin-2:                           "\e028",
  power-1:                         "\e029",
  power-2:                         "\e02a",
  preview-1:                       "\e02b",
  preview-2:                       "\e02c",
  scissor-1:                       "\e02d",
  scissor-2:                       "\e02e",
  skull-1:                         "\e02f",
  skull-2:                         "\e030",
  type-1:                          "\e031",
  type-2:                          "\e032",
  type-3:                          "\e033",
  type-4:                          "\e034",
  zoom-area:                       "\e035",
  zoom-in:                         "\e036",
  zoom-out:                        "\e037",
  cursor-1:                        "\e038",
  cursor-2:                        "\e039",
  cursor-add:                      "\e03a",
  cursor-duplicate:                "\e03b",
  cursor-move:                     "\e03c",
  cursor-move-2:                   "\e03d",
  cursor-select-area:              "\e03e",
  hand:                            "\e03f",
  hand-block:                      "\e040",
  hand-grab-1:                     "\e041",
  hand-grab-2:                     "\e042",
  hand-point:                      "\e043",
  hand-touch-1:                    "\e044",
  hand-touch-2:                    "\e045",
  hand-touch-3:                    "\e046",
  hand-touch-4:                    "\e047",
  bookmark-1:                      "\e048",
  bookmark-2:                      "\e049",
  bookmark-3:                      "\e04a",
  bookmark-4:                      "\e04b",
  tag-1:                           "\e04c",
  tag-2:                           "\e04d",
  tag-add:                         "\e04e",
  tag-delete:                      "\e04f",
  tags-1:                          "\e050",
  tags-2:                          "\e051",
  anchor-point-1:                  "\e052",
  anchor-point-2:                  "\e053",
  arrange-1:                       "\e054",
  arrange-2:                       "\e055",
  artboard:                        "\e056",
  brush-1:                         "\e057",
  brush-2:                         "\e058",
  bucket:                          "\e059",
  crop:                            "\e05a",
  dropper-1:                       "\e05b",
  dropper-2:                       "\e05c",
  dropper-3:                       "\e05d",
  glue:                            "\e05e",
  grid:                            "\e05f",
  layers:                          "\e060",
  magic-wand-1:                    "\e061",
  magic-wand-2:                    "\e062",
  magnet:                          "\e063",
  marker:                          "\e064",
  palette:                         "\e065",
  pen-5:                           "\e066",
  pen-6:                           "\e067",
  quill:                           "\e068",
  reflect:                         "\e069",
  roller:                          "\e06a",
  ruler-1:                         "\e06b",
  ruler-2:                         "\e06c",
  scale-diagonal-1:                "\e06d",
  scale-diagonal-2:                "\e06e",
  scale-horizontal:                "\e06f",
  scale-tool-1:                    "\e070",
  scale-tool-2:                    "\e071",
  scale-tool-3:                    "\e072",
  scale-vertical:                  "\e073",
  shear-tool:                      "\e074",
  spray:                           "\e075",
  stamp:                           "\e076",
  stationery-1:                    "\e077",
  stationery-2:                    "\e078",
  stationery-3:                    "\e079",
  vector:                          "\e07a",
  award-1:                         "\e07b",
  award-2:                         "\e07c",
  award-3:                         "\e07d",
  award-4:                         "\e07e",
  award-5:                         "\e07f",
  award-6:                         "\e080",
  crown-1:                         "\e081",
  crown-2:                         "\e082",
  crown-3:                         "\e083",
  fire:                            "\e084",
  flag-1:                          "\e085",
  flag-2:                          "\e086",
  flag-3:                          "\e087",
  flag-4:                          "\e088",
  flag-5:                          "\e089",
  flag-6:                          "\e08a",
  flag-7:                          "\e08b",
  flag-8:                          "\e08c",
  google-plus-1:                   "\e08d",
  google-plus-2:                   "\e08e",
  hand-like-1:                     "\e08f",
  hand-like-2:                     "\e090",
  hand-unlike-1:                   "\e091",
  hand-unlike-2:                   "\e092",
  heart-1:                         "\e093",
  heart-2:                         "\e094",
  heart-angel:                     "\e095",
  heart-broken:                    "\e096",
  heart-minus:                     "\e097",
  heart-plus:                      "\e098",
  present:                         "\e099",
  rank-1:                          "\e09a",
  rank-2:                          "\e09b",
  ribbon:                          "\e09c",
  star-1:                          "\e09d",
  star-2:                          "\e09e",
  star-3:                          "\e09f",
  star-4:                          "\e0a0",
  star-5:                          "\e0a1",
  star-6:                          "\e0a2",
  star-7:                          "\e0a3",
  star-8:                          "\e0a4",
  star-9:                          "\e0a5",
  star-10:                         "\e0a6",
  trophy:                          "\e0a7",
  bubble-2:                        "\e0a8",
  bubble-add-1:                    "\e0a9",
  bubble-add-2:                    "\e0aa",
  bubble-add-3:                    "\e0ab",
  bubble-ask-1:                    "\e0ac",
  bubble-ask-2:                    "\e0ad",
  bubble-attention-1:              "\e0ae",
  bubble-attention-2:              "\e0af",
  bubble-attention-3:              "\e0b0",
  bubble-attention-4:              "\e0b1",
  bubble-attention-5:              "\e0b2",
  bubble-attention-6:              "\e0b3",
  bubble-attention-7:              "\e0b4",
  bubble-block-1:                  "\e0b5",
  bubble-block-2:                  "\e0b6",
  bubble-block-3:                  "\e0b7",
  bubble-chat-1:                   "\e0b8",
  bubble-chat-2:                   "\e0b9",
  bubble-check-1:                  "\e0ba",
  bubble-check-2:                  "\e0bb",
  bubble-check-3:                  "\e0bc",
  bubble-comment-1:                "\e0bd",
  bubble-comment-2:                "\e0be",
  bubble-conversation-1:           "\e0bf",
  bubble-conversation-2:           "\e0c0",
  bubble-conversation-3:           "\e0c1",
  bubble-conversation-4:           "\e0c2",
  bubble-conversation-5:           "\e0c3",
  bubble-conversation-6:           "\e0c4",
  bubble-delete-1:                 "\e0c5",
  bubble-delete-2:                 "\e0c6",
  bubble-delete-3:                 "\e0c7",
  bubble-edit-1:                   "\e0c8",
  bubble-edit-2:                   "\e0c9",
  bubble-edit-3:                   "\e0ca",
  bubble-heart-1:                  "\e0cb",
  bubble-heart-2:                  "\e0cc",
  bubble-minus-1:                  "\e0cd",
  bubble-minus-2:                  "\e0ce",
  bubble-minus-3:                  "\e0cf",
  bubble-quote-1:                  "\e0d0",
  bubble-quote-2:                  "\e0d1",
  bubble-smiley-3:                 "\e0d2",
  bubble-smiley-4:                 "\e0d3",
  bubble-smiley-smile:             "\e0d4",
  bubble-smiley-wink:              "\e0d5",
  bubble-star-1:                   "\e0d6",
  bubble-star-2:                   "\e0d7",
  bubble-star-3:                   "\e0d8",
  chat-1:                          "\e0d9",
  chat-2:                          "\e0da",
  chat-3:                          "\e0db",
  chat-4:                          "\e0dc",
  chat-5:                          "\e0dd",
  chat-bubble-1:                   "\e0de",
  chat-bubble-2:                   "\e0df",
  smiley-happy-1:                  "\e0e0",
  smiley-happy-2:                  "\e0e1",
  smiley-happy-3:                  "\e0e2",
  smiley-happy-4:                  "\e0e3",
  smiley-happy-5:                  "\e0e4",
  smiley-relax:                    "\e0e5",
  smiley-sad:                      "\e0e6",
  smiley-surprise:                 "\e0e7",
  thinking-1:                      "\e0e8",
  thinking-2:                      "\e0e9",
  call-1:                          "\e0ea",
  call-2:                          "\e0eb",
  call-3:                          "\e0ec",
  call-4:                          "\e0ed",
  call-add:                        "\e0ee",
  call-block:                      "\e0ef",
  call-delete:                     "\e0f0",
  call-in:                         "\e0f1",
  call-minus:                      "\e0f2",
  call-out:                        "\e0f3",
  contact:                         "\e0f4",
  fax:                             "\e0f5",
  hang-up:                         "\e0f6",
  message:                         "\e0f7",
  mobile-phone-1:                  "\e0f8",
  mobile-phone-2:                  "\e0f9",
  phone-1:                         "\e0fa",
  phone-2:                         "\e0fb",
  phone-3:                         "\e0fc",
  phone-4:                         "\e0fd",
  phone-vibration:                 "\e0fe",
  signal-fine:                     "\e0ff",
  signal-full:                     "\e100",
  signal-high:                     "\e101",
  signal-no:                       "\e102",
  signal-poor:                     "\e103",
  signal-weak:                     "\e104",
  smartphone:                      "\e105",
  tape:                            "\e106",
  camera-symbol-1:                 "\e107",
  camera-symbol-2:                 "\e108",
  camera-symbol-3:                 "\e109",
  headphone:                       "\e10a",
  antenna-1:                       "\e10b",
  antenna-2:                       "\e10c",
  antenna-3:                       "\e10d",
  hotspot-1:                       "\e10e",
  hotspot-2:                       "\e10f",
  link:                            "\e110",
  megaphone-1:                     "\e111",
  megaphone-2:                     "\e112",
  radar:                           "\e113",
  rss-1:                           "\e114",
  rss-2:                           "\e115",
  satellite:                       "\e116",
  address-1:                       "\e117",
  address-2:                       "\e118",
  address-3:                       "\e119",
  forward:                         "\e11a",
  inbox-1:                         "\e11b",
  inbox-2:                         "\e11c",
  inbox-3:                         "\e11d",
  inbox-4:                         "\e11e",
  letter-1:                        "\e11f",
  letter-2:                        "\e120",
  letter-3:                        "\e121",
  letter-4:                        "\e122",
  letter-5:                        "\e123",
  mail-1:                          "\e124",
  mail-2:                          "\e125",
  mail-add:                        "\e126",
  mail-attention:                  "\e127",
  mail-block:                      "\e128",
  mail-box-1:                      "\e129",
  mail-box-2:                      "\e12a",
  mail-box-3:                      "\e12b",
  mail-checked:                    "\e12c",
  mail-compose:                    "\e12d",
  mail-delete:                     "\e12e",
  mail-favorite:                   "\e12f",
  mail-inbox:                      "\e130",
  mail-lock:                       "\e131",
  mail-minus:                      "\e132",
  mail-read:                       "\e133",
  mail-recieved-1:                 "\e134",
  mail-recieved-2:                 "\e135",
  mail-search-1:                   "\e136",
  mail-search-2:                   "\e137",
  mail-sent-1:                     "\e138",
  mail-sent-2:                     "\e139",
  mail-setting:                    "\e13a",
  mail-star:                       "\e13b",
  mail-sync:                       "\e13c",
  mail-time:                       "\e13d",
  outbox-1:                        "\e13e",
  outbox-2:                        "\e13f",
  plane-paper-1:                   "\e140",
  plane-paper-2:                   "\e141",
  reply-mail-1:                    "\e142",
  reply-mail-2:                    "\e143",
  connection-1:                    "\e144",
  connection-2:                    "\e145",
  connection-3:                    "\e146",
  contacts-1:                      "\e147",
  contacts-2:                      "\e148",
  contacts-3:                      "\e149",
  contacts-4:                      "\e14a",
  female:                          "\e14b",
  gender-female:                   "\e14c",
  gender-male:                     "\e14d",
  genders:                         "\e14e",
  id-1:                            "\e14f",
  id-2:                            "\e150",
  id-3:                            "\e151",
  id-4:                            "\e152",
  id-5:                            "\e153",
  id-6:                            "\e154",
  id-7:                            "\e155",
  id-8:                            "\e156",
  male:                            "\e157",
  profile-1:                       "\e158",
  profile-2:                       "\e159",
  profile-3:                       "\e15a",
  profile-4:                       "\e15b",
  profile-5:                       "\e15c",
  profile-6:                       "\e15d",
  profile-athlete:                 "\e15e",
  profile-bussiness-man:           "\e15f",
  profile-chef:                    "\e160",
  profile-cop:                     "\e161",
  profile-doctor-1:                "\e162",
  profile-doctor-2:                "\e163",
  profile-gentleman-1:             "\e164",
  profile-gentleman-2:             "\e165",
  profile-graduate:                "\e166",
  profile-king:                    "\e167",
  profile-lady-1:                  "\e168",
  profile-lady-2:                  "\e169",
  profile-man:                     "\e16a",
  profile-nurse1:                  "\e16b",
  profile-nurse-2:                 "\e16c",
  profile-prisoner:                "\e16d",
  profile-serviceman-1:            "\e16e",
  profile-serviceman-2:            "\e16f",
  profile-spy:                     "\e170",
  profile-teacher:                 "\e171",
  profile-thief:                   "\e172",
  user-1:                          "\e173",
  user-2:                          "\e174",
  user-add-1:                      "\e175",
  user-add-2:                      "\e176",
  user-block-1:                    "\e177",
  user-block-2:                    "\e178",
  user-checked-1:                  "\e179",
  user-checked-2:                  "\e17a",
  user-delete-1:                   "\e17b",
  user-delete-2:                   "\e17c",
  user-edit-1:                     "\e17d",
  user-edit-2:                     "\e17e",
  user-heart-1:                    "\e17f",
  user-heart-2:                    "\e180",
  user-lock-1:                     "\e181",
  user-lock-2:                     "\e182",
  user-minus-1:                    "\e183",
  user-minus-2:                    "\e184",
  user-search-1:                   "\e185",
  user-search-2:                   "\e186",
  user-setting-1:                  "\e187",
  user-setting-2:                  "\e188",
  user-star-1:                     "\e189",
  user-star-2:                     "\e18a",
  bag-shopping-1:                  "\e18c",
  bag-shopping-2:                  "\e18d",
  bag-shopping-3:                  "\e18e",
  basket-1:                        "\e18f",
  basket-2:                        "\e190",
  basket-3:                        "\e191",
  basket-add:                      "\e192",
  basket-minus:                    "\e193",
  briefcase:                       "\e194",
  cart-1:                          "\e195",
  cart-2:                          "\e196",
  cart-3:                          "\e197",
  cart-4:                          "\e198",
  cut:                             "\e199",
  handbag-1:                       "\e19a",
  handbag-2:                       "\e19b",
  purse-1:                         "\e19c",
  purse-2:                         "\e19d",
  qr-code:                         "\e19e",
  receipt-1:                       "\e19f",
  receipt-2:                       "\e1a0",
  receipt-3:                       "\e1a1",
  receipt-4:                       "\e1a2",
  shopping-1:                      "\e1a3",
  sign-new-1:                      "\e1a4",
  sign-new-2:                      "\e1a5",
  sign-parking:                    "\e1a6",
  signal-star:                     "\e1a7",
  trolley-1:                       "\e1a8",
  trolley-2:                       "\e1a9",
  trolley-3:                       "\e1aa",
  trolley-load:                    "\e1ab",
  trolley-off:                     "\e1ac",
  wallet-1:                        "\e1ad",
  wallet-2:                        "\e1ae",
  wallet-3:                        "\e1af",
  camera-1:                        "\e1b0",
  camera-2:                        "\e1b1",
  camera-3:                        "\e1b2",
  camera-4:                        "\e1b3",
  camera-5:                        "\e1b4",
  camera-back:                     "\e1b5",
  camera-focus:                    "\e1b6",
  camera-frames:                   "\e1b7",
  camera-front:                    "\e1b8",
  camera-graph-1:                  "\e1b9",
  camera-graph-2:                  "\e1ba",
  camera-landscape:                "\e1bb",
  camera-lens-1:                   "\e1bc",
  camera-lens-2:                   "\e1bd",
  camera-light:                    "\e1be",
  camera-portrait:                 "\e1bf",
  camera-view:                     "\e1c0",
  film-1:                          "\e1c1",
  film-2:                          "\e1c2",
  photo-1:                         "\e1c3",
  photo-2:                         "\e1c4",
  photo-frame:                     "\e1c5",
  photos-1:                        "\e1c6",
  photos-2:                        "\e1c7",
  polaroid:                        "\e1c8",
  signal-camera-1:                 "\e1c9",
  signal-camera-2:                 "\e1ca",
  user-photo:                      "\e1cb",
  backward-1:                      "\e1cc",
  dvd-player:                      "\e1cd",
  eject-1:                         "\e1ce",
  film-3:                          "\e1cf",
  forward-1:                       "\e1d0",
  handycam:                        "\e1d1",
  movie-play-1:                    "\e1d2",
  movie-play-2:                    "\e1d3",
  movie-play-3:                    "\e1d4",
  next-1:                          "\e1d5",
  pause-1:                         "\e1d6",
  play-1:                          "\e1d7",
  player:                          "\e1d8",
  previous-1:                      "\e1d9",
  record-1:                        "\e1da",
  slate:                           "\e1db",
  stop-1:                          "\e1dc",
  television:                      "\e1dd",
  video-camera-1:                  "\e1de",
  video-camera-2:                  "\e1df",
  backward-2:                      "\e1e0",
  cd:                              "\e1e1",
  eject-2:                         "\e1e2",
  equalizer-2:                     "\e1e3",
  equalizer-3:                     "\e1e4",
  forward-2:                       "\e1e5",
  gramophone:                      "\e1e6",
  gramophone-record-2:             "\e1e7",
  guitar:                          "\e1e8",
  headphone-1:                     "\e1e9",
  headphone-2:                     "\e1ea",
  microphone-1:                    "\e1eb",
  microphone-2:                    "\e1ec",
  microphone-3:                    "\e1ed",
  movie-play-4:                    "\e1ee",
  music-note-1:                    "\e1ef",
  music-note-2:                    "\e1f0",
  music-note-3:                    "\e1f1",
  music-note-4:                    "\e1f2",
  next-2:                          "\e1f3",
  notes-1:                         "\e1f4",
  notes-2:                         "\e1f5",
  pause-2:                         "\e1f6",
  piano:                           "\e1f7",
  play-2:                          "\e1f8",
  playlist:                        "\e1f9",
  previous-2:                      "\e1fa",
  radio-1:                         "\e1fb",
  radio-2:                         "\e1fc",
  record-2:                        "\e1fd",
  recorder:                        "\e1fe",
  saxophone:                       "\e1ff",
  speaker-1:                       "\e200",
  speaker-2:                       "\e201",
  speaker-3:                       "\e202",
  stop-2:                          "\e203",
  tape-1:                          "\e204",
  trumpet:                         "\e205",
  volume-down-1:                   "\e206",
  volume-down-2:                   "\e207",
  volume-loud-1:                   "\e208",
  volume-loud-2:                   "\e209",
  volume-low-1:                    "\e20a",
  volume-low-2:                    "\e20b",
  volume-medium-1:                 "\e20c",
  volume-medium-2:                 "\e20d",
  volume-mute-1:                   "\e20e",
  volume-mute-2:                   "\e20f",
  volume-mute-3:                   "\e210",
  volume-up-1:                     "\e211",
  volume-up-2:                     "\e212",
  walkman:                         "\e213",
  cloud:                           "\e214",
  cloud-add:                       "\e215",
  cloud-checked:                   "\e216",
  cloud-delete:                    "\e217",
  cloud-download:                  "\e218",
  cloud-minus:                     "\e219",
  cloud-refresh:                   "\e21a",
  cloud-sync:                      "\e21b",
  cloud-upload:                    "\e21c",
  download-1:                      "\e21d",
  download-2:                      "\e21e",
  download-3:                      "\e21f",
  download-4:                      "\e220",
  download-5:                      "\e221",
  download-6:                      "\e222",
  download-7:                      "\e223",
  download-8:                      "\e224",
  download-9:                      "\e225",
  download-10:                     "\e226",
  download-11:                     "\e227",
  download-12:                     "\e228",
  download-13:                     "\e229",
  download-14:                     "\e22a",
  download-15:                     "\e22b",
  download-file:                   "\e22c",
  download-folder:                 "\e22d",
  goal-1:                          "\e22e",
  goal-2:                          "\e22f",
  transfer-1:                      "\e230",
  transfer-2:                      "\e231",
  transfer-3:                      "\e232",
  transfer-4:                      "\e233",
  transfer-5:                      "\e234",
  transfer-6:                      "\e235",
  transfer-7:                      "\e236",
  transfer-8:                      "\e237",
  transfer-9:                      "\e238",
  transfer-10:                     "\e239",
  transfer-11:                     "\e23a",
  transfer-12:                     "\e23b",
  upload-1:                        "\e23c",
  upload-2:                        "\e23d",
  upload-3:                        "\e23e",
  upload-4:                        "\e23f",
  upload-5:                        "\e240",
  upload-6:                        "\e241",
  upload-7:                        "\e242",
  upload-8:                        "\e243",
  upload-9:                        "\e244",
  upload-10:                       "\e245",
  upload-11:                       "\e246",
  upload-12:                       "\e247",
  clipboard-1:                     "\e248",
  clipboard-2:                     "\e249",
  clipboard-3:                     "\e24a",
  clipboard-add:                   "\e24b",
  clipboard-block:                 "\e24c",
  clipboard-checked:               "\e24d",
  clipboard-delete:                "\e24e",
  clipboard-edit:                  "\e24f",
  clipboard-minus:                 "\e250",
  document-1:                      "\e251",
  document-2:                      "\e252",
  file-1:                          "\e253",
  file-2:                          "\e254",
  file-add:                        "\e255",
  file-attention:                  "\e256",
  file-block:                      "\e257",
  file-bookmark:                   "\e258",
  file-checked:                    "\e259",
  file-code:                       "\e25a",
  file-delete:                     "\e25b",
  file-download:                   "\e25c",
  file-edit:                       "\e25d",
  file-favorite-1:                 "\e25e",
  file-favorite-2:                 "\e25f",
  file-graph-1:                    "\e260",
  file-graph-2:                    "\e261",
  file-home:                       "\e262",
  file-image-1:                    "\e263",
  file-image-2:                    "\e264",
  file-list:                       "\e265",
  file-lock:                       "\e266",
  file-media:                      "\e267",
  file-minus:                      "\e268",
  file-music:                      "\e269",
  file-new:                        "\e26a",
  file-registry:                   "\e26b",
  file-search:                     "\e26c",
  file-setting:                    "\e26d",
  file-sync:                       "\e26e",
  file-table:                      "\e26f",
  file-thumbnail:                  "\e270",
  file-time:                       "\e271",
  file-transfer:                   "\e272",
  file-upload:                     "\e273",
  file-zip:                        "\e274",
  files-1:                         "\e275",
  files-2:                         "\e276",
  files-3:                         "\e277",
  files-4:                         "\e278",
  files-5:                         "\e279",
  files-6:                         "\e27a",
  hand-file-1:                     "\e27b",
  hand-file-2:                     "\e27c",
  note-paper-1:                    "\e27d",
  note-paper-2:                    "\e27e",
  note-paper-add:                  "\e27f",
  note-paper-attention:            "\e280",
  note-paper-block:                "\e281",
  note-paper-checked:              "\e282",
  note-paper-delete:               "\e283",
  note-paper-download:             "\e284",
  note-paper-edit:                 "\e285",
  note-paper-favorite:             "\e286",
  note-paper-lock:                 "\e287",
  note-paper-minus:                "\e288",
  note-paper-search:               "\e289",
  note-paper-sync:                 "\e28a",
  note-paper-upload:               "\e28b",
  print:                           "\e28c",
  folder-1:                        "\e28d",
  folder-2:                        "\e28e",
  folder-3:                        "\e28f",
  folder-4:                        "\e290",
  folder-add:                      "\e291",
  folder-attention:                "\e292",
  folder-block:                    "\e293",
  folder-bookmark:                 "\e294",
  folder-checked:                  "\e295",
  folder-code:                     "\e296",
  folder-delete:                   "\e297",
  folder-download:                 "\e298",
  folder-edit:                     "\e299",
  folder-favorite:                 "\e29a",
  folder-home:                     "\e29b",
  folder-image:                    "\e29c",
  folder-lock:                     "\e29d",
  folder-media:                    "\e29e",
  folder-minus:                    "\e29f",
  folder-music:                    "\e2a0",
  folder-new:                      "\e2a1",
  folder-search:                   "\e2a2",
  folder-setting:                  "\e2a3",
  folder-share-1:                  "\e2a4",
  folder-share-2:                  "\e2a5",
  folder-sync:                     "\e2a6",
  folder-transfer:                 "\e2a7",
  folder-upload:                   "\e2a8",
  folder-zip:                      "\e2a9",
  add-1:                           "\e2aa",
  add-2:                           "\e2ab",
  add-3:                           "\e2ac",
  add-4:                           "\e2ad",
  add-tag:                         "\e2ae",
  arrow-1:                         "\e2af",
  arrow-2:                         "\e2b0",
  arrow-down-1:                    "\e2b1",
  arrow-down-2:                    "\e2b2",
  arrow-left-1:                    "\e2b3",
  arrow-left-2:                    "\e2b4",
  arrow-move-1:                    "\e2b5",
  arrow-move-down:                 "\e2b6",
  arrow-move-left:                 "\e2b7",
  arrow-move-right:                "\e2b8",
  arrow-move-up:                   "\e2b9",
  arrow-right-1:                   "\e2ba",
  arrow-right-2:                   "\e2bb",
  arrow-up-1:                      "\e2bc",
  arrow-up-2:                      "\e2bd",
  back:                            "\e2be",
  center-expand:                   "\e2bf",
  center-reduce:                   "\e2c0",
  delete-1-1:                      "\e2c1",
  delete-2-1:                      "\e2c2",
  delete-3:                        "\e2c3",
  delete-4:                        "\e2c4",
  delete-tag:                      "\e2c5",
  expand-horizontal:               "\e2c6",
  expand-vertical:                 "\e2c7",
  forward-3:                       "\e2c8",
  infinity:                        "\e2c9",
  loading:                         "\e2ca",
  log-out-1:                       "\e2cb",
  log-out-2:                       "\e2cc",
  loop-1:                          "\e2cd",
  loop-2:                          "\e2ce",
  loop-3:                          "\e2cf",
  minus-1:                         "\e2d0",
  minus-2:                         "\e2d1",
  minus-3:                         "\e2d2",
  minus-4:                         "\e2d3",
  minus-tag:                       "\e2d4",
  move-diagonal-1:                 "\e2d5",
  move-diagonal-2:                 "\e2d6",
  move-horizontal-1:               "\e2d7",
  move-horizontal-2:               "\e2d8",
  move-vertical-1:                 "\e2d9",
  move-vertical-2:                 "\e2da",
  next-1-1:                        "\e2db",
  next-2-1:                        "\e2dc",
  power-1-1:                       "\e2dd",
  power-2-1:                       "\e2de",
  power-3:                         "\e2df",
  power-4:                         "\e2e0",
  recycle:                         "\e2e1",
  refresh:                         "\e2e2",
  repeat:                          "\e2e3",
  return:                          "\e2e4",
  scale-all-1:                     "\e2e5",
  scale-center:                    "\e2e6",
  scale-horizontal-1:              "\e2e7",
  scale-horizontal-2:              "\e2e8",
  scale-reduce-1:                  "\e2e9",
  scale-reduce-2:                  "\e2ea",
  scale-reduce-3:                  "\e2eb",
  scale-spread-1:                  "\e2ec",
  scale-spread-2:                  "\e2ed",
  scale-spread-3:                  "\e2ee",
  scale-vertical-1:                "\e2ef",
  scale-vertical-2:                "\e2f0",
  scroll-horizontal-1:             "\e2f1",
  scroll-horizontal-2:             "\e2f2",
  scroll-omnidirectional-1:        "\e2f3",
  scroll-omnidirectional-2:        "\e2f4",
  scroll-vertical-1:               "\e2f5",
  scroll-vertical-2:               "\e2f6",
  shuffle:                         "\e2f7",
  split:                           "\e2f8",
  sync-1:                          "\e2f9",
  sync-2:                          "\e2fa",
  timer:                           "\e2fb",
  transfer:                        "\e2fc",
  transfer-1-1:                    "\e2fd",
  check-1:                         "\e2fe",
  check-2:                         "\e2ff",
  check-3:                         "\e300",
  check-box:                       "\e301",
  check-bubble:                    "\e302",
  check-circle-1:                  "\e303",
  check-circle-2:                  "\e304",
  check-list:                      "\e305",
  check-shield:                    "\e306",
  cross:                           "\e307",
  cross-bubble:                    "\e308",
  cross-shield:                    "\e309",
  briefcase-1:                     "\e30a",
  brightness-high:                 "\e30b",
  brightness-low:                  "\e30c",
  hammer-1:                        "\e30d",
  hammer-2:                        "\e30e",
  pulse:                           "\e30f",
  scale:                           "\e310",
  screw-driver:                    "\e311",
  setting-adjustment:              "\e312",
  setting-gear:                    "\e313",
  setting-gears-1:                 "\e314",
  setting-gears-2:                 "\e315",
  setting-wrenches:                "\e316",
  switch-1:                        "\e317",
  switch-2:                        "\e318",
  wrench:                          "\e319",
  alarm-1:                         "\e31a",
  alarm-clock:                     "\e31b",
  alarm-off:                       "\e31c",
  alarm-snooze:                    "\e31d",
  bell:                            "\e31e",
  calendar-1:                      "\e31f",
  calendar-2:                      "\e320",
  clock-1:                         "\e321",
  clock-2:                         "\e322",
  clock-3:                         "\e323",
  hourglass-1:                     "\e324",
  hourglass-2:                     "\e325",
  timer-1:                         "\e326",
  timer-3-quarter-1:               "\e327",
  timer-3-quarter-2:               "\e328",
  timer-full-1:                    "\e329",
  timer-full-2:                    "\e32a",
  timer-half-1:                    "\e32b",
  timer-half-2:                    "\e32c",
  timer-half-3:                    "\e32d",
  timer-half-4:                    "\e32e",
  timer-quarter-1:                 "\e32f",
  timer-quarter-2:                 "\e330",
  watch-1:                         "\e331",
  watch-2:                         "\e332",
  alert-1:                         "\e333",
  alert-2:                         "\e334",
  alert-3:                         "\e335",
  information:                     "\e336",
  nuclear-1:                       "\e337",
  nuclear-2:                       "\e338",
  question-mark:                   "\e339",
  abacus:                          "\e33a",
  amex-card:                       "\e33b",
  atm:                             "\e33c",
  balance:                         "\e33d",
  bank-1:                          "\e33e",
  bank-2:                          "\e33f",
  bank-note-1:                     "\e340",
  bank-note-2:                     "\e341",
  bank-note-3:                     "\e342",
  bitcoins:                        "\e343",
  board:                           "\e344",
  box-1:                           "\e345",
  box-2:                           "\e346",
  box-3:                           "\e347",
  box-download:                    "\e348",
  box-shipping:                    "\e349",
  box-upload:                      "\e34a",
  business-chart-1:                "\e34b",
  business-chart-2:                "\e34c",
  calculator-1:                    "\e34d",
  calculator-2:                    "\e34e",
  calculator-3:                    "\e34f",
  cash-register:                   "\e350",
  chart-board:                     "\e351",
  chart-down:                      "\e352",
  chart-up:                        "\e353",
  check:                           "\e354",
  coins-1:                         "\e355",
  coins-2:                         "\e356",
  court:                           "\e357",
  credit-card:                     "\e358",
  credit-card-lock:                "\e359",
  delivery:                        "\e35a",
  dollar-bag:                      "\e35b",
  dollar-currency-1:               "\e35c",
  dollar-currency-2:               "\e35d",
  dollar-currency-3:               "\e35e",
  dollar-currency-4:               "\e35f",
  euro-bag:                        "\e360",
  euro-currency-1:                 "\e361",
  euro-currency-2:                 "\e362",
  euro-currency-3:                 "\e363",
  euro-currency-4:                 "\e364",
  forklift:                        "\e365",
  hand-card:                       "\e366",
  hand-coin:                       "\e367",
  keynote:                         "\e368",
  master-card:                     "\e369",
  money:                           "\e36a",
  parking-meter:                   "\e36b",
  percent-1:                       "\e36c",
  percent-2:                       "\e36d",
  percent-3:                       "\e36e",
  percent-4:                       "\e36f",
  percent-5:                       "\e370",
  percent-up:                      "\e371",
  pie-chart-1:                     "\e372",
  pie-chart-2:                     "\e373",
  piggy-bank:                      "\e374",
  pound-currency-1:                "\e375",
  pound-currency-2:                "\e376",
  pound-currency-3:                "\e377",
  pound-currency-4:                "\e378",
  safe-1:                          "\e379",
  safe-2:                          "\e37a",
  shop:                            "\e37b",
  sign:                            "\e37c",
  trolley:                         "\e37d",
  truck-1:                         "\e37e",
  truck-2:                         "\e37f",
  visa-card:                       "\e380",
  yen-currency-1:                  "\e381",
  yen-currency-2:                  "\e382",
  yen-currency-3:                  "\e383",
  yen-currency-4:                  "\e384",
  add-marker-1:                    "\e385",
  add-marker-1-1:                  "\e386",
  add-marker-2:                    "\e387",
  add-marker-2-1:                  "\e388",
  add-marker-3:                    "\e389",
  compass-1:                       "\e38a",
  compass-2:                       "\e38b",
  compass-3:                       "\e38c",
  delete-marker-1:                 "\e38d",
  delete-marker-1-1:               "\e38e",
  delete-marker-2:                 "\e38f",
  delete-marker-2-1:               "\e390",
  favorite-marker-1:               "\e391",
  favorite-marker-1-1:             "\e392",
  favorite-marker-2:               "\e393",
  favorite-marker-2-1:             "\e394",
  globe:                           "\e395",
  location:                        "\e396",
  map-1:                           "\e397",
  map-location:                    "\e398",
  map-marker-1:                    "\e399",
  map-marker-1-1:                  "\e39a",
  map-marker-2:                    "\e39b",
  map-marker-3:                    "\e39c",
  map-marker-pin:                  "\e39d",
  map-pin:                         "\e39e",
  marker-1:                        "\e39f",
  marker-1-1:                      "\e3a0",
  marker-2:                        "\e3a1",
  marker-2-1:                      "\e3a2",
  marker-pin-1:                    "\e3a3",
  marker-pin-2:                    "\e3a4",
  marker-pin-location:             "\e3a5",
  minus-marker-1:                  "\e3a6",
  minus-marker-1-1:                "\e3a7",
  minus-marker-2:                  "\e3a8",
  minus-marker-2-1:                "\e3a9",
  minus-marker-3:                  "\e3aa",
  anchor:                          "\e3ab",
  bank:                            "\e3ac",
  beach:                           "\e3ad",
  boat:                            "\e3ae",
  building-1:                      "\e3af",
  building-2:                      "\e3b0",
  building-3:                      "\e3b1",
  buildings-1:                     "\e3b2",
  buildings-2:                     "\e3b3",
  buildings-3:                     "\e3b4",
  buildings-4:                     "\e3b5",
  castle:                          "\e3b6",
  column:                          "\e3b7",
  direction-sign:                  "\e3b8",
  factory:                         "\e3b9",
  fence:                           "\e3ba",
  garage:                          "\e3bb",
  globe-1:                         "\e3bc",
  globe-2:                         "\e3bd",
  house-1:                         "\e3be",
  house-2:                         "\e3bf",
  house-3:                         "\e3c0",
  house-4:                         "\e3c1",
  library:                         "\e3c2",
  light-house:                     "\e3c3",
  pisa:                            "\e3c4",
  skyscraper:                      "\e3c5",
  temple:                          "\e3c6",
  treasure-map:                    "\e3c7",
  tree:                            "\e3c8",
  tree-pine:                       "\e3c9",
  attention:                       "\e3ca",
  bug-1:                           "\e3cb",
  bug-2:                           "\e3cc",
  css3:                            "\e3cd",
  firewall:                        "\e3ce",
  html5:                           "\e3cf",
  plugins-1:                       "\e3d0",
  plugins-2:                       "\e3d1",
  script:                          "\e3d2",
  new-window:                      "\e3d3",
  window-1:                        "\e3d4",
  window-2:                        "\e3d5",
  window-3:                        "\e3d6",
  window-add:                      "\e3d7",
  window-alert:                    "\e3d8",
  window-check:                    "\e3d9",
  window-code-1:                   "\e3da",
  window-code-2:                   "\e3db",
  window-code-3:                   "\e3dc",
  window-column:                   "\e3dd",
  window-delete:                   "\e3de",
  window-denied:                   "\e3df",
  window-download-1:               "\e3e0",
  window-download-2:               "\e3e1",
  window-edit:                     "\e3e2",
  window-favorite:                 "\e3e3",
  window-graph-1:                  "\e3e4",
  window-graph-2:                  "\e3e5",
  window-hand:                     "\e3e6",
  window-home:                     "\e3e7",
  window-like:                     "\e3e8",
  window-list-1:                   "\e3e9",
  window-list-2:                   "\e3ea",
  window-lock:                     "\e3eb",
  window-minimize:                 "\e3ec",
  window-minus:                    "\e3ed",
  window-refresh:                  "\e3ee",
  window-register:                 "\e3ef",
  window-search:                   "\e3f0",
  window-selection:                "\e3f1",
  window-setting:                  "\e3f2",
  window-sync:                     "\e3f3",
  window-thumbnails-1:             "\e3f4",
  window-thumbnails-2:             "\e3f5",
  window-time:                     "\e3f6",
  window-upload-1:                 "\e3f7",
  window-upload-2:                 "\e3f8",
  windows-selection:               "\e3f9",
  database:                        "\e3fa",
  database-alert:                  "\e3fb",
  database-block:                  "\e3fc",
  database-check:                  "\e3fd",
  database-delete:                 "\e3fe",
  database-download:               "\e3ff",
  database-edit:                   "\e400",
  database-lock:                   "\e401",
  database-minus:                  "\e402",
  database-network:                "\e403",
  database-plus:                   "\e404",
  database-refresh:                "\e405",
  database-search:                 "\e406",
  database-setting:                "\e407",
  database-sync:                   "\e408",
  database-time:                   "\e409",
  database-upload:                 "\e40a",
  battery-charging:                "\e40b",
  battery-full:                    "\e40c",
  battery-high:                    "\e40d",
  battery-low:                     "\e40e",
  battery-medium:                  "\e40f",
  cd-1:                            "\e410",
  cd-2:                            "\e411",
  chip:                            "\e412",
  computer:                        "\e413",
  disc:                            "\e414",
  filter:                          "\e415",
  floppy-disk:                     "\e416",
  gameboy:                         "\e417",
  harddisk-1:                      "\e418",
  harddisk-2:                      "\e419",
  imac:                            "\e41a",
  ipad-1:                          "\e41b",
  ipad-2:                          "\e41c",
  ipod:                            "\e41d",
  joystick-1:                      "\e41e",
  joystick-2:                      "\e41f",
  joystick-3:                      "\e420",
  keyboard-1:                      "\e421",
  keyboard-2:                      "\e422",
  kindle-1:                        "\e423",
  kindle-2:                        "\e424",
  laptop-1:                        "\e425",
  laptop-2:                        "\e426",
  memory-card:                     "\e427",
  mobile-phone:                    "\e428",
  mouse-1:                         "\e429",
  mouse-2:                         "\e42a",
  mp3player:                       "\e42b",
  plug-1:                          "\e42c",
  plug-2:                          "\e42d",
  plug-slot:                       "\e42e",
  printer:                         "\e42f",
  projector:                       "\e430",
  remote:                          "\e431",
  router:                          "\e432",
  screen-1:                        "\e433",
  screen-2:                        "\e434",
  screen-3:                        "\e435",
  screen-4:                        "\e436",
  smartphone-1:                    "\e437",
  television-1:                    "\e438",
  typewriter-1:                    "\e439",
  typewriter-2:                    "\e43a",
  usb-1:                           "\e43b",
  usb-2:                           "\e43c",
  webcam:                          "\e43d",
  wireless-router-1:               "\e43e",
  wireless-router-2:               "\e43f",
  bluetooth:                       "\e440",
  ethernet:                        "\e441",
  ethernet-slot:                   "\e442",
  firewire-1:                      "\e443",
  firewire-2:                      "\e444",
  network-1:                       "\e445",
  network-2:                       "\e446",
  server-1:                        "\e447",
  server-2:                        "\e448",
  server-3:                        "\e449",
  usb:                             "\e44a",
  wireless-signal:                 "\e44b",
  book-1:                          "\e44c",
  book-2:                          "\e44d",
  book-3:                          "\e44e",
  book-4:                          "\e44f",
  book-5:                          "\e450",
  book-6:                          "\e451",
  book-7:                          "\e452",
  book-8:                          "\e453",
  book-download-1:                 "\e454",
  book-download-2:                 "\e455",
  book-favorite-1:                 "\e456",
  book-favorite-2:                 "\e457",
  bookmark-1-1:                    "\e458",
  bookmark-2-1:                    "\e459",
  bookmark-3-1:                    "\e45a",
  bookmark-4-1:                    "\e45b",
  books-1:                         "\e45c",
  books-2:                         "\e45d",
  books-3:                         "\e45e",
  briefcase-2:                     "\e45f",
  contact-book-1:                  "\e460",
  contact-book-2:                  "\e461",
  contact-book-3:                  "\e462",
  contact-book-4:                  "\e463",
  copyright:                       "\e464",
  creative-commons:                "\e465",
  cube:                            "\e466",
  data-filter:                     "\e467",
  document-box-1:                  "\e468",
  document-box-2:                  "\e469",
  document-box-3:                  "\e46a",
  drawer-1:                        "\e46b",
  drawer-2:                        "\e46c",
  drawer-3:                        "\e46d",
  envelope:                        "\e46e",
  file:                            "\e46f",
  files:                           "\e470",
  filter-1:                        "\e471",
  filter-2:                        "\e472",
  layers-1:                        "\e473",
  list-1:                          "\e474",
  list-2:                          "\e475",
  newspaper-1:                     "\e476",
  newspaper-2:                     "\e477",
  registry-1:                      "\e478",
  registry-2:                      "\e479",
  shield-1:                        "\e47a",
  shield-2:                        "\e47b",
  shield-3:                        "\e47c",
  sketchbook:                      "\e47d",
  sound-book:                      "\e47e",
  thumbnails-1:                    "\e47f",
  thumbnails-2:                    "\e480",
  graph:                           "\e481",
  hierarchy-1:                     "\e482",
  hierarchy-2:                     "\e483",
  hierarchy-3:                     "\e484",
  hierarchy-4:                     "\e485",
  hierarchy-5:                     "\e486",
  hierarchy-6:                     "\e487",
  hierarchy-7:                     "\e488",
  network-1-1:                     "\e489",
  network-2-1:                     "\e48a",
  backpack:                        "\e48b",
  balance-1:                       "\e48c",
  bed:                             "\e48d",
  bench:                           "\e48e",
  bomb-1:                          "\e48f",
  bricks:                          "\e490",
  bullets:                         "\e491",
  buoy:                            "\e492",
  campfire:                        "\e493",
  can:                             "\e494",
  candle:                          "\e495",
  canon:                           "\e496",
  cctv-1:                          "\e497",
  cctv-2:                          "\e498",
  chair:                           "\e499",
  chair-director:                  "\e49a",
  cigarette:                       "\e49b",
  construction-sign:               "\e49c",
  diamond:                         "\e49d",
  disabled:                        "\e49e",
  door:                            "\e49f",
  drawer:                          "\e4a0",
  driller:                         "\e4a1",
  dumbbells:                       "\e4a2",
  fire-extinguisher:               "\e4a3",
  flashlight:                      "\e4a4",
  gas-station:                     "\e4a5",
  gun:                             "\e4a6",
  lamp-1:                          "\e4a7",
  lamp-2:                          "\e4a8",
  lamp-3:                          "\e4a9",
  lamp-4:                          "\e4aa",
  lightbulb-1:                     "\e4ab",
  lightbulb-2:                     "\e4ac",
  measuring-tape:                  "\e4ad",
  mine-cart:                       "\e4ae",
  missile:                         "\e4af",
  ring:                            "\e4b0",
  scale-1:                         "\e4b1",
  shovel:                          "\e4b2",
  smoke-no:                        "\e4b3",
  sofa-1:                          "\e4b4",
  sofa-2:                          "\e4b5",
  sofa-3:                          "\e4b6",
  target:                          "\e4b7",
  torch:                           "\e4b8",
  traffic-cone:                    "\e4b9",
  traffic-light-1:                 "\e4ba",
  traffic-light-2:                 "\e4bb",
  treasure-chest-1:                "\e4bc",
  treasure-chest-2:                "\e4bd",
  trowel:                          "\e4be",
  watering-can:                    "\e4bf",
  weigh:                           "\e4c0",
  36-text-arrow-redo:              "\e4c1",
  academic-cap:                    "\e4c2",
  baseball-helmet:                 "\e4c3",
  beanie:                          "\e4c4",
  bike-helmet:                     "\e4c5",
  bow:                             "\e4c6",
  cap:                             "\e4c7",
  chaplin:                         "\e4c8",
  chef-hat:                        "\e4c9",
  cloth-hanger:                    "\e4ca",
  fins:                            "\e4cb",
  football-helmet:                 "\e4cc",
  glasses:                         "\e4cd",
  glasses-1:                       "\e4ce",
  glasses-2:                       "\e4cf",
  magician-hat:                    "\e4d0",
  monocle-1:                       "\e4d1",
  monocle-2:                       "\e4d2",
  necktie:                         "\e4d3",
  safety-helmet:                   "\e4d4",
  scuba-tank:                      "\e4d5",
  shirt-1:                         "\e4d6",
  shirt-2:                         "\e4d7",
  shirt-3:                         "\e4d8",
  sneakers:                        "\e4d9",
  snorkel:                         "\e4da",
  sombrero:                        "\e4db",
  sunglasses:                      "\e4dc",
  tall-hat:                        "\e4dd",
  trousers:                        "\e4de",
  walking-stick:                   "\e4df",
  arrow-undo:                      "\e4e0",
  bold:                            "\e4e1",
  columns:                         "\e4e2",
  eraser:                          "\e4e3",
  font-color:                      "\e4e4",
  html:                            "\e4e5",
  italic:                          "\e4e6",
  list-1-1:                        "\e4e7",
  list-2-1:                        "\e4e8",
  list-3:                          "\e4e9",
  list-4:                          "\e4ea",
  paragraph:                       "\e4eb",
  paste:                           "\e4ec",
  print-preview:                   "\e4ed",
  quote:                           "\e4ee",
  strikethrough:                   "\e4ef",
  text:                            "\e4f0",
  text-wrapping-1:                 "\e4f1",
  text-wrapping-2:                 "\e4f2",
  text-wrapping-3:                 "\e4f3",
  underline:                       "\e4f4",
  align-center:                    "\e4f5",
  align-left:                      "\e4f6",
  align-right:                     "\e4f7",
  all-caps:                        "\e4f8",
  arrange-2-1:                     "\e4f9",
  arrange-2-2:                     "\e4fa",
  arrange-2-3:                     "\e4fb",
  arrange-2-4:                     "\e4fc",
  arrange-3-1:                     "\e4fd",
  arrange-3-2:                     "\e4fe",
  arrange-3-3:                     "\e4ff",
  arrange-3-4:                     "\e500",
  arrange-3-5:                     "\e501",
  arrange-4-1:                     "\e502",
  arrange-4-2:                     "\e503",
  arrange-4-3:                     "\e504",
  arrange-5:                       "\e505",
  consolidate-all:                 "\e506",
  decrease-indent-1:               "\e507",
  decrease-indent-2:               "\e508",
  horizontal-page:                 "\e509",
  increase-indent-1:               "\e50a",
  increase-indent-2:               "\e50b",
  justify:                         "\e50c",
  leading-1:                       "\e50d",
  leading-2:                       "\e50e",
  left-indent:                     "\e50f",
  right-indent:                    "\e510",
  small-caps:                      "\e511",
  vertical-page:                   "\e512",
  alt-mac:                         "\e513",
  alt-windows:                     "\e514",
  arrow-down:                      "\e515",
  arrow-down-left:                 "\e516",
  arrow-down-right:                "\e517",
  arrow-left:                      "\e518",
  arrow-right:                     "\e519",
  arrow-up:                        "\e51a",
  arrow-up-left:                   "\e51b",
  arrow-up-right:                  "\e51c",
  asterisk-1:                      "\e51d",
  asterisk-2:                      "\e51e",
  back-tab-1:                      "\e51f",
  back-tab-2:                      "\e520",
  backward-delete:                 "\e521",
  blank:                           "\e522",
  eject:                           "\e523",
  enter-1:                         "\e524",
  enter-2:                         "\e525",
  escape:                          "\e526",
  page-down:                       "\e527",
  page-up:                         "\e528",
  return-1:                        "\e529",
  shift-1:                         "\e52a",
  shift-2:                         "\e52b",
  tab:                             "\e52c",
  apple:                           "\e52d",
  beer:                            "\e52e",
  boil:                            "\e52f",
  bottle-1:                        "\e530",
  bottle-2:                        "\e531",
  bottle-3:                        "\e532",
  bottle-4:                        "\e533",
  bread:                           "\e534",
  burger-1:                        "\e535",
  burger-2:                        "\e536",
  cake-1:                          "\e537",
  cake-2:                          "\e538",
  champagne:                       "\e539",
  cheese:                          "\e53a",
  cocktail:                        "\e53b",
  cocktail-1:                      "\e53c",
  cocktail-2:                      "\e53d",
  coffee-pot:                      "\e53e",
  cup1:                            "\e53f",
  cup-2:                           "\e540",
  deep-fry:                        "\e541",
  energy-drink:                    "\e542",
  espresso-machine:                "\e543",
  food-dome:                       "\e544",
  fork-and-knife:                  "\e545",
  fork-and-spoon:                  "\e546",
  grape:                           "\e547",
  grater:                          "\e548",
  grill:                           "\e549",
  hot-drinks-glass:                "\e54a",
  hotdog:                          "\e54b",
  ice-cream-1:                     "\e54c",
  ice-cream-2:                     "\e54d",
  ice-cream-3:                     "\e54e",
  ice-drinks-galss:                "\e54f",
  juicer:                          "\e550",
  kitchen-timer:                   "\e551",
  milk:                            "\e552",
  orange:                          "\e553",
  oven:                            "\e554",
  pan-fry:                         "\e555",
  pepper-salt:                     "\e556",
  pizza:                           "\e557",
  popcorn:                         "\e558",
  serving:                         "\e559",
  soda:                            "\e55a",
  soda-can-1:                      "\e55b",
  soda-can-2:                      "\e55c",
  steam:                           "\e55d",
  tea-pot:                         "\e55e",
  thermometer-high:                "\e55f",
  thermometer-low:                 "\e560",
  thermometer-medium:              "\e561",
  water:                           "\e562",
  wine:                            "\e563",
  ambulance:                       "\e564",
  beaker-1:                        "\e565",
  beaker-2:                        "\e566",
  blood:                           "\e567",
  drug:                            "\e568",
  first-aid:                       "\e569",
  hashish:                         "\e56a",
  heartpulse:                      "\e56b",
  hospital-1:                      "\e56c",
  hospital-2:                      "\e56d",
  hospital-sign-1:                 "\e56e",
  hospital-sign-2:                 "\e56f",
  hospital-sign-3:                 "\e570",
  medicine:                        "\e571",
  microscope:                      "\e572",
  mortar-and-pestle:               "\e573",
  plaster:                         "\e574",
  pulse-graph-1:                   "\e575",
  pulse-graph-2:                   "\e576",
  pulse-graph-3:                   "\e577",
  red-cross:                       "\e578",
  stethoscope:                     "\e579",
  syringe:                         "\e57a",
  yin-yang:                        "\e57b",
  balloon:                         "\e57c",
  briefcase-lock:                  "\e57d",
  card:                            "\e57e",
  cards:                           "\e57f",
  cards-2:                         "\e580",
  curtain:                         "\e581",
  dice-1:                          "\e582",
  dice-2:                          "\e583",
  happy-smiley:                    "\e584",
  pacman:                          "\e585",
  pacman-ghost:                    "\e586",
  sad-smiley:                      "\e587",
  sign-1:                          "\e588",
  smileys:                         "\e589",
  suitcase-1:                      "\e58a",
  suitcase-2:                      "\e58b",
  tetris:                          "\e58c",
  ticket-1:                        "\e58d",
  ticket-2:                        "\e58e",
  ticket-3:                        "\e58f",
  virus:                           "\e590",
  cloud-1:                         "\e591",
  cloud-lightning:                 "\e592",
  clouds:                          "\e593",
  first-quarter-moon:              "\e594",
  full-moon:                       "\e595",
  hail:                            "\e596",
  heavy-rain:                      "\e597",
  moon-cloud:                      "\e598",
  rain:                            "\e599",
  rain-lightning:                  "\e59a",
  snow:                            "\e59b",
  sun:                             "\e59c",
  sun-cloud:                       "\e59d",
  thermometer:                     "\e59e",
  third-quarter-moon:              "\e59f",
  umbrella:                        "\e5a0",
  waning-crescent-moon:            "\e5a1",
  waning-gibbous-moon:             "\e5a2",
  waxing-crescent-moon:            "\e5a3",
  waxing-gibbous-moon:             "\e5a4",
  bicycle:                         "\e5a5",
  bus-1:                           "\e5a6",
  bus-2:                           "\e5a7",
  car-1:                           "\e5a8",
  car-2:                           "\e5a9",
  car-3:                           "\e5aa",
  car-4:                           "\e5ab",
  helicopter:                      "\e5ac",
  mountain-bike:                   "\e5ad",
  pickup:                          "\e5ae",
  plane-1:                         "\e5af",
  plane-2:                         "\e5b0",
  plane-landing:                   "\e5b1",
  plane-takeoff:                   "\e5b2",
  road:                            "\e5b3",
  road-bike:                       "\e5b4",
  rocket:                          "\e5b5",
  scooter:                         "\e5b6",
  ship:                            "\e5b7",
  train:                           "\e5b8",
  tram:                            "\e5b9",
  cactus:                          "\e5ba",
  clover:                          "\e5bb",
  flower:                          "\e5bc",
  hand-eco:                        "\e5bd",
  hand-globe:                      "\e5be",
  leaf:                            "\e5bf",
  light-eco:                       "\e5c0",
  potted-plant-1:                  "\e5c1",
  potted-plant-2:                  "\e5c2",
  2-fingers-double-tap:            "\e5c3",
  2-fingers-down-swipe:            "\e5c4",
  2-fingers-horizontal-swipe:      "\e5c5",
  2-fingers-left-swipe:            "\e5c6",
  2-fingers-omnidirectional-swipe: "\e5c7",
  2-fingers-right-swipe:           "\e5c8",
  2-fingers-tab-hold:              "\e5c9",
  2-fingers-tap:                   "\e5ca",
  2-fingers-up-swipe:              "\e5cb",
  2-fingers-vertical-swipe:        "\e5cc",
  double-tap:                      "\e5cd",
  drag-down:                       "\e5ce",
  drag-horizontal:                 "\e5cf",
  drag-left:                       "\e5d0",
  drag-right:                      "\e5d1",
  drag-up:                         "\e5d2",
  drag-vertical:                   "\e5d3",
  filck-down:                      "\e5d4",
  flick-up:                        "\e5d5",
  horizontal-flick:                "\e5d6",
  left-flick:                      "\e5d7",
  omnidirectional-drag:            "\e5d8",
  omnidirectional-flick:           "\e5d9",
  omnidirectional-swipe:           "\e5da",
  pinch:                           "\e5db",
  right-flick:                     "\e5dc",
  rotate-clockwise:                "\e5dd",
  rotate-counterclockwise:         "\e5de",
  spread:                          "\e5df",
  swipe-down:                      "\e5e0",
  swipe-horizontal:                "\e5e1",
  swipe-left:                      "\e5e2",
  swipe-right:                     "\e5e3",
  swipe-up:                        "\e5e4",
  swipe-vertical:                  "\e5e5",
  tap:                             "\e5e6",
  tap-hold:                        "\e5e7",
  vertical-flick:                  "\e5e8",
  arrow-1-1:                       "\e5e9",
  arrow-2-1:                       "\e5ea",
  arrow-3:                         "\e5eb",
  arrow-4:                         "\e5ec",
  arrow-5:                         "\e5ed",
  arrow-6:                         "\e5ee",
  arrow-7:                         "\e5ef",
  arrow-8:                         "\e5f0",
  arrow-9:                         "\e5f1",
  arrow-10:                        "\e5f2",
  arrow-11:                        "\e5f3",
  arrow-12:                        "\e5f4",
  arrow-13:                        "\e5f5",
  arrow-14:                        "\e5f6",
  arrow-15:                        "\e5f7",
  arrow-16:                        "\e5f8",
  arrow-17:                        "\e5f9",
  arrow-18:                        "\e5fa",
  arrow-19:                        "\e5fb",
  arrow-20:                        "\e5fc",
  arrow-21:                        "\e5fd",
  arrow-22:                        "\e5fe",
  arrow-23:                        "\e5ff",
  arrow-24:                        "\e600",
  arrow-25:                        "\e601",
  arrow-26:                        "\e602",
  arrow-27:                        "\e603",
  arrow-28:                        "\e604",
  arrow-29:                        "\e605",
  arrow-30:                        "\e606",
  arrow-31:                        "\e607",
  arrow-32:                        "\e608",
  arrow-33:                        "\e609",
  arrow-34:                        "\e60a",
  arrow-35:                        "\e60b",
  arrow-36:                        "\e60c",
  arrow-37:                        "\e60d",
  arrow-38:                        "\e60e",
  arrow-39:                        "\e60f",
  arrow-40:                        "\e610",
  arrow-41:                        "\e611",
  arrow-42:                        "\e612",
  arrow-43:                        "\e613",
  arrow-44:                        "\e614",
  arrow-45:                        "\e615",
  arrow-46:                        "\e616",
  arrow-47:                        "\e617",
  arrow-48:                        "\e618",
  arrow-49:                        "\e619",
  arrow-50:                        "\e61a",
  arrow-51:                        "\e61b",
  arrow-52:                        "\e61c",
  arrow-53:                        "\e61d",
  arrow-54:                        "\e61e",
  arrow-55:                        "\e61f",
  arrow-56:                        "\e620",
  arrow-57:                        "\e621",
  arrow-58:                        "\e622",
  arrow-59:                        "\e623",
  arrow-60:                        "\e624",
  arrow-61:                        "\e625",
  arrow-62:                        "\e626",
  arrow-63:                        "\e627",
  arrow-64:                        "\e628",
  arrow-65:                        "\e629",
  arrow-66:                        "\e62a",
  arrow-67:                        "\e62b",
  arrow-68:                        "\e62c",
  arrow-69:                        "\e62d",
  arrow-70:                        "\e62e",
  arrow-71:                        "\e62f",
  arrow-72:                        "\e630",
  arrow-circle-1:                  "\e631",
  arrow-circle-2:                  "\e632",
  arrow-circle-3:                  "\e633",
  arrow-circle-4:                  "\e634",
  arrow-circle-5:                  "\e635",
  arrow-circle-6:                  "\e636",
  arrow-circle-7:                  "\e637",
  arrow-circle-8:                  "\e638",
  arrow-circle-9:                  "\e639",
  arrow-circle-10:                 "\e63a",
  arrow-circle-11:                 "\e63b",
  arrow-circle-12:                 "\e63c",
  arrow-circle-13:                 "\e63d",
  arrow-circle-14:                 "\e63e",
  arrow-circle-15:                 "\e63f",
  arrow-circle-16:                 "\e640",
  arrow-circle-17:                 "\e641",
  arrow-circle-18:                 "\e642",
  arrow-circle-19:                 "\e643",
  arrow-circle-20:                 "\e644",
  arrow-circle-21:                 "\e645",
  arrow-circle-22:                 "\e646",
  arrow-circle-23:                 "\e647",
  arrow-circle-24:                 "\e648",
  arrow-circle-25:                 "\e649",
  arrow-circle-26:                 "\e64a",
  arrow-circle-27:                 "\e64b",
  arrow-circle-28:                 "\e64c",
  arrow-circle-29:                 "\e64d",
  arrow-circle-30:                 "\e64e",
  arrow-delete-1:                  "\e64f",
  arrow-delete-2:                  "\e650",
  arrow-dot-1:                     "\e651",
  arrow-dot-2:                     "\e652",
  arrow-dot-3:                     "\e653",
  arrow-dot-4:                     "\e654",
  arrow-dot-5:                     "\e655",
  arrow-dot-6:                     "\e656",
  arrow-rectangle-1:               "\e657",
  arrow-rectangle-2:               "\e658",
  arrow-rectangle-3:               "\e659",
  arrow-rectangle-4:               "\e65a",
  arrow-rectangle-5:               "\e65b",
  arrow-rectangle-6:               "\e65c",
  arrow-rectangle-7:               "\e65d",
  arrow-rectangle-8:               "\e65e",
  arrow-rectangle-9:               "\e65f",
  arrow-rectangle-10:              "\e660",
  arrow-rectangle-11:              "\e661",
  arrow-rectangle-12:              "\e662",
  arrow-rectangle-13:              "\e663",
  arrow-rectangle-14:              "\e664",
  arrow-rectangle-15:              "\e665",
  arrow-rectangle-16:              "\e666",
  arrow-rectangle-17:              "\e667",
  arrow-rectangle-18:              "\e668",
  arrow-rectangle-19:              "\e669",
  arrow-rectangle-20:              "\e66a",
);

$streamline-bin-1:                           "\e000";
$streamline-bin-2:                           "\e001";
$streamline-binocular:                       "\e002";
$streamline-bomb:                            "\e003";
$streamline-clip-1:                          "\e004";
$streamline-clip-2:                          "\e005";
$streamline-crosshair-1:                     "\e006";
$streamline-crosshair-2:                     "\e007";
$streamline-crosshair-3:                     "\e008";
$streamline-cutter:                          "\e009";
$streamline-delete-1:                        "\e00a";
$streamline-delete-2:                        "\e00b";
$streamline-edit-1:                          "\e00c";
$streamline-edit-2:                          "\e00d";
$streamline-edit-3:                          "\e00e";
$streamline-hide:                            "\e00f";
$streamline-ink:                             "\e010";
$streamline-key-1:                           "\e011";
$streamline-key-2:                           "\e012";
$streamline-link-1:                          "\e013";
$streamline-link-2:                          "\e014";
$streamline-link-3:                          "\e015";
$streamline-link-broken-1:                   "\e016";
$streamline-link-broken-2:                   "\e017";
$streamline-lock-1:                          "\e018";
$streamline-lock-2:                          "\e019";
$streamline-lock-3:                          "\e01a";
$streamline-lock-4:                          "\e01b";
$streamline-lock-5:                          "\e01c";
$streamline-lock-unlock-1:                   "\e01d";
$streamline-lock-unlock-2:                   "\e01e";
$streamline-magnifier:                       "\e01f";
$streamline-pen-1:                           "\e020";
$streamline-pen-2:                           "\e021";
$streamline-pen-3:                           "\e022";
$streamline-pen-4:                           "\e023";
$streamline-pencil-1:                        "\e024";
$streamline-pencil-2:                        "\e025";
$streamline-pencil-3:                        "\e026";
$streamline-pin-1:                           "\e027";
$streamline-pin-2:                           "\e028";
$streamline-power-1:                         "\e029";
$streamline-power-2:                         "\e02a";
$streamline-preview-1:                       "\e02b";
$streamline-preview-2:                       "\e02c";
$streamline-scissor-1:                       "\e02d";
$streamline-scissor-2:                       "\e02e";
$streamline-skull-1:                         "\e02f";
$streamline-skull-2:                         "\e030";
$streamline-type-1:                          "\e031";
$streamline-type-2:                          "\e032";
$streamline-type-3:                          "\e033";
$streamline-type-4:                          "\e034";
$streamline-zoom-area:                       "\e035";
$streamline-zoom-in:                         "\e036";
$streamline-zoom-out:                        "\e037";
$streamline-cursor-1:                        "\e038";
$streamline-cursor-2:                        "\e039";
$streamline-cursor-add:                      "\e03a";
$streamline-cursor-duplicate:                "\e03b";
$streamline-cursor-move:                     "\e03c";
$streamline-cursor-move-2:                   "\e03d";
$streamline-cursor-select-area:              "\e03e";
$streamline-hand:                            "\e03f";
$streamline-hand-block:                      "\e040";
$streamline-hand-grab-1:                     "\e041";
$streamline-hand-grab-2:                     "\e042";
$streamline-hand-point:                      "\e043";
$streamline-hand-touch-1:                    "\e044";
$streamline-hand-touch-2:                    "\e045";
$streamline-hand-touch-3:                    "\e046";
$streamline-hand-touch-4:                    "\e047";
$streamline-bookmark-1:                      "\e048";
$streamline-bookmark-2:                      "\e049";
$streamline-bookmark-3:                      "\e04a";
$streamline-bookmark-4:                      "\e04b";
$streamline-tag-1:                           "\e04c";
$streamline-tag-2:                           "\e04d";
$streamline-tag-add:                         "\e04e";
$streamline-tag-delete:                      "\e04f";
$streamline-tags-1:                          "\e050";
$streamline-tags-2:                          "\e051";
$streamline-anchor-point-1:                  "\e052";
$streamline-anchor-point-2:                  "\e053";
$streamline-arrange-1:                       "\e054";
$streamline-arrange-2:                       "\e055";
$streamline-artboard:                        "\e056";
$streamline-brush-1:                         "\e057";
$streamline-brush-2:                         "\e058";
$streamline-bucket:                          "\e059";
$streamline-crop:                            "\e05a";
$streamline-dropper-1:                       "\e05b";
$streamline-dropper-2:                       "\e05c";
$streamline-dropper-3:                       "\e05d";
$streamline-glue:                            "\e05e";
$streamline-grid:                            "\e05f";
$streamline-layers:                          "\e060";
$streamline-magic-wand-1:                    "\e061";
$streamline-magic-wand-2:                    "\e062";
$streamline-magnet:                          "\e063";
$streamline-marker:                          "\e064";
$streamline-palette:                         "\e065";
$streamline-pen-5:                           "\e066";
$streamline-pen-6:                           "\e067";
$streamline-quill:                           "\e068";
$streamline-reflect:                         "\e069";
$streamline-roller:                          "\e06a";
$streamline-ruler-1:                         "\e06b";
$streamline-ruler-2:                         "\e06c";
$streamline-scale-diagonal-1:                "\e06d";
$streamline-scale-diagonal-2:                "\e06e";
$streamline-scale-horizontal:                "\e06f";
$streamline-scale-tool-1:                    "\e070";
$streamline-scale-tool-2:                    "\e071";
$streamline-scale-tool-3:                    "\e072";
$streamline-scale-vertical:                  "\e073";
$streamline-shear-tool:                      "\e074";
$streamline-spray:                           "\e075";
$streamline-stamp:                           "\e076";
$streamline-stationery-1:                    "\e077";
$streamline-stationery-2:                    "\e078";
$streamline-stationery-3:                    "\e079";
$streamline-vector:                          "\e07a";
$streamline-award-1:                         "\e07b";
$streamline-award-2:                         "\e07c";
$streamline-award-3:                         "\e07d";
$streamline-award-4:                         "\e07e";
$streamline-award-5:                         "\e07f";
$streamline-award-6:                         "\e080";
$streamline-crown-1:                         "\e081";
$streamline-crown-2:                         "\e082";
$streamline-crown-3:                         "\e083";
$streamline-fire:                            "\e084";
$streamline-flag-1:                          "\e085";
$streamline-flag-2:                          "\e086";
$streamline-flag-3:                          "\e087";
$streamline-flag-4:                          "\e088";
$streamline-flag-5:                          "\e089";
$streamline-flag-6:                          "\e08a";
$streamline-flag-7:                          "\e08b";
$streamline-flag-8:                          "\e08c";
$streamline-google-plus-1:                   "\e08d";
$streamline-google-plus-2:                   "\e08e";
$streamline-hand-like-1:                     "\e08f";
$streamline-hand-like-2:                     "\e090";
$streamline-hand-unlike-1:                   "\e091";
$streamline-hand-unlike-2:                   "\e092";
$streamline-heart-1:                         "\e093";
$streamline-heart-2:                         "\e094";
$streamline-heart-angel:                     "\e095";
$streamline-heart-broken:                    "\e096";
$streamline-heart-minus:                     "\e097";
$streamline-heart-plus:                      "\e098";
$streamline-present:                         "\e099";
$streamline-rank-1:                          "\e09a";
$streamline-rank-2:                          "\e09b";
$streamline-ribbon:                          "\e09c";
$streamline-star-1:                          "\e09d";
$streamline-star-2:                          "\e09e";
$streamline-star-3:                          "\e09f";
$streamline-star-4:                          "\e0a0";
$streamline-star-5:                          "\e0a1";
$streamline-star-6:                          "\e0a2";
$streamline-star-7:                          "\e0a3";
$streamline-star-8:                          "\e0a4";
$streamline-star-9:                          "\e0a5";
$streamline-star-10:                         "\e0a6";
$streamline-trophy:                          "\e0a7";
$streamline-bubble-2:                        "\e0a8";
$streamline-bubble-add-1:                    "\e0a9";
$streamline-bubble-add-2:                    "\e0aa";
$streamline-bubble-add-3:                    "\e0ab";
$streamline-bubble-ask-1:                    "\e0ac";
$streamline-bubble-ask-2:                    "\e0ad";
$streamline-bubble-attention-1:              "\e0ae";
$streamline-bubble-attention-2:              "\e0af";
$streamline-bubble-attention-3:              "\e0b0";
$streamline-bubble-attention-4:              "\e0b1";
$streamline-bubble-attention-5:              "\e0b2";
$streamline-bubble-attention-6:              "\e0b3";
$streamline-bubble-attention-7:              "\e0b4";
$streamline-bubble-block-1:                  "\e0b5";
$streamline-bubble-block-2:                  "\e0b6";
$streamline-bubble-block-3:                  "\e0b7";
$streamline-bubble-chat-1:                   "\e0b8";
$streamline-bubble-chat-2:                   "\e0b9";
$streamline-bubble-check-1:                  "\e0ba";
$streamline-bubble-check-2:                  "\e0bb";
$streamline-bubble-check-3:                  "\e0bc";
$streamline-bubble-comment-1:                "\e0bd";
$streamline-bubble-comment-2:                "\e0be";
$streamline-bubble-conversation-1:           "\e0bf";
$streamline-bubble-conversation-2:           "\e0c0";
$streamline-bubble-conversation-3:           "\e0c1";
$streamline-bubble-conversation-4:           "\e0c2";
$streamline-bubble-conversation-5:           "\e0c3";
$streamline-bubble-conversation-6:           "\e0c4";
$streamline-bubble-delete-1:                 "\e0c5";
$streamline-bubble-delete-2:                 "\e0c6";
$streamline-bubble-delete-3:                 "\e0c7";
$streamline-bubble-edit-1:                   "\e0c8";
$streamline-bubble-edit-2:                   "\e0c9";
$streamline-bubble-edit-3:                   "\e0ca";
$streamline-bubble-heart-1:                  "\e0cb";
$streamline-bubble-heart-2:                  "\e0cc";
$streamline-bubble-minus-1:                  "\e0cd";
$streamline-bubble-minus-2:                  "\e0ce";
$streamline-bubble-minus-3:                  "\e0cf";
$streamline-bubble-quote-1:                  "\e0d0";
$streamline-bubble-quote-2:                  "\e0d1";
$streamline-bubble-smiley-3:                 "\e0d2";
$streamline-bubble-smiley-4:                 "\e0d3";
$streamline-bubble-smiley-smile:             "\e0d4";
$streamline-bubble-smiley-wink:              "\e0d5";
$streamline-bubble-star-1:                   "\e0d6";
$streamline-bubble-star-2:                   "\e0d7";
$streamline-bubble-star-3:                   "\e0d8";
$streamline-chat-1:                          "\e0d9";
$streamline-chat-2:                          "\e0da";
$streamline-chat-3:                          "\e0db";
$streamline-chat-4:                          "\e0dc";
$streamline-chat-5:                          "\e0dd";
$streamline-chat-bubble-1:                   "\e0de";
$streamline-chat-bubble-2:                   "\e0df";
$streamline-smiley-happy-1:                  "\e0e0";
$streamline-smiley-happy-2:                  "\e0e1";
$streamline-smiley-happy-3:                  "\e0e2";
$streamline-smiley-happy-4:                  "\e0e3";
$streamline-smiley-happy-5:                  "\e0e4";
$streamline-smiley-relax:                    "\e0e5";
$streamline-smiley-sad:                      "\e0e6";
$streamline-smiley-surprise:                 "\e0e7";
$streamline-thinking-1:                      "\e0e8";
$streamline-thinking-2:                      "\e0e9";
$streamline-call-1:                          "\e0ea";
$streamline-call-2:                          "\e0eb";
$streamline-call-3:                          "\e0ec";
$streamline-call-4:                          "\e0ed";
$streamline-call-add:                        "\e0ee";
$streamline-call-block:                      "\e0ef";
$streamline-call-delete:                     "\e0f0";
$streamline-call-in:                         "\e0f1";
$streamline-call-minus:                      "\e0f2";
$streamline-call-out:                        "\e0f3";
$streamline-contact:                         "\e0f4";
$streamline-fax:                             "\e0f5";
$streamline-hang-up:                         "\e0f6";
$streamline-message:                         "\e0f7";
$streamline-mobile-phone-1:                  "\e0f8";
$streamline-mobile-phone-2:                  "\e0f9";
$streamline-phone-1:                         "\e0fa";
$streamline-phone-2:                         "\e0fb";
$streamline-phone-3:                         "\e0fc";
$streamline-phone-4:                         "\e0fd";
$streamline-phone-vibration:                 "\e0fe";
$streamline-signal-fine:                     "\e0ff";
$streamline-signal-full:                     "\e100";
$streamline-signal-high:                     "\e101";
$streamline-signal-no:                       "\e102";
$streamline-signal-poor:                     "\e103";
$streamline-signal-weak:                     "\e104";
$streamline-smartphone:                      "\e105";
$streamline-tape:                            "\e106";
$streamline-camera-symbol-1:                 "\e107";
$streamline-camera-symbol-2:                 "\e108";
$streamline-camera-symbol-3:                 "\e109";
$streamline-headphone:                       "\e10a";
$streamline-antenna-1:                       "\e10b";
$streamline-antenna-2:                       "\e10c";
$streamline-antenna-3:                       "\e10d";
$streamline-hotspot-1:                       "\e10e";
$streamline-hotspot-2:                       "\e10f";
$streamline-link:                            "\e110";
$streamline-megaphone-1:                     "\e111";
$streamline-megaphone-2:                     "\e112";
$streamline-radar:                           "\e113";
$streamline-rss-1:                           "\e114";
$streamline-rss-2:                           "\e115";
$streamline-satellite:                       "\e116";
$streamline-address-1:                       "\e117";
$streamline-address-2:                       "\e118";
$streamline-address-3:                       "\e119";
$streamline-forward:                         "\e11a";
$streamline-inbox-1:                         "\e11b";
$streamline-inbox-2:                         "\e11c";
$streamline-inbox-3:                         "\e11d";
$streamline-inbox-4:                         "\e11e";
$streamline-letter-1:                        "\e11f";
$streamline-letter-2:                        "\e120";
$streamline-letter-3:                        "\e121";
$streamline-letter-4:                        "\e122";
$streamline-letter-5:                        "\e123";
$streamline-mail-1:                          "\e124";
$streamline-mail-2:                          "\e125";
$streamline-mail-add:                        "\e126";
$streamline-mail-attention:                  "\e127";
$streamline-mail-block:                      "\e128";
$streamline-mail-box-1:                      "\e129";
$streamline-mail-box-2:                      "\e12a";
$streamline-mail-box-3:                      "\e12b";
$streamline-mail-checked:                    "\e12c";
$streamline-mail-compose:                    "\e12d";
$streamline-mail-delete:                     "\e12e";
$streamline-mail-favorite:                   "\e12f";
$streamline-mail-inbox:                      "\e130";
$streamline-mail-lock:                       "\e131";
$streamline-mail-minus:                      "\e132";
$streamline-mail-read:                       "\e133";
$streamline-mail-recieved-1:                 "\e134";
$streamline-mail-recieved-2:                 "\e135";
$streamline-mail-search-1:                   "\e136";
$streamline-mail-search-2:                   "\e137";
$streamline-mail-sent-1:                     "\e138";
$streamline-mail-sent-2:                     "\e139";
$streamline-mail-setting:                    "\e13a";
$streamline-mail-star:                       "\e13b";
$streamline-mail-sync:                       "\e13c";
$streamline-mail-time:                       "\e13d";
$streamline-outbox-1:                        "\e13e";
$streamline-outbox-2:                        "\e13f";
$streamline-plane-paper-1:                   "\e140";
$streamline-plane-paper-2:                   "\e141";
$streamline-reply-mail-1:                    "\e142";
$streamline-reply-mail-2:                    "\e143";
$streamline-connection-1:                    "\e144";
$streamline-connection-2:                    "\e145";
$streamline-connection-3:                    "\e146";
$streamline-contacts-1:                      "\e147";
$streamline-contacts-2:                      "\e148";
$streamline-contacts-3:                      "\e149";
$streamline-contacts-4:                      "\e14a";
$streamline-female:                          "\e14b";
$streamline-gender-female:                   "\e14c";
$streamline-gender-male:                     "\e14d";
$streamline-genders:                         "\e14e";
$streamline-id-1:                            "\e14f";
$streamline-id-2:                            "\e150";
$streamline-id-3:                            "\e151";
$streamline-id-4:                            "\e152";
$streamline-id-5:                            "\e153";
$streamline-id-6:                            "\e154";
$streamline-id-7:                            "\e155";
$streamline-id-8:                            "\e156";
$streamline-male:                            "\e157";
$streamline-profile-1:                       "\e158";
$streamline-profile-2:                       "\e159";
$streamline-profile-3:                       "\e15a";
$streamline-profile-4:                       "\e15b";
$streamline-profile-5:                       "\e15c";
$streamline-profile-6:                       "\e15d";
$streamline-profile-athlete:                 "\e15e";
$streamline-profile-bussiness-man:           "\e15f";
$streamline-profile-chef:                    "\e160";
$streamline-profile-cop:                     "\e161";
$streamline-profile-doctor-1:                "\e162";
$streamline-profile-doctor-2:                "\e163";
$streamline-profile-gentleman-1:             "\e164";
$streamline-profile-gentleman-2:             "\e165";
$streamline-profile-graduate:                "\e166";
$streamline-profile-king:                    "\e167";
$streamline-profile-lady-1:                  "\e168";
$streamline-profile-lady-2:                  "\e169";
$streamline-profile-man:                     "\e16a";
$streamline-profile-nurse1:                  "\e16b";
$streamline-profile-nurse-2:                 "\e16c";
$streamline-profile-prisoner:                "\e16d";
$streamline-profile-serviceman-1:            "\e16e";
$streamline-profile-serviceman-2:            "\e16f";
$streamline-profile-spy:                     "\e170";
$streamline-profile-teacher:                 "\e171";
$streamline-profile-thief:                   "\e172";
$streamline-user-1:                          "\e173";
$streamline-user-2:                          "\e174";
$streamline-user-add-1:                      "\e175";
$streamline-user-add-2:                      "\e176";
$streamline-user-block-1:                    "\e177";
$streamline-user-block-2:                    "\e178";
$streamline-user-checked-1:                  "\e179";
$streamline-user-checked-2:                  "\e17a";
$streamline-user-delete-1:                   "\e17b";
$streamline-user-delete-2:                   "\e17c";
$streamline-user-edit-1:                     "\e17d";
$streamline-user-edit-2:                     "\e17e";
$streamline-user-heart-1:                    "\e17f";
$streamline-user-heart-2:                    "\e180";
$streamline-user-lock-1:                     "\e181";
$streamline-user-lock-2:                     "\e182";
$streamline-user-minus-1:                    "\e183";
$streamline-user-minus-2:                    "\e184";
$streamline-user-search-1:                   "\e185";
$streamline-user-search-2:                   "\e186";
$streamline-user-setting-1:                  "\e187";
$streamline-user-setting-2:                  "\e188";
$streamline-user-star-1:                     "\e189";
$streamline-user-star-2:                     "\e18a";
$streamline-bag-shopping-1:                  "\e18c";
$streamline-bag-shopping-2:                  "\e18d";
$streamline-bag-shopping-3:                  "\e18e";
$streamline-basket-1:                        "\e18f";
$streamline-basket-2:                        "\e190";
$streamline-basket-3:                        "\e191";
$streamline-basket-add:                      "\e192";
$streamline-basket-minus:                    "\e193";
$streamline-briefcase:                       "\e194";
$streamline-cart-1:                          "\e195";
$streamline-cart-2:                          "\e196";
$streamline-cart-3:                          "\e197";
$streamline-cart-4:                          "\e198";
$streamline-cut:                             "\e199";
$streamline-handbag-1:                       "\e19a";
$streamline-handbag-2:                       "\e19b";
$streamline-purse-1:                         "\e19c";
$streamline-purse-2:                         "\e19d";
$streamline-qr-code:                         "\e19e";
$streamline-receipt-1:                       "\e19f";
$streamline-receipt-2:                       "\e1a0";
$streamline-receipt-3:                       "\e1a1";
$streamline-receipt-4:                       "\e1a2";
$streamline-shopping-1:                      "\e1a3";
$streamline-sign-new-1:                      "\e1a4";
$streamline-sign-new-2:                      "\e1a5";
$streamline-sign-parking:                    "\e1a6";
$streamline-signal-star:                     "\e1a7";
$streamline-trolley-1:                       "\e1a8";
$streamline-trolley-2:                       "\e1a9";
$streamline-trolley-3:                       "\e1aa";
$streamline-trolley-load:                    "\e1ab";
$streamline-trolley-off:                     "\e1ac";
$streamline-wallet-1:                        "\e1ad";
$streamline-wallet-2:                        "\e1ae";
$streamline-wallet-3:                        "\e1af";
$streamline-camera-1:                        "\e1b0";
$streamline-camera-2:                        "\e1b1";
$streamline-camera-3:                        "\e1b2";
$streamline-camera-4:                        "\e1b3";
$streamline-camera-5:                        "\e1b4";
$streamline-camera-back:                     "\e1b5";
$streamline-camera-focus:                    "\e1b6";
$streamline-camera-frames:                   "\e1b7";
$streamline-camera-front:                    "\e1b8";
$streamline-camera-graph-1:                  "\e1b9";
$streamline-camera-graph-2:                  "\e1ba";
$streamline-camera-landscape:                "\e1bb";
$streamline-camera-lens-1:                   "\e1bc";
$streamline-camera-lens-2:                   "\e1bd";
$streamline-camera-light:                    "\e1be";
$streamline-camera-portrait:                 "\e1bf";
$streamline-camera-view:                     "\e1c0";
$streamline-film-1:                          "\e1c1";
$streamline-film-2:                          "\e1c2";
$streamline-photo-1:                         "\e1c3";
$streamline-photo-2:                         "\e1c4";
$streamline-photo-frame:                     "\e1c5";
$streamline-photos-1:                        "\e1c6";
$streamline-photos-2:                        "\e1c7";
$streamline-polaroid:                        "\e1c8";
$streamline-signal-camera-1:                 "\e1c9";
$streamline-signal-camera-2:                 "\e1ca";
$streamline-user-photo:                      "\e1cb";
$streamline-backward-1:                      "\e1cc";
$streamline-dvd-player:                      "\e1cd";
$streamline-eject-1:                         "\e1ce";
$streamline-film-3:                          "\e1cf";
$streamline-forward-1:                       "\e1d0";
$streamline-handycam:                        "\e1d1";
$streamline-movie-play-1:                    "\e1d2";
$streamline-movie-play-2:                    "\e1d3";
$streamline-movie-play-3:                    "\e1d4";
$streamline-next-1:                          "\e1d5";
$streamline-pause-1:                         "\e1d6";
$streamline-play-1:                          "\e1d7";
$streamline-player:                          "\e1d8";
$streamline-previous-1:                      "\e1d9";
$streamline-record-1:                        "\e1da";
$streamline-slate:                           "\e1db";
$streamline-stop-1:                          "\e1dc";
$streamline-television:                      "\e1dd";
$streamline-video-camera-1:                  "\e1de";
$streamline-video-camera-2:                  "\e1df";
$streamline-backward-2:                      "\e1e0";
$streamline-cd:                              "\e1e1";
$streamline-eject-2:                         "\e1e2";
$streamline-equalizer-2:                     "\e1e3";
$streamline-equalizer-3:                     "\e1e4";
$streamline-forward-2:                       "\e1e5";
$streamline-gramophone:                      "\e1e6";
$streamline-gramophone-record-2:             "\e1e7";
$streamline-guitar:                          "\e1e8";
$streamline-headphone-1:                     "\e1e9";
$streamline-headphone-2:                     "\e1ea";
$streamline-microphone-1:                    "\e1eb";
$streamline-microphone-2:                    "\e1ec";
$streamline-microphone-3:                    "\e1ed";
$streamline-movie-play-4:                    "\e1ee";
$streamline-music-note-1:                    "\e1ef";
$streamline-music-note-2:                    "\e1f0";
$streamline-music-note-3:                    "\e1f1";
$streamline-music-note-4:                    "\e1f2";
$streamline-next-2:                          "\e1f3";
$streamline-notes-1:                         "\e1f4";
$streamline-notes-2:                         "\e1f5";
$streamline-pause-2:                         "\e1f6";
$streamline-piano:                           "\e1f7";
$streamline-play-2:                          "\e1f8";
$streamline-playlist:                        "\e1f9";
$streamline-previous-2:                      "\e1fa";
$streamline-radio-1:                         "\e1fb";
$streamline-radio-2:                         "\e1fc";
$streamline-record-2:                        "\e1fd";
$streamline-recorder:                        "\e1fe";
$streamline-saxophone:                       "\e1ff";
$streamline-speaker-1:                       "\e200";
$streamline-speaker-2:                       "\e201";
$streamline-speaker-3:                       "\e202";
$streamline-stop-2:                          "\e203";
$streamline-tape-1:                          "\e204";
$streamline-trumpet:                         "\e205";
$streamline-volume-down-1:                   "\e206";
$streamline-volume-down-2:                   "\e207";
$streamline-volume-loud-1:                   "\e208";
$streamline-volume-loud-2:                   "\e209";
$streamline-volume-low-1:                    "\e20a";
$streamline-volume-low-2:                    "\e20b";
$streamline-volume-medium-1:                 "\e20c";
$streamline-volume-medium-2:                 "\e20d";
$streamline-volume-mute-1:                   "\e20e";
$streamline-volume-mute-2:                   "\e20f";
$streamline-volume-mute-3:                   "\e210";
$streamline-volume-up-1:                     "\e211";
$streamline-volume-up-2:                     "\e212";
$streamline-walkman:                         "\e213";
$streamline-cloud:                           "\e214";
$streamline-cloud-add:                       "\e215";
$streamline-cloud-checked:                   "\e216";
$streamline-cloud-delete:                    "\e217";
$streamline-cloud-download:                  "\e218";
$streamline-cloud-minus:                     "\e219";
$streamline-cloud-refresh:                   "\e21a";
$streamline-cloud-sync:                      "\e21b";
$streamline-cloud-upload:                    "\e21c";
$streamline-download-1:                      "\e21d";
$streamline-download-2:                      "\e21e";
$streamline-download-3:                      "\e21f";
$streamline-download-4:                      "\e220";
$streamline-download-5:                      "\e221";
$streamline-download-6:                      "\e222";
$streamline-download-7:                      "\e223";
$streamline-download-8:                      "\e224";
$streamline-download-9:                      "\e225";
$streamline-download-10:                     "\e226";
$streamline-download-11:                     "\e227";
$streamline-download-12:                     "\e228";
$streamline-download-13:                     "\e229";
$streamline-download-14:                     "\e22a";
$streamline-download-15:                     "\e22b";
$streamline-download-file:                   "\e22c";
$streamline-download-folder:                 "\e22d";
$streamline-goal-1:                          "\e22e";
$streamline-goal-2:                          "\e22f";
$streamline-transfer-1:                      "\e230";
$streamline-transfer-2:                      "\e231";
$streamline-transfer-3:                      "\e232";
$streamline-transfer-4:                      "\e233";
$streamline-transfer-5:                      "\e234";
$streamline-transfer-6:                      "\e235";
$streamline-transfer-7:                      "\e236";
$streamline-transfer-8:                      "\e237";
$streamline-transfer-9:                      "\e238";
$streamline-transfer-10:                     "\e239";
$streamline-transfer-11:                     "\e23a";
$streamline-transfer-12:                     "\e23b";
$streamline-upload-1:                        "\e23c";
$streamline-upload-2:                        "\e23d";
$streamline-upload-3:                        "\e23e";
$streamline-upload-4:                        "\e23f";
$streamline-upload-5:                        "\e240";
$streamline-upload-6:                        "\e241";
$streamline-upload-7:                        "\e242";
$streamline-upload-8:                        "\e243";
$streamline-upload-9:                        "\e244";
$streamline-upload-10:                       "\e245";
$streamline-upload-11:                       "\e246";
$streamline-upload-12:                       "\e247";
$streamline-clipboard-1:                     "\e248";
$streamline-clipboard-2:                     "\e249";
$streamline-clipboard-3:                     "\e24a";
$streamline-clipboard-add:                   "\e24b";
$streamline-clipboard-block:                 "\e24c";
$streamline-clipboard-checked:               "\e24d";
$streamline-clipboard-delete:                "\e24e";
$streamline-clipboard-edit:                  "\e24f";
$streamline-clipboard-minus:                 "\e250";
$streamline-document-1:                      "\e251";
$streamline-document-2:                      "\e252";
$streamline-file-1:                          "\e253";
$streamline-file-2:                          "\e254";
$streamline-file-add:                        "\e255";
$streamline-file-attention:                  "\e256";
$streamline-file-block:                      "\e257";
$streamline-file-bookmark:                   "\e258";
$streamline-file-checked:                    "\e259";
$streamline-file-code:                       "\e25a";
$streamline-file-delete:                     "\e25b";
$streamline-file-download:                   "\e25c";
$streamline-file-edit:                       "\e25d";
$streamline-file-favorite-1:                 "\e25e";
$streamline-file-favorite-2:                 "\e25f";
$streamline-file-graph-1:                    "\e260";
$streamline-file-graph-2:                    "\e261";
$streamline-file-home:                       "\e262";
$streamline-file-image-1:                    "\e263";
$streamline-file-image-2:                    "\e264";
$streamline-file-list:                       "\e265";
$streamline-file-lock:                       "\e266";
$streamline-file-media:                      "\e267";
$streamline-file-minus:                      "\e268";
$streamline-file-music:                      "\e269";
$streamline-file-new:                        "\e26a";
$streamline-file-registry:                   "\e26b";
$streamline-file-search:                     "\e26c";
$streamline-file-setting:                    "\e26d";
$streamline-file-sync:                       "\e26e";
$streamline-file-table:                      "\e26f";
$streamline-file-thumbnail:                  "\e270";
$streamline-file-time:                       "\e271";
$streamline-file-transfer:                   "\e272";
$streamline-file-upload:                     "\e273";
$streamline-file-zip:                        "\e274";
$streamline-files-1:                         "\e275";
$streamline-files-2:                         "\e276";
$streamline-files-3:                         "\e277";
$streamline-files-4:                         "\e278";
$streamline-files-5:                         "\e279";
$streamline-files-6:                         "\e27a";
$streamline-hand-file-1:                     "\e27b";
$streamline-hand-file-2:                     "\e27c";
$streamline-note-paper-1:                    "\e27d";
$streamline-note-paper-2:                    "\e27e";
$streamline-note-paper-add:                  "\e27f";
$streamline-note-paper-attention:            "\e280";
$streamline-note-paper-block:                "\e281";
$streamline-note-paper-checked:              "\e282";
$streamline-note-paper-delete:               "\e283";
$streamline-note-paper-download:             "\e284";
$streamline-note-paper-edit:                 "\e285";
$streamline-note-paper-favorite:             "\e286";
$streamline-note-paper-lock:                 "\e287";
$streamline-note-paper-minus:                "\e288";
$streamline-note-paper-search:               "\e289";
$streamline-note-paper-sync:                 "\e28a";
$streamline-note-paper-upload:               "\e28b";
$streamline-print:                           "\e28c";
$streamline-folder-1:                        "\e28d";
$streamline-folder-2:                        "\e28e";
$streamline-folder-3:                        "\e28f";
$streamline-folder-4:                        "\e290";
$streamline-folder-add:                      "\e291";
$streamline-folder-attention:                "\e292";
$streamline-folder-block:                    "\e293";
$streamline-folder-bookmark:                 "\e294";
$streamline-folder-checked:                  "\e295";
$streamline-folder-code:                     "\e296";
$streamline-folder-delete:                   "\e297";
$streamline-folder-download:                 "\e298";
$streamline-folder-edit:                     "\e299";
$streamline-folder-favorite:                 "\e29a";
$streamline-folder-home:                     "\e29b";
$streamline-folder-image:                    "\e29c";
$streamline-folder-lock:                     "\e29d";
$streamline-folder-media:                    "\e29e";
$streamline-folder-minus:                    "\e29f";
$streamline-folder-music:                    "\e2a0";
$streamline-folder-new:                      "\e2a1";
$streamline-folder-search:                   "\e2a2";
$streamline-folder-setting:                  "\e2a3";
$streamline-folder-share-1:                  "\e2a4";
$streamline-folder-share-2:                  "\e2a5";
$streamline-folder-sync:                     "\e2a6";
$streamline-folder-transfer:                 "\e2a7";
$streamline-folder-upload:                   "\e2a8";
$streamline-folder-zip:                      "\e2a9";
$streamline-add-1:                           "\e2aa";
$streamline-add-2:                           "\e2ab";
$streamline-add-3:                           "\e2ac";
$streamline-add-4:                           "\e2ad";
$streamline-add-tag:                         "\e2ae";
$streamline-arrow-1:                         "\e2af";
$streamline-arrow-2:                         "\e2b0";
$streamline-arrow-down-1:                    "\e2b1";
$streamline-arrow-down-2:                    "\e2b2";
$streamline-arrow-left-1:                    "\e2b3";
$streamline-arrow-left-2:                    "\e2b4";
$streamline-arrow-move-1:                    "\e2b5";
$streamline-arrow-move-down:                 "\e2b6";
$streamline-arrow-move-left:                 "\e2b7";
$streamline-arrow-move-right:                "\e2b8";
$streamline-arrow-move-up:                   "\e2b9";
$streamline-arrow-right-1:                   "\e2ba";
$streamline-arrow-right-2:                   "\e2bb";
$streamline-arrow-up-1:                      "\e2bc";
$streamline-arrow-up-2:                      "\e2bd";
$streamline-back:                            "\e2be";
$streamline-center-expand:                   "\e2bf";
$streamline-center-reduce:                   "\e2c0";
$streamline-delete-1-1:                      "\e2c1";
$streamline-delete-2-1:                      "\e2c2";
$streamline-delete-3:                        "\e2c3";
$streamline-delete-4:                        "\e2c4";
$streamline-delete-tag:                      "\e2c5";
$streamline-expand-horizontal:               "\e2c6";
$streamline-expand-vertical:                 "\e2c7";
$streamline-forward-3:                       "\e2c8";
$streamline-infinity:                        "\e2c9";
$streamline-loading:                         "\e2ca";
$streamline-log-out-1:                       "\e2cb";
$streamline-log-out-2:                       "\e2cc";
$streamline-loop-1:                          "\e2cd";
$streamline-loop-2:                          "\e2ce";
$streamline-loop-3:                          "\e2cf";
$streamline-minus-1:                         "\e2d0";
$streamline-minus-2:                         "\e2d1";
$streamline-minus-3:                         "\e2d2";
$streamline-minus-4:                         "\e2d3";
$streamline-minus-tag:                       "\e2d4";
$streamline-move-diagonal-1:                 "\e2d5";
$streamline-move-diagonal-2:                 "\e2d6";
$streamline-move-horizontal-1:               "\e2d7";
$streamline-move-horizontal-2:               "\e2d8";
$streamline-move-vertical-1:                 "\e2d9";
$streamline-move-vertical-2:                 "\e2da";
$streamline-next-1-1:                        "\e2db";
$streamline-next-2-1:                        "\e2dc";
$streamline-power-1-1:                       "\e2dd";
$streamline-power-2-1:                       "\e2de";
$streamline-power-3:                         "\e2df";
$streamline-power-4:                         "\e2e0";
$streamline-recycle:                         "\e2e1";
$streamline-refresh:                         "\e2e2";
$streamline-repeat:                          "\e2e3";
$streamline-return:                          "\e2e4";
$streamline-scale-all-1:                     "\e2e5";
$streamline-scale-center:                    "\e2e6";
$streamline-scale-horizontal-1:              "\e2e7";
$streamline-scale-horizontal-2:              "\e2e8";
$streamline-scale-reduce-1:                  "\e2e9";
$streamline-scale-reduce-2:                  "\e2ea";
$streamline-scale-reduce-3:                  "\e2eb";
$streamline-scale-spread-1:                  "\e2ec";
$streamline-scale-spread-2:                  "\e2ed";
$streamline-scale-spread-3:                  "\e2ee";
$streamline-scale-vertical-1:                "\e2ef";
$streamline-scale-vertical-2:                "\e2f0";
$streamline-scroll-horizontal-1:             "\e2f1";
$streamline-scroll-horizontal-2:             "\e2f2";
$streamline-scroll-omnidirectional-1:        "\e2f3";
$streamline-scroll-omnidirectional-2:        "\e2f4";
$streamline-scroll-vertical-1:               "\e2f5";
$streamline-scroll-vertical-2:               "\e2f6";
$streamline-shuffle:                         "\e2f7";
$streamline-split:                           "\e2f8";
$streamline-sync-1:                          "\e2f9";
$streamline-sync-2:                          "\e2fa";
$streamline-timer:                           "\e2fb";
$streamline-transfer:                        "\e2fc";
$streamline-transfer-1-1:                    "\e2fd";
$streamline-check-1:                         "\e2fe";
$streamline-check-2:                         "\e2ff";
$streamline-check-3:                         "\e300";
$streamline-check-box:                       "\e301";
$streamline-check-bubble:                    "\e302";
$streamline-check-circle-1:                  "\e303";
$streamline-check-circle-2:                  "\e304";
$streamline-check-list:                      "\e305";
$streamline-check-shield:                    "\e306";
$streamline-cross:                           "\e307";
$streamline-cross-bubble:                    "\e308";
$streamline-cross-shield:                    "\e309";
$streamline-briefcase-1:                     "\e30a";
$streamline-brightness-high:                 "\e30b";
$streamline-brightness-low:                  "\e30c";
$streamline-hammer-1:                        "\e30d";
$streamline-hammer-2:                        "\e30e";
$streamline-pulse:                           "\e30f";
$streamline-scale:                           "\e310";
$streamline-screw-driver:                    "\e311";
$streamline-setting-adjustment:              "\e312";
$streamline-setting-gear:                    "\e313";
$streamline-setting-gears-1:                 "\e314";
$streamline-setting-gears-2:                 "\e315";
$streamline-setting-wrenches:                "\e316";
$streamline-switch-1:                        "\e317";
$streamline-switch-2:                        "\e318";
$streamline-wrench:                          "\e319";
$streamline-alarm-1:                         "\e31a";
$streamline-alarm-clock:                     "\e31b";
$streamline-alarm-off:                       "\e31c";
$streamline-alarm-snooze:                    "\e31d";
$streamline-bell:                            "\e31e";
$streamline-calendar-1:                      "\e31f";
$streamline-calendar-2:                      "\e320";
$streamline-clock-1:                         "\e321";
$streamline-clock-2:                         "\e322";
$streamline-clock-3:                         "\e323";
$streamline-hourglass-1:                     "\e324";
$streamline-hourglass-2:                     "\e325";
$streamline-timer-1:                         "\e326";
$streamline-timer-3-quarter-1:               "\e327";
$streamline-timer-3-quarter-2:               "\e328";
$streamline-timer-full-1:                    "\e329";
$streamline-timer-full-2:                    "\e32a";
$streamline-timer-half-1:                    "\e32b";
$streamline-timer-half-2:                    "\e32c";
$streamline-timer-half-3:                    "\e32d";
$streamline-timer-half-4:                    "\e32e";
$streamline-timer-quarter-1:                 "\e32f";
$streamline-timer-quarter-2:                 "\e330";
$streamline-watch-1:                         "\e331";
$streamline-watch-2:                         "\e332";
$streamline-alert-1:                         "\e333";
$streamline-alert-2:                         "\e334";
$streamline-alert-3:                         "\e335";
$streamline-information:                     "\e336";
$streamline-nuclear-1:                       "\e337";
$streamline-nuclear-2:                       "\e338";
$streamline-question-mark:                   "\e339";
$streamline-abacus:                          "\e33a";
$streamline-amex-card:                       "\e33b";
$streamline-atm:                             "\e33c";
$streamline-balance:                         "\e33d";
$streamline-bank-1:                          "\e33e";
$streamline-bank-2:                          "\e33f";
$streamline-bank-note-1:                     "\e340";
$streamline-bank-note-2:                     "\e341";
$streamline-bank-note-3:                     "\e342";
$streamline-bitcoins:                        "\e343";
$streamline-board:                           "\e344";
$streamline-box-1:                           "\e345";
$streamline-box-2:                           "\e346";
$streamline-box-3:                           "\e347";
$streamline-box-download:                    "\e348";
$streamline-box-shipping:                    "\e349";
$streamline-box-upload:                      "\e34a";
$streamline-business-chart-1:                "\e34b";
$streamline-business-chart-2:                "\e34c";
$streamline-calculator-1:                    "\e34d";
$streamline-calculator-2:                    "\e34e";
$streamline-calculator-3:                    "\e34f";
$streamline-cash-register:                   "\e350";
$streamline-chart-board:                     "\e351";
$streamline-chart-down:                      "\e352";
$streamline-chart-up:                        "\e353";
$streamline-check:                           "\e354";
$streamline-coins-1:                         "\e355";
$streamline-coins-2:                         "\e356";
$streamline-court:                           "\e357";
$streamline-credit-card:                     "\e358";
$streamline-credit-card-lock:                "\e359";
$streamline-delivery:                        "\e35a";
$streamline-dollar-bag:                      "\e35b";
$streamline-dollar-currency-1:               "\e35c";
$streamline-dollar-currency-2:               "\e35d";
$streamline-dollar-currency-3:               "\e35e";
$streamline-dollar-currency-4:               "\e35f";
$streamline-euro-bag:                        "\e360";
$streamline-euro-currency-1:                 "\e361";
$streamline-euro-currency-2:                 "\e362";
$streamline-euro-currency-3:                 "\e363";
$streamline-euro-currency-4:                 "\e364";
$streamline-forklift:                        "\e365";
$streamline-hand-card:                       "\e366";
$streamline-hand-coin:                       "\e367";
$streamline-keynote:                         "\e368";
$streamline-master-card:                     "\e369";
$streamline-money:                           "\e36a";
$streamline-parking-meter:                   "\e36b";
$streamline-percent-1:                       "\e36c";
$streamline-percent-2:                       "\e36d";
$streamline-percent-3:                       "\e36e";
$streamline-percent-4:                       "\e36f";
$streamline-percent-5:                       "\e370";
$streamline-percent-up:                      "\e371";
$streamline-pie-chart-1:                     "\e372";
$streamline-pie-chart-2:                     "\e373";
$streamline-piggy-bank:                      "\e374";
$streamline-pound-currency-1:                "\e375";
$streamline-pound-currency-2:                "\e376";
$streamline-pound-currency-3:                "\e377";
$streamline-pound-currency-4:                "\e378";
$streamline-safe-1:                          "\e379";
$streamline-safe-2:                          "\e37a";
$streamline-shop:                            "\e37b";
$streamline-sign:                            "\e37c";
$streamline-trolley:                         "\e37d";
$streamline-truck-1:                         "\e37e";
$streamline-truck-2:                         "\e37f";
$streamline-visa-card:                       "\e380";
$streamline-yen-currency-1:                  "\e381";
$streamline-yen-currency-2:                  "\e382";
$streamline-yen-currency-3:                  "\e383";
$streamline-yen-currency-4:                  "\e384";
$streamline-add-marker-1:                    "\e385";
$streamline-add-marker-1-1:                  "\e386";
$streamline-add-marker-2:                    "\e387";
$streamline-add-marker-2-1:                  "\e388";
$streamline-add-marker-3:                    "\e389";
$streamline-compass-1:                       "\e38a";
$streamline-compass-2:                       "\e38b";
$streamline-compass-3:                       "\e38c";
$streamline-delete-marker-1:                 "\e38d";
$streamline-delete-marker-1-1:               "\e38e";
$streamline-delete-marker-2:                 "\e38f";
$streamline-delete-marker-2-1:               "\e390";
$streamline-favorite-marker-1:               "\e391";
$streamline-favorite-marker-1-1:             "\e392";
$streamline-favorite-marker-2:               "\e393";
$streamline-favorite-marker-2-1:             "\e394";
$streamline-globe:                           "\e395";
$streamline-location:                        "\e396";
$streamline-map-1:                           "\e397";
$streamline-map-location:                    "\e398";
$streamline-map-marker-1:                    "\e399";
$streamline-map-marker-1-1:                  "\e39a";
$streamline-map-marker-2:                    "\e39b";
$streamline-map-marker-3:                    "\e39c";
$streamline-map-marker-pin:                  "\e39d";
$streamline-map-pin:                         "\e39e";
$streamline-marker-1:                        "\e39f";
$streamline-marker-1-1:                      "\e3a0";
$streamline-marker-2:                        "\e3a1";
$streamline-marker-2-1:                      "\e3a2";
$streamline-marker-pin-1:                    "\e3a3";
$streamline-marker-pin-2:                    "\e3a4";
$streamline-marker-pin-location:             "\e3a5";
$streamline-minus-marker-1:                  "\e3a6";
$streamline-minus-marker-1-1:                "\e3a7";
$streamline-minus-marker-2:                  "\e3a8";
$streamline-minus-marker-2-1:                "\e3a9";
$streamline-minus-marker-3:                  "\e3aa";
$streamline-anchor:                          "\e3ab";
$streamline-bank:                            "\e3ac";
$streamline-beach:                           "\e3ad";
$streamline-boat:                            "\e3ae";
$streamline-building-1:                      "\e3af";
$streamline-building-2:                      "\e3b0";
$streamline-building-3:                      "\e3b1";
$streamline-buildings-1:                     "\e3b2";
$streamline-buildings-2:                     "\e3b3";
$streamline-buildings-3:                     "\e3b4";
$streamline-buildings-4:                     "\e3b5";
$streamline-castle:                          "\e3b6";
$streamline-column:                          "\e3b7";
$streamline-direction-sign:                  "\e3b8";
$streamline-factory:                         "\e3b9";
$streamline-fence:                           "\e3ba";
$streamline-garage:                          "\e3bb";
$streamline-globe-1:                         "\e3bc";
$streamline-globe-2:                         "\e3bd";
$streamline-house-1:                         "\e3be";
$streamline-house-2:                         "\e3bf";
$streamline-house-3:                         "\e3c0";
$streamline-house-4:                         "\e3c1";
$streamline-library:                         "\e3c2";
$streamline-light-house:                     "\e3c3";
$streamline-pisa:                            "\e3c4";
$streamline-skyscraper:                      "\e3c5";
$streamline-temple:                          "\e3c6";
$streamline-treasure-map:                    "\e3c7";
$streamline-tree:                            "\e3c8";
$streamline-tree-pine:                       "\e3c9";
$streamline-attention:                       "\e3ca";
$streamline-bug-1:                           "\e3cb";
$streamline-bug-2:                           "\e3cc";
$streamline-css3:                            "\e3cd";
$streamline-firewall:                        "\e3ce";
$streamline-html5:                           "\e3cf";
$streamline-plugins-1:                       "\e3d0";
$streamline-plugins-2:                       "\e3d1";
$streamline-script:                          "\e3d2";
$streamline-new-window:                      "\e3d3";
$streamline-window-1:                        "\e3d4";
$streamline-window-2:                        "\e3d5";
$streamline-window-3:                        "\e3d6";
$streamline-window-add:                      "\e3d7";
$streamline-window-alert:                    "\e3d8";
$streamline-window-check:                    "\e3d9";
$streamline-window-code-1:                   "\e3da";
$streamline-window-code-2:                   "\e3db";
$streamline-window-code-3:                   "\e3dc";
$streamline-window-column:                   "\e3dd";
$streamline-window-delete:                   "\e3de";
$streamline-window-denied:                   "\e3df";
$streamline-window-download-1:               "\e3e0";
$streamline-window-download-2:               "\e3e1";
$streamline-window-edit:                     "\e3e2";
$streamline-window-favorite:                 "\e3e3";
$streamline-window-graph-1:                  "\e3e4";
$streamline-window-graph-2:                  "\e3e5";
$streamline-window-hand:                     "\e3e6";
$streamline-window-home:                     "\e3e7";
$streamline-window-like:                     "\e3e8";
$streamline-window-list-1:                   "\e3e9";
$streamline-window-list-2:                   "\e3ea";
$streamline-window-lock:                     "\e3eb";
$streamline-window-minimize:                 "\e3ec";
$streamline-window-minus:                    "\e3ed";
$streamline-window-refresh:                  "\e3ee";
$streamline-window-register:                 "\e3ef";
$streamline-window-search:                   "\e3f0";
$streamline-window-selection:                "\e3f1";
$streamline-window-setting:                  "\e3f2";
$streamline-window-sync:                     "\e3f3";
$streamline-window-thumbnails-1:             "\e3f4";
$streamline-window-thumbnails-2:             "\e3f5";
$streamline-window-time:                     "\e3f6";
$streamline-window-upload-1:                 "\e3f7";
$streamline-window-upload-2:                 "\e3f8";
$streamline-windows-selection:               "\e3f9";
$streamline-database:                        "\e3fa";
$streamline-database-alert:                  "\e3fb";
$streamline-database-block:                  "\e3fc";
$streamline-database-check:                  "\e3fd";
$streamline-database-delete:                 "\e3fe";
$streamline-database-download:               "\e3ff";
$streamline-database-edit:                   "\e400";
$streamline-database-lock:                   "\e401";
$streamline-database-minus:                  "\e402";
$streamline-database-network:                "\e403";
$streamline-database-plus:                   "\e404";
$streamline-database-refresh:                "\e405";
$streamline-database-search:                 "\e406";
$streamline-database-setting:                "\e407";
$streamline-database-sync:                   "\e408";
$streamline-database-time:                   "\e409";
$streamline-database-upload:                 "\e40a";
$streamline-battery-charging:                "\e40b";
$streamline-battery-full:                    "\e40c";
$streamline-battery-high:                    "\e40d";
$streamline-battery-low:                     "\e40e";
$streamline-battery-medium:                  "\e40f";
$streamline-cd-1:                            "\e410";
$streamline-cd-2:                            "\e411";
$streamline-chip:                            "\e412";
$streamline-computer:                        "\e413";
$streamline-disc:                            "\e414";
$streamline-filter:                          "\e415";
$streamline-floppy-disk:                     "\e416";
$streamline-gameboy:                         "\e417";
$streamline-harddisk-1:                      "\e418";
$streamline-harddisk-2:                      "\e419";
$streamline-imac:                            "\e41a";
$streamline-ipad-1:                          "\e41b";
$streamline-ipad-2:                          "\e41c";
$streamline-ipod:                            "\e41d";
$streamline-joystick-1:                      "\e41e";
$streamline-joystick-2:                      "\e41f";
$streamline-joystick-3:                      "\e420";
$streamline-keyboard-1:                      "\e421";
$streamline-keyboard-2:                      "\e422";
$streamline-kindle-1:                        "\e423";
$streamline-kindle-2:                        "\e424";
$streamline-laptop-1:                        "\e425";
$streamline-laptop-2:                        "\e426";
$streamline-memory-card:                     "\e427";
$streamline-mobile-phone:                    "\e428";
$streamline-mouse-1:                         "\e429";
$streamline-mouse-2:                         "\e42a";
$streamline-mp3player:                       "\e42b";
$streamline-plug-1:                          "\e42c";
$streamline-plug-2:                          "\e42d";
$streamline-plug-slot:                       "\e42e";
$streamline-printer:                         "\e42f";
$streamline-projector:                       "\e430";
$streamline-remote:                          "\e431";
$streamline-router:                          "\e432";
$streamline-screen-1:                        "\e433";
$streamline-screen-2:                        "\e434";
$streamline-screen-3:                        "\e435";
$streamline-screen-4:                        "\e436";
$streamline-smartphone-1:                    "\e437";
$streamline-television-1:                    "\e438";
$streamline-typewriter-1:                    "\e439";
$streamline-typewriter-2:                    "\e43a";
$streamline-usb-1:                           "\e43b";
$streamline-usb-2:                           "\e43c";
$streamline-webcam:                          "\e43d";
$streamline-wireless-router-1:               "\e43e";
$streamline-wireless-router-2:               "\e43f";
$streamline-bluetooth:                       "\e440";
$streamline-ethernet:                        "\e441";
$streamline-ethernet-slot:                   "\e442";
$streamline-firewire-1:                      "\e443";
$streamline-firewire-2:                      "\e444";
$streamline-network-1:                       "\e445";
$streamline-network-2:                       "\e446";
$streamline-server-1:                        "\e447";
$streamline-server-2:                        "\e448";
$streamline-server-3:                        "\e449";
$streamline-usb:                             "\e44a";
$streamline-wireless-signal:                 "\e44b";
$streamline-book-1:                          "\e44c";
$streamline-book-2:                          "\e44d";
$streamline-book-3:                          "\e44e";
$streamline-book-4:                          "\e44f";
$streamline-book-5:                          "\e450";
$streamline-book-6:                          "\e451";
$streamline-book-7:                          "\e452";
$streamline-book-8:                          "\e453";
$streamline-book-download-1:                 "\e454";
$streamline-book-download-2:                 "\e455";
$streamline-book-favorite-1:                 "\e456";
$streamline-book-favorite-2:                 "\e457";
$streamline-bookmark-1-1:                    "\e458";
$streamline-bookmark-2-1:                    "\e459";
$streamline-bookmark-3-1:                    "\e45a";
$streamline-bookmark-4-1:                    "\e45b";
$streamline-books-1:                         "\e45c";
$streamline-books-2:                         "\e45d";
$streamline-books-3:                         "\e45e";
$streamline-briefcase-2:                     "\e45f";
$streamline-contact-book-1:                  "\e460";
$streamline-contact-book-2:                  "\e461";
$streamline-contact-book-3:                  "\e462";
$streamline-contact-book-4:                  "\e463";
$streamline-copyright:                       "\e464";
$streamline-creative-commons:                "\e465";
$streamline-cube:                            "\e466";
$streamline-data-filter:                     "\e467";
$streamline-document-box-1:                  "\e468";
$streamline-document-box-2:                  "\e469";
$streamline-document-box-3:                  "\e46a";
$streamline-drawer-1:                        "\e46b";
$streamline-drawer-2:                        "\e46c";
$streamline-drawer-3:                        "\e46d";
$streamline-envelope:                        "\e46e";
$streamline-file:                            "\e46f";
$streamline-files:                           "\e470";
$streamline-filter-1:                        "\e471";
$streamline-filter-2:                        "\e472";
$streamline-layers-1:                        "\e473";
$streamline-list-1:                          "\e474";
$streamline-list-2:                          "\e475";
$streamline-newspaper-1:                     "\e476";
$streamline-newspaper-2:                     "\e477";
$streamline-registry-1:                      "\e478";
$streamline-registry-2:                      "\e479";
$streamline-shield-1:                        "\e47a";
$streamline-shield-2:                        "\e47b";
$streamline-shield-3:                        "\e47c";
$streamline-sketchbook:                      "\e47d";
$streamline-sound-book:                      "\e47e";
$streamline-thumbnails-1:                    "\e47f";
$streamline-thumbnails-2:                    "\e480";
$streamline-graph:                           "\e481";
$streamline-hierarchy-1:                     "\e482";
$streamline-hierarchy-2:                     "\e483";
$streamline-hierarchy-3:                     "\e484";
$streamline-hierarchy-4:                     "\e485";
$streamline-hierarchy-5:                     "\e486";
$streamline-hierarchy-6:                     "\e487";
$streamline-hierarchy-7:                     "\e488";
$streamline-network-1-1:                     "\e489";
$streamline-network-2-1:                     "\e48a";
$streamline-backpack:                        "\e48b";
$streamline-balance-1:                       "\e48c";
$streamline-bed:                             "\e48d";
$streamline-bench:                           "\e48e";
$streamline-bomb-1:                          "\e48f";
$streamline-bricks:                          "\e490";
$streamline-bullets:                         "\e491";
$streamline-buoy:                            "\e492";
$streamline-campfire:                        "\e493";
$streamline-can:                             "\e494";
$streamline-candle:                          "\e495";
$streamline-canon:                           "\e496";
$streamline-cctv-1:                          "\e497";
$streamline-cctv-2:                          "\e498";
$streamline-chair:                           "\e499";
$streamline-chair-director:                  "\e49a";
$streamline-cigarette:                       "\e49b";
$streamline-construction-sign:               "\e49c";
$streamline-diamond:                         "\e49d";
$streamline-disabled:                        "\e49e";
$streamline-door:                            "\e49f";
$streamline-drawer:                          "\e4a0";
$streamline-driller:                         "\e4a1";
$streamline-dumbbells:                       "\e4a2";
$streamline-fire-extinguisher:               "\e4a3";
$streamline-flashlight:                      "\e4a4";
$streamline-gas-station:                     "\e4a5";
$streamline-gun:                             "\e4a6";
$streamline-lamp-1:                          "\e4a7";
$streamline-lamp-2:                          "\e4a8";
$streamline-lamp-3:                          "\e4a9";
$streamline-lamp-4:                          "\e4aa";
$streamline-lightbulb-1:                     "\e4ab";
$streamline-lightbulb-2:                     "\e4ac";
$streamline-measuring-tape:                  "\e4ad";
$streamline-mine-cart:                       "\e4ae";
$streamline-missile:                         "\e4af";
$streamline-ring:                            "\e4b0";
$streamline-scale-1:                         "\e4b1";
$streamline-shovel:                          "\e4b2";
$streamline-smoke-no:                        "\e4b3";
$streamline-sofa-1:                          "\e4b4";
$streamline-sofa-2:                          "\e4b5";
$streamline-sofa-3:                          "\e4b6";
$streamline-target:                          "\e4b7";
$streamline-torch:                           "\e4b8";
$streamline-traffic-cone:                    "\e4b9";
$streamline-traffic-light-1:                 "\e4ba";
$streamline-traffic-light-2:                 "\e4bb";
$streamline-treasure-chest-1:                "\e4bc";
$streamline-treasure-chest-2:                "\e4bd";
$streamline-trowel:                          "\e4be";
$streamline-watering-can:                    "\e4bf";
$streamline-weigh:                           "\e4c0";
$streamline-36-text-arrow-redo:              "\e4c1";
$streamline-academic-cap:                    "\e4c2";
$streamline-baseball-helmet:                 "\e4c3";
$streamline-beanie:                          "\e4c4";
$streamline-bike-helmet:                     "\e4c5";
$streamline-bow:                             "\e4c6";
$streamline-cap:                             "\e4c7";
$streamline-chaplin:                         "\e4c8";
$streamline-chef-hat:                        "\e4c9";
$streamline-cloth-hanger:                    "\e4ca";
$streamline-fins:                            "\e4cb";
$streamline-football-helmet:                 "\e4cc";
$streamline-glasses:                         "\e4cd";
$streamline-glasses-1:                       "\e4ce";
$streamline-glasses-2:                       "\e4cf";
$streamline-magician-hat:                    "\e4d0";
$streamline-monocle-1:                       "\e4d1";
$streamline-monocle-2:                       "\e4d2";
$streamline-necktie:                         "\e4d3";
$streamline-safety-helmet:                   "\e4d4";
$streamline-scuba-tank:                      "\e4d5";
$streamline-shirt-1:                         "\e4d6";
$streamline-shirt-2:                         "\e4d7";
$streamline-shirt-3:                         "\e4d8";
$streamline-sneakers:                        "\e4d9";
$streamline-snorkel:                         "\e4da";
$streamline-sombrero:                        "\e4db";
$streamline-sunglasses:                      "\e4dc";
$streamline-tall-hat:                        "\e4dd";
$streamline-trousers:                        "\e4de";
$streamline-walking-stick:                   "\e4df";
$streamline-arrow-undo:                      "\e4e0";
$streamline-bold:                            "\e4e1";
$streamline-columns:                         "\e4e2";
$streamline-eraser:                          "\e4e3";
$streamline-font-color:                      "\e4e4";
$streamline-html:                            "\e4e5";
$streamline-italic:                          "\e4e6";
$streamline-list-1-1:                        "\e4e7";
$streamline-list-2-1:                        "\e4e8";
$streamline-list-3:                          "\e4e9";
$streamline-list-4:                          "\e4ea";
$streamline-paragraph:                       "\e4eb";
$streamline-paste:                           "\e4ec";
$streamline-print-preview:                   "\e4ed";
$streamline-quote:                           "\e4ee";
$streamline-strikethrough:                   "\e4ef";
$streamline-text:                            "\e4f0";
$streamline-text-wrapping-1:                 "\e4f1";
$streamline-text-wrapping-2:                 "\e4f2";
$streamline-text-wrapping-3:                 "\e4f3";
$streamline-underline:                       "\e4f4";
$streamline-align-center:                    "\e4f5";
$streamline-align-left:                      "\e4f6";
$streamline-align-right:                     "\e4f7";
$streamline-all-caps:                        "\e4f8";
$streamline-arrange-2-1:                     "\e4f9";
$streamline-arrange-2-2:                     "\e4fa";
$streamline-arrange-2-3:                     "\e4fb";
$streamline-arrange-2-4:                     "\e4fc";
$streamline-arrange-3-1:                     "\e4fd";
$streamline-arrange-3-2:                     "\e4fe";
$streamline-arrange-3-3:                     "\e4ff";
$streamline-arrange-3-4:                     "\e500";
$streamline-arrange-3-5:                     "\e501";
$streamline-arrange-4-1:                     "\e502";
$streamline-arrange-4-2:                     "\e503";
$streamline-arrange-4-3:                     "\e504";
$streamline-arrange-5:                       "\e505";
$streamline-consolidate-all:                 "\e506";
$streamline-decrease-indent-1:               "\e507";
$streamline-decrease-indent-2:               "\e508";
$streamline-horizontal-page:                 "\e509";
$streamline-increase-indent-1:               "\e50a";
$streamline-increase-indent-2:               "\e50b";
$streamline-justify:                         "\e50c";
$streamline-leading-1:                       "\e50d";
$streamline-leading-2:                       "\e50e";
$streamline-left-indent:                     "\e50f";
$streamline-right-indent:                    "\e510";
$streamline-small-caps:                      "\e511";
$streamline-vertical-page:                   "\e512";
$streamline-alt-mac:                         "\e513";
$streamline-alt-windows:                     "\e514";
$streamline-arrow-down:                      "\e515";
$streamline-arrow-down-left:                 "\e516";
$streamline-arrow-down-right:                "\e517";
$streamline-arrow-left:                      "\e518";
$streamline-arrow-right:                     "\e519";
$streamline-arrow-up:                        "\e51a";
$streamline-arrow-up-left:                   "\e51b";
$streamline-arrow-up-right:                  "\e51c";
$streamline-asterisk-1:                      "\e51d";
$streamline-asterisk-2:                      "\e51e";
$streamline-back-tab-1:                      "\e51f";
$streamline-back-tab-2:                      "\e520";
$streamline-backward-delete:                 "\e521";
$streamline-blank:                           "\e522";
$streamline-eject:                           "\e523";
$streamline-enter-1:                         "\e524";
$streamline-enter-2:                         "\e525";
$streamline-escape:                          "\e526";
$streamline-page-down:                       "\e527";
$streamline-page-up:                         "\e528";
$streamline-return-1:                        "\e529";
$streamline-shift-1:                         "\e52a";
$streamline-shift-2:                         "\e52b";
$streamline-tab:                             "\e52c";
$streamline-apple:                           "\e52d";
$streamline-beer:                            "\e52e";
$streamline-boil:                            "\e52f";
$streamline-bottle-1:                        "\e530";
$streamline-bottle-2:                        "\e531";
$streamline-bottle-3:                        "\e532";
$streamline-bottle-4:                        "\e533";
$streamline-bread:                           "\e534";
$streamline-burger-1:                        "\e535";
$streamline-burger-2:                        "\e536";
$streamline-cake-1:                          "\e537";
$streamline-cake-2:                          "\e538";
$streamline-champagne:                       "\e539";
$streamline-cheese:                          "\e53a";
$streamline-cocktail:                        "\e53b";
$streamline-cocktail-1:                      "\e53c";
$streamline-cocktail-2:                      "\e53d";
$streamline-coffee-pot:                      "\e53e";
$streamline-cup1:                            "\e53f";
$streamline-cup-2:                           "\e540";
$streamline-deep-fry:                        "\e541";
$streamline-energy-drink:                    "\e542";
$streamline-espresso-machine:                "\e543";
$streamline-food-dome:                       "\e544";
$streamline-fork-and-knife:                  "\e545";
$streamline-fork-and-spoon:                  "\e546";
$streamline-grape:                           "\e547";
$streamline-grater:                          "\e548";
$streamline-grill:                           "\e549";
$streamline-hot-drinks-glass:                "\e54a";
$streamline-hotdog:                          "\e54b";
$streamline-ice-cream-1:                     "\e54c";
$streamline-ice-cream-2:                     "\e54d";
$streamline-ice-cream-3:                     "\e54e";
$streamline-ice-drinks-galss:                "\e54f";
$streamline-juicer:                          "\e550";
$streamline-kitchen-timer:                   "\e551";
$streamline-milk:                            "\e552";
$streamline-orange:                          "\e553";
$streamline-oven:                            "\e554";
$streamline-pan-fry:                         "\e555";
$streamline-pepper-salt:                     "\e556";
$streamline-pizza:                           "\e557";
$streamline-popcorn:                         "\e558";
$streamline-serving:                         "\e559";
$streamline-soda:                            "\e55a";
$streamline-soda-can-1:                      "\e55b";
$streamline-soda-can-2:                      "\e55c";
$streamline-steam:                           "\e55d";
$streamline-tea-pot:                         "\e55e";
$streamline-thermometer-high:                "\e55f";
$streamline-thermometer-low:                 "\e560";
$streamline-thermometer-medium:              "\e561";
$streamline-water:                           "\e562";
$streamline-wine:                            "\e563";
$streamline-ambulance:                       "\e564";
$streamline-beaker-1:                        "\e565";
$streamline-beaker-2:                        "\e566";
$streamline-blood:                           "\e567";
$streamline-drug:                            "\e568";
$streamline-first-aid:                       "\e569";
$streamline-hashish:                         "\e56a";
$streamline-heartpulse:                      "\e56b";
$streamline-hospital-1:                      "\e56c";
$streamline-hospital-2:                      "\e56d";
$streamline-hospital-sign-1:                 "\e56e";
$streamline-hospital-sign-2:                 "\e56f";
$streamline-hospital-sign-3:                 "\e570";
$streamline-medicine:                        "\e571";
$streamline-microscope:                      "\e572";
$streamline-mortar-and-pestle:               "\e573";
$streamline-plaster:                         "\e574";
$streamline-pulse-graph-1:                   "\e575";
$streamline-pulse-graph-2:                   "\e576";
$streamline-pulse-graph-3:                   "\e577";
$streamline-red-cross:                       "\e578";
$streamline-stethoscope:                     "\e579";
$streamline-syringe:                         "\e57a";
$streamline-yin-yang:                        "\e57b";
$streamline-balloon:                         "\e57c";
$streamline-briefcase-lock:                  "\e57d";
$streamline-card:                            "\e57e";
$streamline-cards:                           "\e57f";
$streamline-cards-2:                         "\e580";
$streamline-curtain:                         "\e581";
$streamline-dice-1:                          "\e582";
$streamline-dice-2:                          "\e583";
$streamline-happy-smiley:                    "\e584";
$streamline-pacman:                          "\e585";
$streamline-pacman-ghost:                    "\e586";
$streamline-sad-smiley:                      "\e587";
$streamline-sign-1:                          "\e588";
$streamline-smileys:                         "\e589";
$streamline-suitcase-1:                      "\e58a";
$streamline-suitcase-2:                      "\e58b";
$streamline-tetris:                          "\e58c";
$streamline-ticket-1:                        "\e58d";
$streamline-ticket-2:                        "\e58e";
$streamline-ticket-3:                        "\e58f";
$streamline-virus:                           "\e590";
$streamline-cloud-1:                         "\e591";
$streamline-cloud-lightning:                 "\e592";
$streamline-clouds:                          "\e593";
$streamline-first-quarter-moon:              "\e594";
$streamline-full-moon:                       "\e595";
$streamline-hail:                            "\e596";
$streamline-heavy-rain:                      "\e597";
$streamline-moon-cloud:                      "\e598";
$streamline-rain:                            "\e599";
$streamline-rain-lightning:                  "\e59a";
$streamline-snow:                            "\e59b";
$streamline-sun:                             "\e59c";
$streamline-sun-cloud:                       "\e59d";
$streamline-thermometer:                     "\e59e";
$streamline-third-quarter-moon:              "\e59f";
$streamline-umbrella:                        "\e5a0";
$streamline-waning-crescent-moon:            "\e5a1";
$streamline-waning-gibbous-moon:             "\e5a2";
$streamline-waxing-crescent-moon:            "\e5a3";
$streamline-waxing-gibbous-moon:             "\e5a4";
$streamline-bicycle:                         "\e5a5";
$streamline-bus-1:                           "\e5a6";
$streamline-bus-2:                           "\e5a7";
$streamline-car-1:                           "\e5a8";
$streamline-car-2:                           "\e5a9";
$streamline-car-3:                           "\e5aa";
$streamline-car-4:                           "\e5ab";
$streamline-helicopter:                      "\e5ac";
$streamline-mountain-bike:                   "\e5ad";
$streamline-pickup:                          "\e5ae";
$streamline-plane-1:                         "\e5af";
$streamline-plane-2:                         "\e5b0";
$streamline-plane-landing:                   "\e5b1";
$streamline-plane-takeoff:                   "\e5b2";
$streamline-road:                            "\e5b3";
$streamline-road-bike:                       "\e5b4";
$streamline-rocket:                          "\e5b5";
$streamline-scooter:                         "\e5b6";
$streamline-ship:                            "\e5b7";
$streamline-train:                           "\e5b8";
$streamline-tram:                            "\e5b9";
$streamline-cactus:                          "\e5ba";
$streamline-clover:                          "\e5bb";
$streamline-flower:                          "\e5bc";
$streamline-hand-eco:                        "\e5bd";
$streamline-hand-globe:                      "\e5be";
$streamline-leaf:                            "\e5bf";
$streamline-light-eco:                       "\e5c0";
$streamline-potted-plant-1:                  "\e5c1";
$streamline-potted-plant-2:                  "\e5c2";
$streamline-2-fingers-double-tap:            "\e5c3";
$streamline-2-fingers-down-swipe:            "\e5c4";
$streamline-2-fingers-horizontal-swipe:      "\e5c5";
$streamline-2-fingers-left-swipe:            "\e5c6";
$streamline-2-fingers-omnidirectional-swipe: "\e5c7";
$streamline-2-fingers-right-swipe:           "\e5c8";
$streamline-2-fingers-tab-hold:              "\e5c9";
$streamline-2-fingers-tap:                   "\e5ca";
$streamline-2-fingers-up-swipe:              "\e5cb";
$streamline-2-fingers-vertical-swipe:        "\e5cc";
$streamline-double-tap:                      "\e5cd";
$streamline-drag-down:                       "\e5ce";
$streamline-drag-horizontal:                 "\e5cf";
$streamline-drag-left:                       "\e5d0";
$streamline-drag-right:                      "\e5d1";
$streamline-drag-up:                         "\e5d2";
$streamline-drag-vertical:                   "\e5d3";
$streamline-filck-down:                      "\e5d4";
$streamline-flick-up:                        "\e5d5";
$streamline-horizontal-flick:                "\e5d6";
$streamline-left-flick:                      "\e5d7";
$streamline-omnidirectional-drag:            "\e5d8";
$streamline-omnidirectional-flick:           "\e5d9";
$streamline-omnidirectional-swipe:           "\e5da";
$streamline-pinch:                           "\e5db";
$streamline-right-flick:                     "\e5dc";
$streamline-rotate-clockwise:                "\e5dd";
$streamline-rotate-counterclockwise:         "\e5de";
$streamline-spread:                          "\e5df";
$streamline-swipe-down:                      "\e5e0";
$streamline-swipe-horizontal:                "\e5e1";
$streamline-swipe-left:                      "\e5e2";
$streamline-swipe-right:                     "\e5e3";
$streamline-swipe-up:                        "\e5e4";
$streamline-swipe-vertical:                  "\e5e5";
$streamline-tap:                             "\e5e6";
$streamline-tap-hold:                        "\e5e7";
$streamline-vertical-flick:                  "\e5e8";
$streamline-arrow-1-1:                       "\e5e9";
$streamline-arrow-2-1:                       "\e5ea";
$streamline-arrow-3:                         "\e5eb";
$streamline-arrow-4:                         "\e5ec";
$streamline-arrow-5:                         "\e5ed";
$streamline-arrow-6:                         "\e5ee";
$streamline-arrow-7:                         "\e5ef";
$streamline-arrow-8:                         "\e5f0";
$streamline-arrow-9:                         "\e5f1";
$streamline-arrow-10:                        "\e5f2";
$streamline-arrow-11:                        "\e5f3";
$streamline-arrow-12:                        "\e5f4";
$streamline-arrow-13:                        "\e5f5";
$streamline-arrow-14:                        "\e5f6";
$streamline-arrow-15:                        "\e5f7";
$streamline-arrow-16:                        "\e5f8";
$streamline-arrow-17:                        "\e5f9";
$streamline-arrow-18:                        "\e5fa";
$streamline-arrow-19:                        "\e5fb";
$streamline-arrow-20:                        "\e5fc";
$streamline-arrow-21:                        "\e5fd";
$streamline-arrow-22:                        "\e5fe";
$streamline-arrow-23:                        "\e5ff";
$streamline-arrow-24:                        "\e600";
$streamline-arrow-25:                        "\e601";
$streamline-arrow-26:                        "\e602";
$streamline-arrow-27:                        "\e603";
$streamline-arrow-28:                        "\e604";
$streamline-arrow-29:                        "\e605";
$streamline-arrow-30:                        "\e606";
$streamline-arrow-31:                        "\e607";
$streamline-arrow-32:                        "\e608";
$streamline-arrow-33:                        "\e609";
$streamline-arrow-34:                        "\e60a";
$streamline-arrow-35:                        "\e60b";
$streamline-arrow-36:                        "\e60c";
$streamline-arrow-37:                        "\e60d";
$streamline-arrow-38:                        "\e60e";
$streamline-arrow-39:                        "\e60f";
$streamline-arrow-40:                        "\e610";
$streamline-arrow-41:                        "\e611";
$streamline-arrow-42:                        "\e612";
$streamline-arrow-43:                        "\e613";
$streamline-arrow-44:                        "\e614";
$streamline-arrow-45:                        "\e615";
$streamline-arrow-46:                        "\e616";
$streamline-arrow-47:                        "\e617";
$streamline-arrow-48:                        "\e618";
$streamline-arrow-49:                        "\e619";
$streamline-arrow-50:                        "\e61a";
$streamline-arrow-51:                        "\e61b";
$streamline-arrow-52:                        "\e61c";
$streamline-arrow-53:                        "\e61d";
$streamline-arrow-54:                        "\e61e";
$streamline-arrow-55:                        "\e61f";
$streamline-arrow-56:                        "\e620";
$streamline-arrow-57:                        "\e621";
$streamline-arrow-58:                        "\e622";
$streamline-arrow-59:                        "\e623";
$streamline-arrow-60:                        "\e624";
$streamline-arrow-61:                        "\e625";
$streamline-arrow-62:                        "\e626";
$streamline-arrow-63:                        "\e627";
$streamline-arrow-64:                        "\e628";
$streamline-arrow-65:                        "\e629";
$streamline-arrow-66:                        "\e62a";
$streamline-arrow-67:                        "\e62b";
$streamline-arrow-68:                        "\e62c";
$streamline-arrow-69:                        "\e62d";
$streamline-arrow-70:                        "\e62e";
$streamline-arrow-71:                        "\e62f";
$streamline-arrow-72:                        "\e630";
$streamline-arrow-circle-1:                  "\e631";
$streamline-arrow-circle-2:                  "\e632";
$streamline-arrow-circle-3:                  "\e633";
$streamline-arrow-circle-4:                  "\e634";
$streamline-arrow-circle-5:                  "\e635";
$streamline-arrow-circle-6:                  "\e636";
$streamline-arrow-circle-7:                  "\e637";
$streamline-arrow-circle-8:                  "\e638";
$streamline-arrow-circle-9:                  "\e639";
$streamline-arrow-circle-10:                 "\e63a";
$streamline-arrow-circle-11:                 "\e63b";
$streamline-arrow-circle-12:                 "\e63c";
$streamline-arrow-circle-13:                 "\e63d";
$streamline-arrow-circle-14:                 "\e63e";
$streamline-arrow-circle-15:                 "\e63f";
$streamline-arrow-circle-16:                 "\e640";
$streamline-arrow-circle-17:                 "\e641";
$streamline-arrow-circle-18:                 "\e642";
$streamline-arrow-circle-19:                 "\e643";
$streamline-arrow-circle-20:                 "\e644";
$streamline-arrow-circle-21:                 "\e645";
$streamline-arrow-circle-22:                 "\e646";
$streamline-arrow-circle-23:                 "\e647";
$streamline-arrow-circle-24:                 "\e648";
$streamline-arrow-circle-25:                 "\e649";
$streamline-arrow-circle-26:                 "\e64a";
$streamline-arrow-circle-27:                 "\e64b";
$streamline-arrow-circle-28:                 "\e64c";
$streamline-arrow-circle-29:                 "\e64d";
$streamline-arrow-circle-30:                 "\e64e";
$streamline-arrow-delete-1:                  "\e64f";
$streamline-arrow-delete-2:                  "\e650";
$streamline-arrow-dot-1:                     "\e651";
$streamline-arrow-dot-2:                     "\e652";
$streamline-arrow-dot-3:                     "\e653";
$streamline-arrow-dot-4:                     "\e654";
$streamline-arrow-dot-5:                     "\e655";
$streamline-arrow-dot-6:                     "\e656";
$streamline-arrow-rectangle-1:               "\e657";
$streamline-arrow-rectangle-2:               "\e658";
$streamline-arrow-rectangle-3:               "\e659";
$streamline-arrow-rectangle-4:               "\e65a";
$streamline-arrow-rectangle-5:               "\e65b";
$streamline-arrow-rectangle-6:               "\e65c";
$streamline-arrow-rectangle-7:               "\e65d";
$streamline-arrow-rectangle-8:               "\e65e";
$streamline-arrow-rectangle-9:               "\e65f";
$streamline-arrow-rectangle-10:              "\e660";
$streamline-arrow-rectangle-11:              "\e661";
$streamline-arrow-rectangle-12:              "\e662";
$streamline-arrow-rectangle-13:              "\e663";
$streamline-arrow-rectangle-14:              "\e664";
$streamline-arrow-rectangle-15:              "\e665";
$streamline-arrow-rectangle-16:              "\e666";
$streamline-arrow-rectangle-17:              "\e667";
$streamline-arrow-rectangle-18:              "\e668";
$streamline-arrow-rectangle-19:              "\e669";
$streamline-arrow-rectangle-20:              "\e66a";
