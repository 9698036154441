@include tweak(overlay-0) {
    @include option(o-light) {
        color: color-contrast($main-color);
    }

    @include option(o-dark) {
        color: color-contrast(#000);
    }

    @include option(o-accent) {
        color: color-contrast($accent-color);
    }

    @include option(o-primary) {
        color: color-contrast($primary-color);
    }

    @include option(o-secondary) {
        color: color-contrast($secondary-color);
    }

    @include option(o-inverse) {
        color: color-contrast($inverse-main-color);
    }
}

@for $i from 1 through 19 {
    @include tweak(overlay-#{$i * 5}) {
        &:before {
            content: '';
            display: block;
            @include absolute((top: 0, right: 0, bottom: 0, left: 0));
        }
        
        @include option(o-light) {
            color: color-contrast($main-color);
            
            &:before {
                background: rgba($main-color, ($i / 20));
            }
        }
        
        @include option(o-dark) {
            color: color-contrast(#000);
            
            &:before {
                background: rgba(#000, ($i / 20));
            }
        }
        
        @include option(o-accent) {
            color: color-contrast($accent-color);
            
            &:before {
                background: rgba($accent-color, ($i / 20));
            }
        }
        
        @include option(o-primary) {
            color: color-contrast($primary-color);
            
            &:before {
                background: rgba($primary-color, ($i / 20));
            }
        }
        
        @include option(o-secondary) {
            color: color-contrast($secondary-color);
            
            &:before {
                background: rgba($secondary-color, ($i / 20));
            }
        }
        
        @include option(o-inverse) {
            color: color-contrast($inverse-main-color);
            
            &:before {
                background: rgba($inverse-main-color, ($i / 20));
            }
        }
    }
}
