@include component(section) {
    position: relative;
    overflow: hidden;
    z-index: 1;
    
    &:first-child {
        @include component(content) {
            @include important {
                padding-top: $content-padding + $nav-height;
            }
        }
    }
    
    @include location(posts-page, post-page) {
        &:first-child {
            @include component(content) {
                @include padding(($content-padding / 2 + $nav-height) null ($content-padding / 2));
            }
        }
    }
    
    @include option(style-bg-img) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    
    @include option(type-map) {
        min-height: 320px;
        height: 100vh;
        height: calc(100vh - #{$nav-height});
        
        @include location(has-admin-bar) {
            height: calc(100vh - #{$nav-height + 32px});
        }
    }
}
