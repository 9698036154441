%z-depth-1 {
    box-shadow: 0 2px 5px 0 rgba(#000, .16), 0 2px 10px 0 rgba(#000, .12);
}

%z-depth-1-half {
    box-shadow: 0 5px 11px 0 rgba(#000, .18), 0 4px 15px 0 rgba(#000, .15);
}

%z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(#000, .2), 0 6px 20px 0 rgba(#000, .19);
}

%z-depth-3 {
    box-shadow: 0 12px 15px 0 rgba(#000, .24), 0 17px 50px 0 rgba(#000, .19);
}

%z-depth-4 {
    box-shadow: 0 16px 28px 0 rgba(#000, .22), 0 25px 55px 0 rgba(#000, .21);
}

%z-depth-5 {
    box-shadow: 0 27px 24px 0 rgba(#000, .2), 0 40px 77px 0 rgba(#000, .22);
}

%z-depth-1-in {
    box-shadow: inset 0 2px 5px -2px rgba(#000, .16), inset 0 2px 10px -2px rgba(#000, .12);
}

%z-depth-1-half-in {
    box-shadow: inset 0 5px 11px -5px rgba(#000, .18), inset 0 4px 15px -4px rgba(#000, .15);
}

%z-depth-2-in {
    box-shadow: inset 0 8px 17px -8px rgba(#000, .2), inset 0 6px 20px -6px rgba(#000, .19);
}

%z-depth-3-in {
    box-shadow: inset 0 12px 15px -12px rgba(#000, .24), inset 0 17px 50px -17px rgba(#000, .19);
}

%z-depth-4-in {
    box-shadow: inset 0 16px 28px -16px rgba(#000, .22), inset 0 25px 55px -25px rgba(#000, .21);
}

%z-depth-5-in {
    box-shadow: inset 0 27px 24px -27px rgba(#000, .2), inset 0 40px 77px -40px rgba(#000, .22);
}

@include tweak(z-depth-0, z-depth-1, z-depth-1-half, z-depth-2, z-depth-3, z-depth-4) {
    @include option(hoverable, focusable) {
        @include policy(transitionable);
    }
}

@include tweak(z-depth-0) {
    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 1;
    }

    @include option(hoverable) {
        &:hover {
            @extend %z-depth-1;
        }
    }

    @include option(focusable) {
        &.focus {
            @extend %z-depth-1;
        }
    }
}

@include component('*') {
    @include option(hoverable) {
        &:hover {
            @include tweak(z-depth-0) {
                @extend %z-depth-1;
            }
        }
    }
}

@include tweak(z-depth-1, z-depth-1-half) {
    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 2;
    }

    @include option(hoverable) {
        &:hover {
            @extend %z-depth-2;
        }
    }

    @include option(focusable) {
        &.focus {
            @extend %z-depth-2;
        }
    }
}

@include component('*') {
    @include option(hoverable) {
        &:hover {
            @include tweak(z-depth-1, z-depth-1-half) {
                @extend %z-depth-2;
            }
        }
    }
}

@include tweak(z-depth-1) {
    @extend %z-depth-1;
}

@include tweak(z-depth-1-half) {
    @extend %z-depth-1-half;
}

@include tweak(z-depth-2) {
    @extend %z-depth-2;

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 3;
    }

    @include option(hoverable) {
        &:hover {
            @extend %z-depth-3;
        }
    }

    @include option(focusable) {
        &.focus{
            @extend %z-depth-3;
        }
    }
}

@include component('*') {
    @include option(hoverable) {
        &:hover {
            @include tweak(z-depth-2) {
                @extend %z-depth-3;
            }
        }
    }
}

@include tweak(z-depth-3) {
    @extend %z-depth-3;

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 4;
    }

    @include option(hoverable) {
        &:hover{
            @extend %z-depth-4;
        }
    }

    @include option(focusable) {
        &.focus{
            @extend %z-depth-4;
        }
    }
}

@include component('*') {
    @include option(hoverable) {
        &:hover {
            @include tweak(z-depth-3) {
                @extend %z-depth-4;
            }
        }
    }
}

@include tweak(z-depth-4) {
    @extend %z-depth-4;

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 5;
    }

    @include option(hoverable) {
        &:hover{
            @extend %z-depth-5;
        }
    }

    @include option(focusable) {
        &.focus{
            @extend %z-depth-5;
        }
    }
}

@include component('*') {
    @include option(hoverable) {
        &:hover {
            @include tweak(z-depth-4) {
                @extend %z-depth-5;
            }
        }
    }
}

@include tweak(z-depth-5) {
    @extend %z-depth-5;

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 6;
    }
}

input, select, textarea, button, a, [tabindex]{
    @include tweak(z-depth-0) {
        @include option(focusable) {
            &:focus{
                @extend %z-depth-1;
            }
        }
    }

    @include tweak(z-depth-1, z-depth-1-half) {
        @include option(focusable) {
            &:focus{
                @extend %z-depth-2;
            }
        }
    }

    @include tweak(z-depth-2) {
        @include option(focusable) {
            &:focus{
                @extend %z-depth-3;
            }
        }
    }

    @include tweak(z-depth-3) {
        @include option(focusable) {
            &:focus{
                @extend %z-depth-4;
            }
        }
    }

    @include tweak(z-depth-4) {
        @include option(focusable) {
            &:focus{
                @extend %z-depth-5;
            }
        }
    }
}

@include tweak(z-depth-0-inv) {
    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 1;
    }

    @include option(hoverable) {
        &:hover{
            box-shadow: 0 -2px 5px 0 rgba(#000, .16), 0 -2px 10px 0 rgba(#000, .12);
        }
    }

    @include option(focusable) {
        &.focus{
            box-shadow: 0 -2px 5px 0 rgba(#000, .16), 0 -2px 10px 0 rgba(#000, .12);
        }
    }
}

@include tweak(z-depth-1-inv, z-depth-1-half-inv) {
    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 2;
    }

    @include option(hoverable) {
        &:hover{
            box-shadow: 0 -8px 17px 0 rgba(#000, .2), 0 -6px 20px 0 rgba(#000, .19);
        }
    }

    @include option(focusable) {
        &.focus{
            box-shadow: 0 -8px 17px 0 rgba(#000, .2), 0 -6px 20px 0 rgba(#000, .19);
        }
    }
}

@include tweak(z-depth-1-inv) {
    box-shadow: 0 -2px 5px 0 rgba(#000, .16), 0 -2px 10px 0 rgba(#000, .12);
}

@include tweak(z-depth-1-half-inv) {
    box-shadow: 0 -5px 11px 0 rgba(#000, .18), 0 -4px 15px 0 rgba(#000, .15);
}

@include tweak(z-depth-2-inv) {
    box-shadow: 0 -8px 17px 0 rgba(#000, .2), 0 -6px 20px 0 rgba(#000, .19);

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 3;
    }

    @include option(hoverable) {
        &:hover{
            box-shadow: 0 -12px 15px 0 rgba(#000, .24), 0 -17px 50px 0 rgba(#000, .19);
        }
    }

    @include option(focusable) {
        &.focus{
            box-shadow: 0 -12px 15px 0 rgba(#000, .24), 0 -17px 50px 0 rgba(#000, .19);
        }
    }
}
@include tweak(z-depth-3-inv) {
    box-shadow: 0 -12px 15px 0 rgba(#000, .24), 0 -17px 50px 0 rgba(#000, .19);

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 4;
    }

    @include option(hoverable) {
        &:hover{
            box-shadow: 0 -16px 28px 0 rgba(#000, .22), 0 -25px 55px 0 rgba(#000, .21);
        }
    }

    @include option(focusable) {
        &.focus{
            box-shadow: 0 -16px 28px 0 rgba(#000, .22), 0 -25px 55px 0 rgba(#000, .21);
        }
    }
}

@include tweak(z-depth-4-inv) {
    box-shadow: 0 -16px 28px 0 rgba(#000, .22), 0 -25px 55px 0 rgba(#000, .21);

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 5;
    }

    @include option(hoverable) {
        &:hover{
            box-shadow: 0 -27px 24px 0 rgba(#000, .2), 0 -40px 77px 0 rgba(#000, .22);
        }
    }

    @include option(focusable) {
        &.focus{
            box-shadow: 0 -27px 24px 0 rgba(#000, .2), 0 -40px 77px 0 rgba(#000, .22);
        }
    }
}

@include tweak(z-depth-5-inv) {
    box-shadow: 0 -27px 24px 0 rgba(#000, .2), 0 -40px 77px 0 rgba(#000, .22);

    &:not(#{component(nav)}):not(#{component(nav)part(container)}):not(#{component(sub-nav)}):not(#{component(sub-nav)part(container)}) {
        z-index: 6;
    }
}

input, select, textarea, button, a, [tabindex]{
    @include tweak(z-depth-0-inv) {
        @include option(focusable) {
            &:focus{
                box-shadow: 0 -2px 5px 0 rgba(#000, .16), 0 -2px 10px 0 rgba(#000, .12);
            }
        }
    }
    @include tweak(z-depth-1-inv, z-depth-1-half-inv) {
        @include option(focusable) {
            &:focus{
                box-shadow: 0 -8px 17px 0 rgba(#000, .2), 0 -6px 20px 0 rgba(#000, .19);
            }
        }
    }
    @include tweak(z-depth-2-inv) {
        @include option(focusable) {
            &:focus{
                box-shadow: 0 -12px 15px 0 rgba(#000, .24), 0 -17px 50px 0 rgba(#000, .19);
            }
        }
    }
    @include tweak(z-depth-3-inv) {
        @include option(focusable) {
            &:focus{
                box-shadow: 0 -16px 28px 0 rgba(#000, .22), 0 -25px 55px 0 rgba(#000, .21);
            }
        }
    }
    @include tweak(z-depth-4-inv) {
        @include option(focusable) {
            &:focus{
                box-shadow: 0 -27px 24px 0 rgba(#000, .2), 0 -40px 77px 0 rgba(#000, .22);
            }
        }
    }
}
