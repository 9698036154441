// Base mixins

@mixin modernizr($feature, $supports) {
    $prefix: if($supports, '', 'no-');
    $selector: &;
    $modernizr: '';

    @each $component in $selector {
        @if $modernizr != '' {
            $modernizr: $modernizr + ', ';
        }

        $modernized: '.' + $prefix + $feature + $component;
        $i: str-index($component + '', ' ');

        @if ($i != null) {
            $modernized: str-insert($component + '', '.' + $prefix + $feature, $i);
        }

        $modernizr: $modernizr + $modernized + ', .' + $prefix + $feature + ' ' + $component;
    }

    @at-root {
        #{unquote($modernizr)} {
            @content;
        }
    }
}

@mixin supports($feature) {
    @include modernizr($feature, true) {
        @content;
    }
}

@mixin rejects($feature) {
    @include modernizr($feature, false) {
        @content;
    }
}

@mixin img-shadow($shadow...){
    filter: drop-shadow($shadow);
    -webkit-backface-visibility: hidden;
}

@mixin media($type) {
    @if $type == sm {
        @media (min-width: $screen-sm-min) {
            @content;
        }
    } @else if $type == md {
        @media (min-width: $screen-md-min) {
            @content;
        }
    } @else if $type == lg {
        @media (min-width: $screen-lg-min) {
            @content;
        }
    } @else if $type == xl {
        @media (min-width: $screen-xl-min) {
            @content;
        }
    }
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculate-rem($size);
}

@mixin position($position, $args) {
    position: $position;
    @each $pos, $val in $args{
        #{$pos}: $val;
    }
}

@mixin absolute($args) {
    @include position(absolute, $args);
}

@mixin fixed($args) {
    @include position(fixed, $args);
}

@mixin relative($args) {
    @include position(relative, $args);
}
