.h-card {
    margin-bottom: 24px;
    @include policy(margin);
    
    a {
        display: inline-block;
        color: inherit;
        font-weight: inherit;
    }
    
    .p-name {
        margin-bottom: 12px;
        font-weight: $fw-bold;
    }
    
    .p-adr.h-adr {
        margin-bottom: 12px;
    }
    
    .u-email {
        margin-bottom: 12px;
        color: $accent-color;
        
        &:hover {
            color: lighten($accent-color, 10%);
        }
    }
}
