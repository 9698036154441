.intl-tel-input {
    display: block;
    width: 100%;
}

.country-list {
    color: $text-color;
}

.form-group {
    margin-bottom: 16px;
}

.form-control {
    border-radius: 4px;
}

.control-label {
    font-weight: $fw-normal;
}
