// General page layout

#container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    min-height: 100vh;
    background: $main-color;
    overflow: hidden;
    
    // IE fix
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
        @include clearfix;
    }
    
    > {
        main, footer {
            transition: opacity $animation-duration;
        }
        
        main {
            flex: 1;
            z-index: 1;
        }
    }
}

@include location(has-admin-bar) {
    #container {
        padding-top: 32px;
    }
}

@include component(clear) {
    clear: both;
}

@include component(clearfix) {
    @include clearfix;
}

