%highlight {
    font-size: 0 !important;
}

%highlight-span {
    display: inline-block;
    margin: .15em 0;
    padding: 0 3px;
    line-height: 1;
    @include font-size($font-base-size);
}

%highlight-p-span {
    padding: 4px;
    @include font-size(20px);
    font-weight: $fw-normal;
}

%highlight-heading-span {
    padding: (2em / 9) 8px (2em / 9);
}

@for $i from 1 through 6 {
    "%highlight-heading-span-#{$i}" {
        @include font-size(map-get($heading-font-sizes, h#{$i}));
    }
}

@include tweak(highlight) {
    @include part(inverse) {
        @extend %highlight;
        
        span {
            @extend %highlight-span;
            background: $inverse-main-color;
            color: $inverse-text-color;
        }
        
        @at-root {
            html##{$csstyle-root-id} {
                p {
                    &#{tweak(highlight)}#{part(inverse)} {
                        span {
                            @extend %highlight-p-span;
                        }
                    }
                }
                
                @for $i from 1 through 6 {
                    "h#{$i}, .h#{$i}" {
                        &#{tweak(highlight)}#{part(inverse)} {
                            span {
                                @extend %highlight-heading-span;
                                @extend "%highlight-heading-span-#{$i}";
                            }
                        }
                    }
                }
            }
        }
        
        @include option(hl-inverse) {
            span {
                background: $main-color;
                color: $text-color;
            }
        }
    }
    
    @each $color, $hex in $color-scheme {
        @include part($color) {
            @extend %highlight;
            
            span {
                @extend %highlight-span;
                background: $hex;
                color: color-contrast($hex);
            }
            
            @at-root {
                html##{$csstyle-root-id} {
                    p {
                        &#{tweak(highlight)}#{part($color)} {
                            span {
                                @extend %highlight-p-span;
                            }
                        }
                    }
                    
                    @for $i from 1 through 6 {
                        "h#{$i}, .h#{$i}" {
                            &#{tweak(highlight)}#{part($color)} {
                                span {
                                    @extend %highlight-heading-span;
                                    @extend "%highlight-heading-span-#{$i}";
                                }
                            }
                        }
                    }
                }
            }
            
            @include option(hl-inverse) {
                span {
                    background: color-contrast($hex);
                    color: $hex;
                }
            }
        }
    }
}
